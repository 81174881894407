import React, { useState } from 'react'

import TimezoneUserPrefFormProperties from './TimezoneUserPrefFormProperties'
import UserPreferenceTranslationText from '../shared/UserPreferenceTranslationText'
import UserPreferenceApiUrls from 'src/apiUrls/UserPreferenceApiUrls'
import { components, useServices } from 'cng-web-lib'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function TimezoneUserPrefPage({ showNotification }) {
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const translatedTextsObject = UserPreferenceTranslationText()
  const { updateRecord } = useServices()
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit', data)
    setLoading(true)

    if (data["timezone"] === "System Timezone") {
      data["timezone"] = ""
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setLoading(false)
      showErrorNotification(error.message)
    }

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      setRefresh(!refresh)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${translatedTextsObject.updateSubmitSuccessMsg}`
        )
      }
    }

    updateRecord.execute(
      UserPreferenceApiUrls.TZ_PUT,
      data,
      onSuccess,
      onError
    )
  }

  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <TimezoneUserPrefFormProperties.FormFields
          showNotification={showNotification}
          loading={loading}
          setLoading={setLoading}
          refresh={refresh}
        />
      }
      formikProps={{
        ...TimezoneUserPrefFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    />
  )
}

export default TimezoneUserPrefPage
