import React, { useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import {
  Card,
  Paper,
  CardContent,
  Grid,
  Checkbox,
  Box,
  Typography,
  useTheme,
  makeStyles,
  Tooltip
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import InfoDialog from '../components/InfoDialog'
import { useFormContext } from 'react-hook-form'
import Utils from 'src/shared/Utils'
import FTACalculatorForm from './FTACalculatorForm'
import FTACalculatorValidationSchema from './FTACalculatorValidationSchema'
import { Popover } from '@material-ui/core'
import { Yup } from 'cng-web-lib'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField },
    CngForm
  },
  button: { CngPrimaryButton, CngSecondaryButton, CngIconButton },
  CngGridItem,
  CngTabs
} = components

const { FormState, NotificationType } = constants

const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),

    borderRadius: 'inherit'
  },
  sectionHeader: {
    paddingInline: '20px',
    paddingBlock: '20px 0'
  },
  sectionMain: {
    marginTop: '10px',
    // Hiding the empty grid item. Because CngForm -> renderButtonSection is
    // |-> creating an empty grid item
    '& .MuiGrid-item:empty': {
      display: 'none'
    },

    '& .section-grid': {
      paddingInline: '20px'
    },

    '& .cta-footerbar': {
      backgroundColor: theme.palette.background.white,
      padding: '20px',
      borderBottomLeftRadius: '11px',
      borderBottomRightRadius: '11px',
      boxShadow: `0px 2px 5px -1px ${Utils.hexToRgba(
        theme.palette.background.sectionOddBg,
        0.65
      )}`,
      borderTop: `1px ${Utils.hexToRgba(
        theme.palette.color.primaryDark,
        0.08
      )} solid`
    }
  },
  formCaptionBig: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.text.widgetTitle
  },
  formCaptionSmall: {
    fontSize: '0.75rem',
    color: theme.palette.text.widgetSubTitle
  },
  popoverTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.text.widgetTitle
  },
  popoverBody: {
    fontSize: '0.8rem',
    color: theme.palette.text.widgetSubTitle
  },

  body: {
    padding: theme.spacing(3),
    fontSize: '1em',
    display: 'block',
    alignItems: 'center'
  },
  title: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0,
    '& > svg': { margin: theme.spacing(0, 3) }
  },
  exportBtn: {
    margin: theme.spacing(0.5)
  },
  tabs: {
    margin: theme.spacing(1, 0)
  },
  listItemAvatar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  listItemEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bottomBtn: {
    padding: theme.spacing(1.25),
    fontSize: '1.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonTransform: {
    textTransform: 'none'
  },
  fnButtonCircle: {
    '& .ng-button-func': {
      borderRadius: '50% !important',
      '&.medium': {
        width: '32px',
        height: '32px',
        minHeight: '32px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        borderWidth: '0'
      },
      '& svg': {
        fontSize: '22px !important'
      }
    }
  }
}))

function FTACalculatorTabContent({ history, showNotification }) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = FTACalculatorValidationSchema()
  const [disabled, setDisabled] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  const handlePopOverClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const popOverOpen = Boolean(anchorEl)
  const popOverId = popOverOpen ? 'about-fta' : undefined

  function onSubmit(data) {
    // console.log('Create New :: onSubmit ::\n', data)
  }

  return (
    <>
      {/* <Paper> */}
      <div
        className={`page-grid one-col edge-to-edge ${classes.sectionBlueBg}`}
      >
        <header
          className={`page-grid__col two-col-one-auto ${classes.sectionHeader}`}
        >
          {/* Left column */}
          <span>
            <Typography variant='h2' className={classes.formCaptionBig}>
              {FtaTranslatedTextsObject.provideNecessaryInfo}
            </Typography>
            <Typography variant='caption' className={classes.formCaptionSmall}>
              <span style={{color:'red'}}>*</span>
              {FtaTranslatedTextsObject.allFieldsAreMandatory}
            </Typography>
          </span>

          {/* Right column */}
          <span className='align-right'>
            {/* <Tooltip title='FTA Calculator Information'> */}
            <span className={classes.fnButtonCircle}>
              <CngIconButton
                icon={['fa-light', 'fa-circle-info']}
                type='outlined'
                onClick={handlePopOverClick}
              />

              <Popover
                id={popOverId}
                open={popOverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                PaperProps={{
                  style: { width: '30%', padding: '16px' }
                }}
              >
                <Typography variant='h2' className={classes.popoverTitle}>
                  {FtaTranslatedTextsObject.moduleTitle}
                </Typography>

                <Typography
                  variant='body1'
                  className={`${classes.popoverBody} mt-10`}
                >
                  {FtaTranslatedTextsObject.aboutFTAPopoverContent}
                </Typography>
              </Popover>
            </span>

            {/* </Tooltip> */}
          </span>
        </header>

        <main className={`page-grid__col one-col ${classes.sectionMain}`}>
          <CngForm
            formState={isLoading ? FormState.LOADING : FormState.COMPLETED}
            showNotification={showNotification}
            bodySection={
              <FTACalculatorForm.FormFields
                history={history}
                showNotification={showNotification}
                disabled={disabled}
              />
            }
            renderButtonSection={() => <></>}
            formikProps={{
              ...FTACalculatorForm.formikProps,
              validationSchema: Yup.lazy((values) => {
                return validationSchema
              }),
              onSubmit: onSubmit
            }}
            toClientDataFormat={FTACalculatorForm.toClientDataFormat}
            toServerDataFormat={FTACalculatorForm.toServerDataFormat}
          />
        </main>
      </div>
      {/* </Paper > */}
    </>
  )
}

export default FTACalculatorTabContent
