import React from 'react'
import {
  InputAdornment,
  TableRow,
  TableCell,
  TextField,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
  field: {
    "& > *": {
      borderRadius: 8
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.background.ctaBtnActiveBorder,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.component.textFormControl.hoverBorder
    },
    "& .MuiFormHelperText-root": { margin: theme.spacing(1, 0, 0) }
  },
  btn: {
    "& .ng-button-func:not(.Mui-disabled)": {
      background: 'transparent',
      color: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.component.cngButton.outline.activeFill
      }
    }
  }
}))

function TableFilters({ columns, ...props }) {
  const classes = useStyles()

  const handleSearch = (e, col, index) => {
    props.onFilterChanged(index, e.target.value)
  }

  return <TableRow>{columns.map((col, i) => {
    // strict equality since filterable by default

    return <TableCell key={i} className="filter">
      {col?.filtering === true && <>
        {col && col?.filterComponent
          ?
          <col.filterComponent {...col} tableData={{ ...col, id: i }} onFilterChanged={props.onFilterChanged} classes={classes} />
          :
          <TextField size="small" placeholder="Search value"
            variant="outlined" className={classes.field}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <FontAwesomeIcon icon={['fal', 'search']} />
              </InputAdornment>
            }}
            onChange={e => handleSearch(e, col, i)}
          />}
      </>}
    </TableCell>
  })}</TableRow>
}

export { TableFilters }
