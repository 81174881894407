import {
      Box,
      Button,
      DialogContent,
      Grid,
      IconButton,
      Tooltip,
      Typography,

      makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



const {
      CngDialog
} = components


function InfoDialog(props) {

      const handleClose = (preserveActive) => {
            props.onClose()
      }

      return (
            <CngDialog
                  open={props.open}
                  fullWidth
                  maxWidth='md'
                  dialogTitle={<Grid container wrap='nowrap' justify='space-between' alignItems='center'>
                        <Typography variant='h4'><b>{props.dialogTitle}</b></Typography>
                        <Tooltip title='Close'>
                              <IconButton className='cng-cia' size='small'
                                    onClick={handleClose}
                              >
                                    <FontAwesomeIcon icon={['fal', 'times']} />
                              </IconButton>
                        </Tooltip>
                  </Grid>}
                  dialogContent={props.dialogContent}
            />


      )
}

export default InfoDialog