import {
  Box,
  CardMedia as MuiCardMedia,
  makeStyles
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  media: {
    backgroundSize: 'contain',
    margin: '10vh 10vh 4vh 10vh'
  }
}))

function CardMedia(props) {
  const classes = useStyles()
  return (
      <Box component={MuiCardMedia} {...props}
        className={classes.media + (props.className ? ' ' + props.className : '')}
      />
  )
}
CardMedia.defaultProps = {
  height: 350
}
export default CardMedia
