import { Box, Paper, Grid, Chip, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CngBackdrop from '../components/CngBackdrop'
import { useFormContext } from 'react-hook-form'
import ViewFtaProductDetailsParamSummaryHeader from './ViewFtaProductDetailsParamSummaryHeader'
import RulesOfOriginTabContent from './RulesOfOriginTabContent'
import DutySavingsTabContent from './DutySavingsTabContent'
import RelatedBoMTabContent from './RelatedBoMTabContent'
import Utils from 'src/shared/Utils'
import GoBackButton from 'src/components/button/GoBackButton'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'

const { CngGridItem, CngTabs } = components

const DEFAULT_INITIAL_VALUES = Object.freeze({})

const FORMIK_PROPS = {
  initialValues: {}
}

const useStyles = makeStyles((theme) => ({
  innerTabsContainer: { 
    borderRadius: 0,
    '& > .MuiPaper-root': {
      borderRadius: 0
    },

    '& .page-grid': {
      rowGap: 'unset'
    }
  },
  sectionFooter: {
    paddingInline: '20px',
    paddingBlock: '20px'
  },
  stickyFooter: {
    backgroundColor: theme.palette.background.white,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    boxShadow: '0px -1px 2px -1px rgba(0, 0, 0, 0.2)',
    position: 'sticky',
    bottom: 0,
    zIndex: 1
  }
}))

function FormFields({ showNotification, ...props }) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const historyData = location.state
  const classes = useStyles()
  const [currencyCode, setCurrencyCode] = useState(historyData?.currencyCode)


  const [ftaCalcPayload, setFtaCalcPayload] = useState({
    ftaActivityHistoryId: historyData?.ftaActivityHistoryId,
    ftaActivityResultId: historyData?.individualProduct[0].ftaActivityResultId
  })

  const statusCodeFromParent = historyData?.individualProduct[0].statusCode

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  const navigateToCalculationResults = () => {
    history.push(`/cal-tmp/ftacalculator/productdetails`, {
      detailsData: historyData?.detailsData,
      calculationResults: historyData?.calculationResults,
      fromViewDetails: true,
      isRoutefromCalcScreen: historyData?.isRoutefromCalcScreen,
      productStatusCode: props?.productStatusCode,
      ftaActivityHistoryId: historyData?.ftaActivityHistoryId
    })
  }

  return (
    <Box className={`cng-details-page edge-to-edge`}>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box p={2} className='cng-details-page__content-container edge-to-edge'>
          {/* Summary header */}
          <ViewFtaProductDetailsParamSummaryHeader.FormBody
            disabled={false}
            paramsData={historyData?.paramsData || {}}
            individualProduct={historyData?.individualProduct || {}}
            ftaName={historyData?.ftaName || ''}
            sequenceNo={historyData?.sequenceNo || 0}
          />

          {/* FTA services tab */}
          <div className={`${classes.innerTabsContainer}`}>
            <CngTabs
              headerColor='primary'
              tabs={[
                {
                  tabName: CommonFtaTranslatedTextsObject.btnRulesOfOrigin,
                  tabContent: (
                    <RulesOfOriginTabContent
                      history={history}
                      showNotification={showNotification}
                      rooPayload={ftaCalcPayload}
                      statusCode={statusCodeFromParent}
                      currencyCode={currencyCode}
                      productStatusCode={historyData?.productStatusCode}
                      detailsData={historyData?.detailsData}
                      ftaName={historyData?.ftaName || ''}
                    />
                  )
                },
                {
                  tabName: CommonFtaTranslatedTextsObject.btnDutySavings,
                  tabContent: (
                    <DutySavingsTabContent
                      history={history}
                      showNotification={showNotification}
                      dutySavingsPayload={ftaCalcPayload}
                      statusCode={statusCodeFromParent}
                      currencyCode={currencyCode}
                    />
                  )
                },
                {
                  tabName: CommonFtaTranslatedTextsObject.btnRelatedBoM,
                  tabContent: (
                    <RelatedBoMTabContent
                      history={history}
                      showNotification={showNotification}
                      relatedBoMPayload={ftaCalcPayload}
                      statusCode={statusCodeFromParent}
                      currencyCode={currencyCode}
                    />
                  )
                }
              ]}
            />
          </div>

          {/* CTA button */}
          <div className={classes.stickyFooter}>
            <Grid container>
              <CngGridItem xs={12} sm={12}>
                <span className={`cta-btn-wrapper ${classes.sectionFooter}`}>
                  <GoBackButton
                    onClick={() => navigateToCalculationResults()}
                    label={
                      CommonFtaTranslatedTextsObject.btnBackToCalculationResults
                    }
                  />
                </span>
              </CngGridItem>
            </Grid>
          </div>
        </Box>
      </Paper>
    </Box>
  )
}

const ViewFtaProductDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default ViewFtaProductDetailsFormProperties
