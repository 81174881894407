import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useState } from 'react'
import { components, constants } from 'cng-web-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeleteDialog } from 'src/views/trademanagement/myproducts/components/DeleteDialog.js'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'


const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField
    },
  },



} = components

const {
  filter: { EQUAL }
} = constants


const DocumentDetails = (props) => {

  const [anchorMoreActionEl, setAnchorMoreActionEl] = React.useState(null)
  const open = Boolean(anchorMoreActionEl)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteData, setDeleteData] = useState({})

  const handleMoreActionClick = (event) => {
    setAnchorMoreActionEl(event.currentTarget)
  }

  const handleMoreActionClose = () => {
    setAnchorMoreActionEl(null)
  }

  const handleDelete = idx => {
    setDeleteData({
      idx: idx,
    })
    setDeleteDialogOpen(true)
  }

  const removeRowClick = idx => {
    props.onRemoveRow(idx)
    setAnchorMoreActionEl(null)
  }

  const downloadRowClick = idx => {
    props.onDownloadRow(idx)
    setAnchorMoreActionEl(null)
  }


  if (props.tile == null || !Array.isArray(props.tile) || props.tile.length <= 0) {
    return (<br />)
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id="table">
          <TableBody>
            {props.tile.map((elem, i) => {
              // return (<TableRow key={`row-${i}`}>
              return (<TableRow key={elem.id}>

                <TableCell className={`file-format`}> <FileCopyIcon /></TableCell>
                <TableCell><DocumentInfoDecoratorComponent name={elem.fileName} size={elem.fileSize} /> </TableCell>
                <TableCell>
                  <IconButton
                    edge='end'
                    className={`row-more-actions`}
                    onClick={handleMoreActionClick}
                  >
                    <MoreIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorMoreActionEl}
                    keepMounted
                    open={open}
                    onClose={handleMoreActionClose}
                    PaperProps={{
                      style: {
                        maxHeight: 'auto',
                        width: 'auto'
                      }
                    }}
                  >
                    <MenuItem key={'r'}
                      onClick={() => handleDelete(elem.id)}
                    >
                      <FontAwesomeIcon icon={['fal', 'trash']} style={{ marginRight: '0.5em' }} />
                      Delete
                    </MenuItem>
                    <MenuItem key={'d'}
                      onClick={() => downloadRowClick(elem.id)}
                    >
                      <FontAwesomeIcon icon={['fal', 'download']} style={{ marginRight: '0.5em' }} />
                      Download
                    </MenuItem>
                    <DeleteDialog open={deleteDialogOpen != false}
                      onClose={() => setDeleteDialogOpen(false)}
                      onComplete={() => removeRowClick(elem.id)}
                      {...deleteData}
                    />

                  </Menu>
                </TableCell>


              </TableRow>)
            })
            }
          </TableBody >
        </Table>

      </TableContainer>

    )
  }

}

export default DocumentDetails
