import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MTableCell } from 'material-table'

import { components } from 'cng-web-lib'
import { TableFooter, TableFilters } from '.'


const {
  button: { CngIconButton },
  form: { field: { CngTextField } },
  table: { CngTable },
} = components

export const useStyles = makeStyles((theme) => ({
  table: {
    '& thead th': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '.75rem',
      color: '#8181A5',
      lineHeight: 1.2,
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    },
    '& tbody td': {
      color: theme.palette.text.gray900,
      '&.filter': {
        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
      }
    },
    '& td, & th': {
      borderColor: theme.palette.background.ctaBtnActiveBorder
    }
  },
  // cell classes
  ellipsize: {
    maxWidth: '20em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  sku: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '.875rem'
  },
  hsDesc: {
    fontSize: '.75rem',
    color: theme.palette.text.textSecondary
  },
  action: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.background.ctaBtnActiveBorder,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius * 2,
    height: 36, width: 36,
  }
}))

export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50]


function TableBody({ key, data, pageInfo, columns, TableProps, ...props }) {
  const classes = useStyles()

  const handleAction = (e, action, rowData) => {
    e.preventDefault(); e.stopPropagation();
    action.onClick(e, rowData)
  }

  return (
    <Box className={classes.table} mt={props.noMT ? 0 : 2.5}>
      <CngTable
        key={key}
        columns={columns}
        data={data}
        pageSize={pageInfo ? pageInfo.itemPerPage : 5}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        isLoading={props.isLoading}
        components={{
          Pagination: mProps => <TableFooter {...mProps}
            pageInfo={pageInfo}
            onPageInfoUpdate={props.onPageInfoUpdate}
          />,
          FilterRow: mProps => <TableFilters {...mProps} columns={columns} />,
          Actions: mProps => <Grid container spacing={1} wrap="nowrap">
            {mProps.actions.map((a, i) => <Grid item key={`action-${i}`} >
              <Tooltip title={a.tooltip} >
                <IconButton
                  disabled={a.disabled}
                  onClick={e => handleAction(e, a, mProps)}
                  className={classes.action}
                  size="small"
                >
                  {typeof a.icon === 'string' ? <Box component={FontAwesomeIcon}
                    icon={['fal', a.icon]}
                    {...(a.iconProps || {})}
                  /> : a.icon}
                </IconButton>
              </Tooltip>
            </Grid>
            )}
          </Grid>,
        }}
        icons={{
          Check: 'check',
          Clear: 'times',
          Edit: 'pen'
        }}
        onRowClick={props.onRowClick}
        {...TableProps}
        options={{
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          ...TableProps?.options
        }}
      />
    </Box>
  )
}

export { TableBody }
