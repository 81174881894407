import React, { useState } from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack
} from '@mui/material'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ProfilePage from '../ProfilePage'

function SearchRecords(props) {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()
  function makeTranslatedSourceItem() {
    let source = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.SOURCE_SOURCE
    )
    let effectiveDate = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.SOURCE_EFFDATE
    )
    let expiryDate = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.SOURCE_EXPDATE
    )
    let downloadProf = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_DOWNLOAD
    )

    return {
      source,
      effectiveDate,
      expiryDate,
      downloadProf
    }
  }

  const [expand, setExpand] = useState(false)

  function handleExpand(indicator) {
    setExpand(!indicator)
  }

  return (
    <Accordion
      expanded={expand === true || props.expanded === 'all'}
      onChange={() => handleExpand(expand)}
      variant='outlined'
      disableGutters={true}
      style={{ borderRadius: '11px',marginBottom: '1vh', }}
      
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${props.indicator}-content`}
        id={`${props.indicator}-header`}
      >
        <Grid
          container
          style={{ alignItems: 'center', wordWrap: 'break-word' }}
        >
          <Grid item xs={4} style={{ paddingRight: '15px' }}>
            <Stack>
              <Typography color='textPrimary' variant='h5'>
                {props.party.name === null ? '- -' : props.party.name}
              </Typography>
              <Typography color='textSecondary' variant='body2'>
                {props.party.partyType === null ? '- -' : props.party.partyType}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={5} style={{ paddingRight: '15px' }}>
            <Stack>
              <Typography color='textSecondary' variant='body2'>
                {translatedItem.source}
              </Typography>
              <Typography color='textPrimary' variant='h5'>
                {props.party.dataSourceName === undefined ||
                props.party.dataSourceName === null
                  ? '- -'
                  : props.party.dataSourceName}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2} style={{ paddingRight: '15px' }}>
            <Stack>
              <Typography color='textSecondary' variant='body2'>
                {translatedItem.effectiveDate}
              </Typography>
              <Typography color='textPrimary' variant='h5'>
                {props.party.dpsRiskResultAdditionalInfo.restrictionDate ===
                null
                  ? '- -'
                  : props.party.dpsRiskResultAdditionalInfo.restrictionDate}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px' }}>
        <ProfilePage party={props.party}></ProfilePage>
      </AccordionDetails>
    </Accordion>
  )
}

export default SearchRecords
