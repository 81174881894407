import React, { useState, useEffect, useMemo, useContext, createRef } from 'react'
import {
  Box,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { components } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'

import { DialogContext, useFieldStyles } from './SearchDialog'

const {
  button: { CngButton },
  form: { field: { CngTextField } },
} = components

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

function CombinedQty(props) {
  const classes = useFieldStyles()
  const { qty } = useContext(DialogContext)
  const { getValues, setValue, register } = useFormContext()
  const [qtyVal, setQtyVal] = useState(
    qty.current[props.tableData.id]
    || getValues(`REF_components[${props.tableData.id}].quantity`)
    || ''
  )


  const id = useMemo(() => props.tableData.id, [props.tableData.id])
  const attached = useMemo(() =>
    getValues(`REF_components[${id}].attached`) || false,
    [getValues(`REF_components[${id}].attached`)]
  )
  const hasError = useMemo(() => attached && !qtyVal, [attached, qtyVal])

  const debouncedValue = useDebounce(qtyVal, 750)

  useEffect(() => {
    qty.current[id] = debouncedValue
  }, [debouncedValue])

  const handleAttach = e => {
    setValue(`REF_components[${id}].attached`, !attached)
  }


  return <Grid container spacing={1} wrap="nowrap">
    <Grid item><TextField
      label="Units" required
      variant="outlined"
      size="small" type="number"
      key={`qty-${props.tableData.id}-${id}`}
      value={qtyVal}
      className={classes.field}
      autoFocus={hasError}
      onChange={e => setQtyVal(e.target.value)}
      error={hasError}
      helperText={hasError && 'Please fill in quantity'}
    /></Grid>
    <Grid item className={attached ? classes.btnAttached: ''}>
      <CngButton size="small"
        color="secondary"
        startIcon={<FontAwesomeIcon icon={['fal',
          attached ? 'link-horizontal-slash' : 'link-horizontal'
        ]} />}
        onClick={handleAttach}
      >
        <Box fontWeight="fontWeightBold" px={1}>
          {attached ? 'Detach' : 'Attach'}
        </Box>
      </CngButton>
    </Grid>
  </Grid>
}

export { CombinedQty }
