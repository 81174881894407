import React, { useContext, useEffect, useState } from 'react'
import {
  Grid,
  IconButton
} from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { components, useServices } from 'cng-web-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import pathMap from 'src/paths/PathMap_TmpAdmin'

import { Field } from '.'
import { ProductContext } from '../ProductPage'
import { parseFormData } from '../helpers'
import CreatePrincipalProfileDialog from './CreatePrincipalProfileDialog.js'

const {
  form: { field: { CngLookupAutocompleteField } }
} = components

function CompanyInfo(props) {
  const { securedSendRequest } = useServices()
  const { getValues, setValue } = useFormContext()
  const [refreshPrincipal, setRefreshPrincipal] = useState(0)

  const handleCompanyInfo = data => setValue('REF_company', parseFormData(data))
  const handleRefreshPrincipal = () => setRefreshPrincipal(refreshPrincipal + 1)

  const [isPrincipalDialogOpen, setPrincipalDialogOpen] = useState(false)

  function closePrincipalDialog() {
    setRefreshPrincipal(refreshPrincipal + 1)
    setPrincipalDialogOpen(false)
  }

  return <Grid container spacing={1}>
    <Grid item xs={12} md={4}>
      <Field label="Entity name"
        key={refreshPrincipal}
        name="companyId"
        required
        component={CngLookupAutocompleteField}
        lookupProps={{
          url: TradeMgmtApiUrls.GET_COMPANY_INFO,
          label: 'name',
          value: 'id'
        }}
        onLookup={handleCompanyInfo}
      />
      <div>
        <a
          href="#"
          onClick={() => {
            setPrincipalDialogOpen(true)
          }}>
          <FontAwesomeIcon icon={['fal', 'plus']} size={'xs'} /> Create Principal
        </a>
        &emsp;
        <IconButton size='small'
          onClick={handleRefreshPrincipal}
        >
          <FontAwesomeIcon icon={['fal', 'arrows-rotate']} />
        </IconButton>
      </div>

    </Grid>
    <Grid item xs={12} md={4}>
      <Field label="Registration no."
        name="REF_company.registerNo"
        disabled
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <Field label="Reference no."
        name="REF_company.referenceNo"
        disabled
      />
    </Grid>
    <Grid item xs={12}>
      <Field label="Business address"
        name="REF_company.address"
        disabled
      />
    </Grid>
    <CreatePrincipalProfileDialog
      isDialogOpen={isPrincipalDialogOpen}
      closeDialog={closePrincipalDialog}
      showNotification={() => { }} />
  </Grid>

}

export { CompanyInfo }
