import { BasePage as CngBasePage } from 'cng-web-lib'
import TimezoneUserPrefPage from './TimezoneUserPrefPage.js'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_UserPreference'
import TranslationText from '../shared/UserPreferenceTranslationText'

function WrappedTimezoneUserPrefPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.userPreference}
      renderPage={(showSnackbar) => (
        <TimezoneUserPrefPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { WrappedTimezoneUserPrefPage as TimezoneUserPrefPage }
