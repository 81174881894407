import {Box, Chip, Grid, Typography} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'
import OriginCalculationSPForm from "./OriginCalculationSPForm";
import moment from "moment/moment";
import TradeMgmtApiUrls from "../../../apiUrls/TradeMgmtApiUrls";

const {
    CngMoreActionsMenu,
    card: {
        CngSimpleCardHeader,
    },
    form: {
        field: { CngDateField },
        CngEditFormButtonSection
    },
    CngDialog,
    button: { CngPrimaryButton, CngSecondaryButton },
    CngGridItem
} = components

const moreActionsList = [
    {
        action: 'setSPRule',
        name: 'Origin calculation checklist',
        icon: ['fal', 'list']
    },
    {
        action: 'setDate',
        name: 'Set qualification expiry',
        icon: ['fal', 'edit']
    }
]

const RulesOfOriginSPDetailsTop = ({ ...props }) => {
    const { spStatus, setSpStatus, assessor, spCriterionHolder, dates, setDates, datesCardKey, setDatesCardKey } = props

    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const [statusText, setStatusText] = useState('')

    const [statusColor, setStatusColor] = useState('')

    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const FtaTranslatedTextsObject = TradeManagementTranslationText()

    const { fetchRecords } = useServices();

    const statusList = [
        {
            statusCode: 'QUAL',
            statusName: FtaTranslatedTextsObject.statusQualified,
            color: '#7CE7AC4D',
            actions: []
        },
        {
            statusCode: 'DQUA',
            statusName: FtaTranslatedTextsObject.statusDNQualified,
            color: '#1C1D214D',
            actions: []
        },
        {
            statusCode: 'SELF',
            statusName: FtaTranslatedTextsObject.statusSelfAssessmentRequired,
            color: '#F578B24D',
            actions: []
        },
        {
            statusCode: 'PRIC',
            statusName: FtaTranslatedTextsObject.statusPriceDependent,
            color: '#5E81F44D',
            actions: []
        }
    ]

    function populateStatusTitle() {
        let statusString = statusList.find(
            (b) => b.statusCode === spStatus
        )?.statusName
        setStatusText(statusString)

        let statusColor = statusList.find(
            (b) => b.statusCode === spStatus
        )?.color
        setStatusColor(statusColor)
    }

    useEffect( () => {
        populateStatusTitle();
    }, [])

    useEffect( () => {
        populateStatusTitle();
    }, [spStatus])

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleMoreAction = (data, action) => {
        switch (action) {
            case 'setDate':
                console.log('data.action ::', 'Set expiry date')
                setDatePickerOpen(true)
                break

            case 'setSPRule':
                console.log('data.action ::', 'Set checklist')
                setIsDialogOpen(true);
                break
        }
    }

    const handleDatePickerOnClose = (thisDate, value) => {
        setDatePickerOpen(false)
    }

    const handleDatePickerOkClick = (date) => {
        let formattedDate = moment.utc(date).format()
        console.log("formatted date:["+JSON.stringify(formattedDate)+"]")
        setDatePickerOpen(false)

        const onSuccess = (response) => {
            console.log("Expiry date submitted successfully.updated date:["+JSON.stringify(response?.spExpiryDate)+"]")
            setDatesCardKey(datesCardKey+1)
            const qDate = dates.qualificationDate
            setDates({
                qualificationDate: qDate,
                qualificationExpiry: response?.spExpiryDate
            })
        }

        const onError = (error) => {
            console.log("Error in submitting expiry date:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => {
            console.log("Expiry date submitting completed")
        }

        fetchRecords.execute(
            TradeMgmtApiUrls.SET_SP_EXPIRY,
            {
                customData: {spCriterionHolder: spCriterionHolder, spExpiryDate: formattedDate},
                filters: []
            },
            onSuccess,
            onError,
            onComplete
        )

    }

    return (
        <header className={`cng-summary-card--header-box`} key={spCriterionHolder?.hsCode}>
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={12} sm={4}>
                    <div className={`cng-summary-card--header-box--left`}>
                        <Chip
                            label={
                                <Typography className={`font-mediumBold font-16`}>
                                    {statusText?.toUpperCase()}
                                </Typography>
                            }
                            style={{ backgroundColor: statusColor }}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={8}>
                    <div className={`cng-summary-card--header-box--right`}>
                        <div className={`header-box--right-col-1 text-right-align box-col`}>
                            <div className={`field-vertical--label-value`}>
                                <Typography className={'frb-card-label-large-grey'}>
                                    {FtaTranslatedTextsObject.assessedBy}
                                    <b className='ml-5'>{assessor !== null ? assessor : `-`}</b>
                                </Typography>
                            </div>
                        </div>



                        {props.productStatusCode !== 'DELE' && (
                            <div className={`header-box--right-col-2 cta-box box-col`}>
                                <div className={`cng-summary-card--more-actions-wrapper`}>
                                    <CngMoreActionsMenu
                                        type='primary'
                                        size='large'
                                        style={{ color: '#7680a3' }}
                                        menuItems={moreActionsList}
                                        iconDirection='horizontal'
                                        spData={spCriterionHolder}
                                        data={null}
                                        onActionSelect={handleMoreAction}
                                    />
                                </div>
                            </div>
                        )}


                    </div>
                </Grid>

                {/*start popup model*/}
                <CngDialog
                    dialogContent={
                        <OriginCalculationSPForm setIsDialogOpen={setIsDialogOpen} {...props}/>
                    }
                    dialogTitle={
                        <Box>
                            <Grid container spacing={2}>
                                <CngGridItem item xs={12}>
                                    Origin calculation checklist
                                </CngGridItem>
                            </Grid>
                        </Box>
                    }
                    open={isDialogOpen}
                    onClose={handleClose}
                    shouldShowCloseButton
                />
                {/*end popup model*/}

                {/*start expiry data selection*/}
                <CngDateField
                    name={`spExpireDate`}
                    label={'date'}
                    format='DD-MMM-YYYY'
                    orientation='landscape'
                    shouldDisableDate={(date) => {
                        return moment(moment().format('DD-MMM-YYYY')).isAfter(
                            moment(date)
                        )
                    }}
                    style={{
                        display: 'none'
                    }}
                    open={datePickerOpen}
                    onAccept={(date) => handleDatePickerOkClick(date)}
                    onClose={() => handleDatePickerOnClose()}
                />
                {/*end expiry data selection*/}

            </Grid>
        </header>
    )
}

export default RulesOfOriginSPDetailsTop
