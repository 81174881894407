import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Namespace from 'src/constants/locale/Namespace'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'
import { useTranslation } from 'cng-web-lib'

export default function ResearchDialog(props) {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()

  function makeTranslatedSourceItem() {
    let dialogTitle = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.DIALOG_SEARCHAGAIN_TITLE
    )
    let dialogContent = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.DIALOG_SEARCHAGAIN_CONTENT
    )
    let dialogAccept = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.DIALOG_SEARCHAGAIN_ACTION_YES
    )
    let dialogReject = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.DIALOG_SEARCHAGAIN_ACTION_NO
    )

    return {
      dialogTitle,
      dialogContent,
      dialogAccept,
      dialogReject
    }
  }

  function searchAgain(event) {
    event.stopPropagation()
    props.agree(props.name, props.partyType)
  }

  return (
    <div>
      <Dialog
        open={props.dialog}
        onClose={props.off}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        disableEnforceFocus
      >
        <DialogTitle id='alert-dialog-title'>
          {translatedItem.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {translatedItem.dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.off}>{translatedItem.dialogReject}</Button>
          <Button variant='contained' onClick={(e) => searchAgain(e)} autoFocus>
            {translatedItem.dialogAccept}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
