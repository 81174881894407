import { Box, Paper, Grid, Chip, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CngBackdrop from '../components/CngBackdrop'
import { useFormContext } from 'react-hook-form'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faLacrosseStick } from '@fortawesome/pro-light-svg-icons'
import Utils from 'src/shared/Utils'
import GoBackButton from 'src/components/button/GoBackButton'

const { CngGridItem } = components

const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    padding: '20px',
    borderRadius: '8px',

    '&.no-bottom-radius': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  separatorInBetween: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content',
    alignItems: 'center',

    '& .divider-vertical': {
      height: '100%',
      width: '1px',
      backgroundColor: Utils.hexToRgba(theme.palette.color.primaryGray, 0.2),
      display: 'flex',
      marginInline: '8px'
    },

    '& > .field-vertical--label-value': {
      marginBlockEnd: 0
    }
  },
  chapterBlock: {
    backgroundColor: theme.palette.color.white,
    padding: '16px',
    alignItems: 'center',
    justifyItems: 'center',
    borderRadius: '10px',
    minWidth: '90px',
    display: 'grid',

    '& > .field-vertical--item': {
      justifyContent: 'center'
    }
  }
}))

const FORM_INITIAL_VALUES = Object.freeze({})

const FormBody = ({ disabled, ...props }) => {
  const {
    hsCodeAndDescription,
    productDescription,
    chapterNo,
    cifValue,
    baseUnit,
    unitOfMeasurement,
    unitOfMeasurementName,
    currency,
    exworksCost,
    fobPrice,
    transactionValue,
    netCosts,
    totalCosts
  } = props.paramsData

  console.log('props.individualProduct:\n', props.individualProduct)

  let ftaName = props.ftaName

  const history = useHistory()
  const classes = useStyles()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  const navigateToCalculate = () => {
    history.push(`/cal-tmp/ftacalculator/productdetails`)
  }

  return (
    <>
      {/* Section */}
      <Box
        className={`cng-details-page__section ${classes.sectionBlueBg} no-bottom-radius`}
      >
        {/* Row 1 - single column */}
        <div className='section-view-fta-details header one-col mb-20'>
          <div className={`separator-in-between ${classes.separatorInBetween}`}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label font-16 font-mediumBold`}>
                  {props.sequenceNo + 1}
                </label>
              </span>
            </div>

            <div className={`divider-vertical`}></div>

            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-value font-16 font-mediumBold dark`}>
                  {ftaName}
                </label>
              </span>
            </div>
          </div>
        </div>

        {/* Row 2 -Two column - minmax 180px */}
        <div className='section-view-fta-details header two-col-minmax-180'>
          <div>
            <div className={`${classes.chapterBlock}`}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item justify-center`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.chapter}
                  </label>
                </span>

                <span className={`field-vertical--item justify-center`}>
                  <label className={`field-value font-32`}>{chapterNo}</label>
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className='one-col'>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.chapterDescription}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value `}>{productDescription}</label>
                </span>
              </div>
            </div>

            <div className='one-col'>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.hsCodeAndDescription}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {hsCodeAndDescription}
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='section-view-fta-details details one-col mb-20' style={{ paddingTop: '10px' }}>
          <Grid container className='cng-grid-container'>
            {/* Row 2 */}
            <CngGridItem xs={12} sm={2}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {'Base Unit'}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{baseUnit}</label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={2}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.unitOfMeasurement}
                  </label>
                </span>

                <div className={`field-country-details`}>
                  <Chip
                    label={<b style={{ fontSize: 10 }}>{unitOfMeasurement}</b>}
                    size='small'
                    style={{ marginRight: 5 }}
                    className={`field-country-code`}
                  />

                  <label className={`field-value`}>{unitOfMeasurementName}</label>
                </div>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={2}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.currency}
                  </label>
                </span>

                <div className={`field-country-details`}>
                  <Chip
                    label={<b style={{ fontSize: 10 }}>{currency}</b>}
                    size='small'
                    style={{ marginRight: 5 }}
                    className={`field-country-code`}
                  />

                </div>
              </div>
            </CngGridItem>


            <CngGridItem xs={12} sm={3}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.cifValue}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{cifValue}</label>
                </span>
              </div>
            </CngGridItem>
          </Grid>
        </div>






        <div className='section-view-fta-details details one-col mb-20' style={{ paddingTop: '10px' }}>
          <Grid container className='cng-grid-container'>
            {/* Row 3 */}

            <CngGridItem xs={12} sm={3}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.exworksCost}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{exworksCost}</label>
                </span>
              </div>
            </CngGridItem>


            <CngGridItem xs={12} sm={2}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.fobPrice}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{fobPrice}</label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={2}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.transactionValue}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{transactionValue}</label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={2}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.netCosts}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{netCosts}</label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.totalCosts}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{totalCosts}</label>
                </span>
              </div>
            </CngGridItem>


          </Grid>
        </div>
      </Box>
    </>
  )
}

const ViewFtaProductDetailsParamSummaryHeader = Object.freeze({
  FormBody: FormBody,
  initialValues: FORM_INITIAL_VALUES
})

export default ViewFtaProductDetailsParamSummaryHeader
