import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Moment from 'moment'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'

import { useServices, constants } from 'cng-web-lib'

import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'

import { TwoLines, CodeDisplay, LTSDFilter, useComponentStyles } from '../components'
import { useStyles } from '../components/TableBody'

const {
  CodeMaintenanceType,
  apiUrl: { CodeMaintenanceApiUrls },
  filter: { EQUAL }
} = constants

const DATE_FORMAT = 'DD MMM YYYY'
export const formatDate = (date, withTime) => {
  let d = Moment(date)
  return d._isValid
    ? d.format(DATE_FORMAT + (withTime ? ', HH:mm' : ''))
    : "Unknown date"
}

const CODE_MAINT = {
  url: CodeMaintenanceApiUrls.GET,
  data: code => ({
    customData: { codeMType: CodeMaintenanceType[code] || code },
    filters: [{
      field: 'active',
      operator: EQUAL,
      value: true
    }],
  })
}

export const COLUMN_BUILDER_URLS = {
  codeMaint: CODE_MAINT.url,
  tmp: TradeMgmtApiUrls.GET_CODEMASTER,
  getTmp: k => TradeMgmtApiUrls[k]
}

export function useColumnBuilder(codesToFetch, actionable) {
  const classes = useStyles()
  const compClasses = useComponentStyles()
  const { fetchRecords } = useServices()
  const { enqueueSnackbar } = useSnackbar()

  const [fetchedCodes, setFetchedCodes] = useState({})
  const [inProgress, setInProgress] = useState(false)
  const loading = useMemo(() => {
    if (codesToFetch && codesToFetch.length)
      return Object.keys(fetchedCodes).length < codesToFetch.length
    return false
  }, [fetchedCodes])

  useEffect(() => {
    let isSubscribed = true

    async function getCodes() {
      const codes = codesToFetch.map(async ({ url, payload }) => {
        let customData = {}
        let code = payload.toUpperCase()
        if (url === COLUMN_BUILDER_URLS.codeMaint)
          customData = CODE_MAINT.data(code.toUpperCase())
        else if (url === COLUMN_BUILDER_URLS.tmp)
          customData = { customData: { codeMType: code.toUpperCase() } }

        return await fetchRecords.execute(url, customData,
          res => isSubscribed && Promise.resolve({ [code]: res.content }),
          () => enqueueSnackbar(`Error retrieving ${code} information`, { variant: 'error' })
        )
      })

      Promise.all(codes).then(vals => setFetchedCodes(
        vals.reduce((acc, cur) => ({ ...acc, ...cur }), {})
      ))
    }

    if (codesToFetch)
      getCodes()

    return () => (isSubscribed = false)
  }, [])

  /**** LANDING PAGE TABLE COLUMNS ****/
  const COLS_LANDING = [
    {
      title: 'Material no. / SKU', field: 'materialSkuNo', filtering: false, render: rowData =>
        <Typography className={classes.sku}>{rowData.materialSkuNo}</Typography>
    },
    {
      title: 'HS Code / Description', field: 'hsCodeAndDescription', filtering: false, render: rowData => {
        const [hsCode, desc] = rowData.hsCodeAndDescription.split(' | ')
        return <Tooltip title={desc}><Box>
          {hsCode}
          <Typography className={clsx(classes.hsDesc, classes.ellipsize)}>
            {desc}
          </Typography>
        </Box></Tooltip>
      }
    },
    {
      title: 'Ex Works (EXW) price', field: 'exworkPrice', filtering: false, render: rowData =>
        <Box component={Grid} container spacing={1} alignItems='center'>
          <Grid item>
            <Chip label={rowData.currency}
              variant="outlined" size="small"
              className={compClasses.chip}
            />
          </Grid>
          <Grid item>{rowData.exworkPrice}</Grid>
        </Box>
    },
    {
      title: 'Product Description', field: 'productDescription', filtering: false, render: rowData =>
        <Box className={classes.ellipsize}>
          {rowData.productDescription}
        </Box>
    }, {
      title: 'Last Modified', field: 'updatedDate', filtering: false,
      render: rowData => formatDate(rowData.updatedDate, true)
    },
  ]

  /**** BOM TABLE COLUMNS ****/
  const COLS_BOM = [].concat(
    actionable ? [] : [{
      title: '#', field: 'displayId', width: 100,
    }], [{
      title: 'Origin country / Component no. & name', field: 'oriCountry',
      filtering: true,
      type: 'string',
      render: rowData => <TwoLines
        title={<Box fontWeight="fontWeightBold">
          {fetchedCodes.COUNTRY.find(x => x.id === parseInt(rowData.oriCountry))?.name || "Unknown"}
        </Box>}
        sub={<>{rowData.componentNumber} : {rowData.componentName}</>}
      />,
      customFilterAndSearch: (term, rowData) => {
        let s = term.toLowerCase()
        let lookup = fetchedCodes.COUNTRY.find(x => x.id === parseInt(rowData.oriCountry))?.name
        return lookup?.toLowerCase().includes(s)
          || rowData.componentNumber?.toLowerCase().includes(s)
          || rowData.componentName?.toLowerCase().includes(s)
      }
    }, {
      title: `Customs tariff no. / UOM /${!actionable ? ' Unit /' : ''} Material type`,
      field: 'customTariffNumber',
      filtering: true,
      type: 'string',
      width: '20%',
      render: rowData => <TwoLines
        title={rowData.customTariffNumber}
        sub={<>
          <CodeDisplay chip={rowData.uom} display="inline-flex">
            {!actionable && rowData.quantity}
          </CodeDisplay>
          {!actionable && ' :'}&nbsp;
          {fetchedCodes.TMP_COMPONENT_MATERIAL.find(x => x.id === rowData.materialType)?.description}
        </>}
      />,
      customFilterAndSearch: (term, rowData) => {
        let s = term.toLowerCase()
        let lookup = fetchedCodes.TMP_COMPONENT_MATERIAL.find(x => x.id === rowData.materialType)?.description || ''
        return lookup.toLowerCase().includes(s)
          || rowData.uom?.toLowerCase().includes(s)
          || rowData.customTariffNumber?.toLowerCase().includes(s)
      }
    }, {
      title: 'LTSD status / Start - end date', field: 'ltsd',
      filtering: true,
      render: rowData => <TwoLines
        title={rowData.ltsd ? "Yes" : "No"}
        sub={rowData.ltsd && <>
          {formatDate(rowData.ltsdStartDate)}
          &nbsp;-&nbsp;
          {formatDate(rowData.ltsdEndDate)}
        </>}
      />,
      filterComponent: props => <LTSDFilter {...props} />,
      customFilterAndSearch: ({ ltsd, date }, rowData) => {
        if (ltsd === "Yes") {
          if (date)
            // check if date is within range
            return rowData.ltsd && Moment(date).startOf('date').isBetween(
              Moment(rowData.ltsdStartDate).startOf('date'),
              Moment(rowData.ltsdEndDate).endOf('date'),
              undefined, '[]'
            )
          return rowData.ltsd
        }
        if (ltsd === "No")
          return !rowData.ltsd
        return true
      }
    }, {
      title: 'Standard price per UOM', field: 'standardPricePerUom',
      filtering: true,
      width: '10%'
    }, {
      title: 'Supplier / manufacturer name', field: 'supplierName',
      filtering: true,
      width: '15%'
    },
  ])

  return {
    loading,
    COLS_LANDING,
    COLS_BOM,
  }
}
