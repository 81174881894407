import React, { useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import {
  makeStyles
} from '@material-ui/core'

import RelatedBoMForm from './RelatedBoMForm'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'



const useStyles = makeStyles((theme) => ({}))

function RelatedBoMTabContent({ history, showNotification, ...props }) {
  const [disabled, setDisabled] = useState(true)

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  function onSubmit(data) {
    // console.log('Create New Parcel :: onSubmit ::\n', data)
  }

  return (
    <>
      <div className='page-grid child-of-grid one-col mh-200'>
        <main className='page-grid__col one-col'>
          <RelatedBoMForm.FormFields
            history={history}
            showNotification={showNotification}
            disabled={disabled}
            relatedBoMPayload={props.relatedBoMPayload}
            statusCode={props.statusCode}
          />
        </main>
      </div>
    </>
  )
}

export default RelatedBoMTabContent
