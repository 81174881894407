import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import React, { useContext, useRef, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import { v4 as uuid } from 'uuid'
import { useFieldArray } from 'react-hook-form'
import { DocumentDetailsComponent } from '.'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'

import { ProductContext } from '../../ProductPage'


const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem,
    dropzone: { CngFileUpload, HelperTextArea },
    table: { useDefaultNotification }
} = components

const initialValues = {
    supportDocs: {
        description: '',
        docType: ''
    }
}
const {
    filter: { EQUAL }
} = constants



const FormBody = (props) => {


    const { fields: pendingDocField } = useFieldArray({
        name: 'pendingSupportDocuments'
    })
    const { fields: docField } = useFieldArray({
        name: 'supportDocuments'
    })


    const { fetchRecords, securedSendRequest } = useServices()


    const [pendingDocs, setPendingDocs] = useState([])  //for the file list below drop zone
    const [uploadedDocs, setUploadedDocs] = useState([]) //for the file list above the drop zone
    const [deletedDocs, setDeletedDocs] = useState([])
    const [fieldConfig] = useState([])
    const [fieldDict] = useState([]) //for display that fits the wireframe more in uploadedDocs section
    const { errors, touched, setFieldValue } = useFormikContext()
    const docTypeKeyRef = useRef(uuid())

    const { error: showErrorNotification } = useDefaultNotification(props.showNotification)
    const { editing } = useContext(ProductContext)

    const removeUploadedRowClick = (id) => {
        let jobDocId = uploadedDocs[id].id
        const deletedDocList = [...deletedDocs]
        deletedDocList.push(jobDocId)
        setDeletedDocs(deletedDocList)
        setFieldValue('deletedDocIds', deletedDocList)

        uploadedDocs.splice(id, 1);
        const newFiles = [].concat([], uploadedDocs)
        setUploadedDocs(newFiles)
        setFieldValue('supportDocuments', newFiles)
        docTypeKeyRef.current = uuid()
    }

    const handleUploadedInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...uploadedDocs]
        list[index][name.split('.')[1]] = value
        setUploadedDocs(list)
        setFieldValue('supportDocuments', list)
    }

    const handleUploadedDropdownChange = (val, evt, index, name) => {
        if (evt) {
            const { value } = evt
            const list = [...uploadedDocs]
            list[index][name] = value
            setUploadedDocs(list)
            setFieldValue('supportDocuments', list)
        }
    }

    const editRowClick = (id) => {
        const newFiles = [].concat([], uploadedDocs)
        newFiles[id].editable = true
        setUploadedDocs(newFiles)
        setFieldValue('supportDocuments', newFiles)
    }

    const downloadUploadedRowClick = id => {
        downloadFile(uploadedDocs[id])
    }

    function downloadFile(doc) {
        securedSendRequest.execute(
            "POST",
            TradeMgmtApiUrls.DOCUMENT_DOWNLOAD,
            {
                id: doc.id
            },
            (data) => {

                const link = data.data.content[0].fileUrl
                window.open(link, '_blank').focus()
            },
            (error) => {
                console.log(error)
            },

        )
    }

    const getFieldError = (itemIndex, fieldPath) => {
        if (errors) {
            let pathArr = fieldPath.split('.')

            if (
                errors[pathArr[0]] &&
                errors[pathArr[0]][itemIndex] &&
                errors[pathArr[0]][itemIndex][pathArr[1]]
            ) {
                if (touched) {
                    if (
                        touched[pathArr[0]] &&
                        touched[pathArr[0]][itemIndex] &&
                        touched[pathArr[0]][itemIndex][pathArr[1]]
                    ) {
                        return errors[pathArr[0]][itemIndex][pathArr[1]]
                    } else {
                        return null
                    }
                }
            }
        }
        return null
    }

    const showErrorMessages = (errorMessages) => {
        console.log("showErrorMessages")
        showErrorNotification(errorMessages[0])
    }

    const moreActions = [
        {
            action: 'remove',
            name: 'Delete',
            icon: ['fal', 'trash']
        }
    ]

    function fetchDocuments() {
        fetchRecords.execute(
            TradeMgmtApiUrls.DOCUMENT_GETBYPRODUCTID,
            { customData: { id: props.id } },
            (data) => {
                setPendingDocs(pendingDocField)
                let uploadedDocList = docField
                if (uploadedDocList != null) {
                    let uploadedDocListWithEditable = data.map((v) => ({ ...v, editable: false }))
                    setUploadedDocs(uploadedDocListWithEditable)
                    setFieldValue('supportDocuments', uploadedDocListWithEditable)
                }
            }
        )
    }


    const setSelectedFiles = (file) => {
        //long file name

        let processedFileObjs = []
        file.forEach((item) => {
            processedFileObjs.push(item)
        })
        setFieldValue('pendingSupportDocuments', processedFileObjs)
    }

    if (!editing)
        return <></>



    return (
        // <Grid container style={{ padding: -2, display: 'block' }} >
        <Card>
            <Accordion expanded={true} style={{ marginBottom: 20 }}>
                <AccordionSummary
                    aria-controls='supportDocumentContent'
                    id='supportDocumentComponentHeader'>
                    <Grid container>
                        <Grid container spacing={2} >
                            <CngGridItem xs={12} sm={12}>
                                <AccordionHeaderComponent title="Documents" hideMandatory />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    <Grid container spacing={2}>
                        <CngGridItem xs={12} sm={12} shouldHide={uploadedDocs.length > 0 ? false : true}>
                            <DocumentDetailsComponent
                                tile={[]}
                                removeRowClick={removeUploadedRowClick}
                                handleInputChange={handleUploadedInputChange}
                                handleDropdownChange={handleUploadedDropdownChange}
                                editRowClick={editRowClick}
                                downloadRowClick={downloadUploadedRowClick}
                                getFieldError={getFieldError}
                                docTypeKeyRef={docTypeKeyRef.current}
                                fieldDict={fieldDict}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={12}>
                            <CngFileUpload
                                accept={[
                                    '.doc',
                                    '.docx',
                                    '.xls',
                                    '.xlsx',
                                    '.pdf',
                                    '.jpg',
                                    '.jpeg',
                                    '.png',
                                    '.bmp',
                                    '.gif',
                                    '.tif',
                                    '.tiff'

                                ]}
                                maxSize={10485760}
                                maxFiles={3}
                                onFileSelect={setSelectedFiles}
                                showFormFields={false}
                                files={pendingDocs}
                                fieldConfig={fieldConfig}
                                moreActions={moreActions}
                                onDropRejected={(error) => showErrorMessages(error)}
                                renderHelperText={() => {
                                    return (
                                        <HelperTextArea
                                            accept={[
                                                '.doc',
                                                '.docx',
                                                '.xls',
                                                '.xlsx',
                                                '.pdf',
                                                '.jpg',
                                                '.jpeg',
                                                '.png',
                                                '.bmp',
                                                '.gif',
                                                '.tif',
                                                '.tiff'
                                            ]}
                                            maxFiles={3}
                                            maxSize={1048576}
                                        />
                                    )
                                }}
                            />
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

        </Card >
        // </Grid>
    )
}


const DocumentComponent = Object.freeze({
    FormBody,
    initialValues
})

export default DocumentComponent