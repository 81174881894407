import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    FormLabel
} from '@material-ui/core'
import { components } from 'cng-web-lib'
import {makeStyles} from "@material-ui/core/styles";
import RulesOfOriginSPDetailsTop from "./RulesOfOriginSPDetailsTop";
import TradeManagementTranslationText from "../shared/TradeManagementTranslationText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-light-svg-icons";
import RulesOfOriginListCardDetailBottom from "./RulesOfOriginListCardDetailBottom";

const {
    CngGridItem,
    button: { CngIconButton }
} = components

const initialValues = Object.freeze({})

const useStyles = makeStyles((theme) => ({
    fnButtonCircle: {
        '& .ng-button-func': {
            borderRadius: '50% !important',
            '&.medium': {

                width: '32px',
                height: '32px',
                minHeight: '32px',

                boxShadow: 'none',
                backgroundColor: 'transparent',
                borderWidth: '0'
            },
            '& svg': {
                backgroundColor: theme.palette.text.textSecondary,
                borderRadius: '50%',
                color: theme.palette.color.white,
                fontSize: '20px !important',
                padding: '4px'
            }
        }
    },
    tooltipContent: {
        fontSize: '14px',
        fontWeight: 400
    },
    tooltipLabel: {
        fontSize: '14px',
        fontWeight: 400
    },
    chipInfo: {
        color: theme.palette.text.textSecondary,
        cursor: 'pointer',
        fontWeight: 500,
        paddingBlock: '15px'
    },
    chipLabel: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',

        '& svg': {
            fontSize: '20px',
            marginInlineStart: '5px'
        }
    }
}))

const FormBody = (props) => {
    const classes = useStyles()

    const {
        originCalculation: {
            assessor,
        },
        spCriterionHolder
    } = props.product

    const FtaTranslatedTextsObject = TradeManagementTranslationText()

    const [spStatus, setSpStatus] = useState(spCriterionHolder?.spStatus)
    const [qvcRvc, setQvcRvc] = useState("-")
    const [spCalculation, setSpCalculation] = useState("-")
    const [concatenatedRules, setConcatenatedRules] = useState("")

    const [datesCardKey, setDatesCardKey] = useState(1)
    const [dates, setDates] = useState({
        qualificationDate: spCriterionHolder.qualificationDate,
        qualificationExpiry: spCriterionHolder.qualificationExpiry
    })

    useEffect(() => {

        if(spCriterionHolder?.ftaOriginCalculationEntity?.qvc !== null && spCriterionHolder?.ftaOriginCalculationEntity?.rvC !== null) {
            setQvcRvc(spCriterionHolder.ftaOriginCalculationEntity.qvc + '/' + spCriterionHolder.ftaOriginCalculationEntity.rvC)
        } else if(spCriterionHolder?.ftaOriginCalculationEntity?.qvc !== null) {
            setQvcRvc(spCriterionHolder.ftaOriginCalculationEntity.qvc)
        } else if (spCriterionHolder.ftaOriginCalculationEntity.rvc !== null) {
            setQvcRvc(spCriterionHolder.ftaOriginCalculationEntity.rvc)
        } else {
            setQvcRvc("-")
        }

        if(spCriterionHolder?.ftaOriginCalculationEntity?.calculation != null) {
            let c = spCriterionHolder?.ftaOriginCalculationEntity?.calculation
            setSpCalculation(c + "%")
        } else {
            setSpCalculation("-")
        }

        const criterianList = spCriterionHolder?.criterionEntityList.map((c, index) => {
            return (
                c.productSpecificRule
            );
        })
        const uniqueCriterianList = Array.from(new Set(criterianList));

        setConcatenatedRules(uniqueCriterianList.map(item => item).join(' | '))

    },[])

    return (
        <Card className={`summary-list-item--card`}>

            {/* start card header*/}
            <RulesOfOriginSPDetailsTop
                assessor={assessor}
                spStatus={spStatus}
                setSpStatus={setSpStatus}
                handleSetQualificationDate={props.handleSetQualificationDate}
                spCriterionHolder={spCriterionHolder}
                dataIndex={props.dataIndex}
                dates={dates}
                setDates={setDates}
                datesCardKey={datesCardKey}
                setDatesCardKey={setDatesCardKey}
                productStatusCode={props?.productStatusCode}
            />
            {/* end card header*/}

            {/* start card body */}
            <div className={`cng-summary-card--body-box`}>
                <Grid container className={`cng-summary-card--body-grid`}>
                    <CngGridItem xs={12} sm={3}>
                        <div className={`field-vertical--label-value`} style={{marginInlineStart: '12px'}}>
                            <span className={`field-vertical--item`}>
                                <label
                                    className={`field-value clamp-line-to-two font-16 pr-10`}
                                    style={{textOverflow: 'ellipsis', overflow: 'hidden', wordBreak: 'break-word'}}
                                >
                                  <Tooltip
                                      title={spCriterionHolder?.criterionEntityList[0]?.criterion}
                                      placement='bottom'
                                      className={classes.tooltipContent}
                                      classes={{
                                          tooltip: classes.tooltipLabel
                                      }}
                                  >
                                    <p>
                                      {spCriterionHolder?.criterionEntityList[0]?.criterion}
                                    </p>
                                  </Tooltip>
                                </label>
                            </span>

                            <span className={`field-vertical--item`}>
                                <label className={`field-label font-14 font-mediumBold`}>
                                    {`-`}
                                </label>
                            </span>

                            <span className={`field-vertical--item`}>
                                <label className={`field-label font-14 pr-10`}>
                                  <Tooltip title={concatenatedRules} placement='bottom' className={classes.tooltipContent}
                                      classes={{tooltip: classes.tooltipLabel}}
                                  >
                                    <Chip
                                        label={
                                            <>
                                                Product Specific Rule
                                                <FontAwesomeIcon icon={faCircleInfo}/>
                                            </>
                                        }
                                        classes={{
                                            label: classes.chipLabel
                                        }}
                                        size='small'
                                        className={classes.chipInfo}
                                    />
                                  </Tooltip>
                                </label>
                            </span>
                        </div>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={9}>
                        <Grid container>
                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.calculation}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>
                                            {spCalculation}
                                        </label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>
                                            {FtaTranslatedTextsObject.thresholdValuePerUOM}
                                        </label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>
                                            {spCriterionHolder?.ftaOriginCalculationEntity?.thresholdValuePerUom !== null ? spCriterionHolder?.ftaOriginCalculationEntity?.thresholdValuePerUom : `-`}
                                        </label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.profit}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>{spCriterionHolder?.ftaOriginCalculationEntity?.profit !== null ? spCriterionHolder?.ftaOriginCalculationEntity?.profit : `-`}</label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.nonOriginatingValue}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>{spCriterionHolder?.ftaOriginCalculationEntity?.nonOriginatingValue !== null ? spCriterionHolder?.ftaOriginCalculationEntity?.nonOriginatingValue : `-`}</label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.directLabourCost}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>{spCriterionHolder?.ftaOriginCalculationEntity?.directLabourCost !== null ? spCriterionHolder?.ftaOriginCalculationEntity?.directLabourCost : `-`}</label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.otherCost}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>{spCriterionHolder?.ftaOriginCalculationEntity?.otherCost !== null ? spCriterionHolder?.ftaOriginCalculationEntity?.otherCost : `-`}</label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.baseUnit}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>{spCriterionHolder?.ftaOriginCalculationEntity?.baseUnit !== null ? spCriterionHolder?.ftaOriginCalculationEntity?.baseUnit : `-`}</label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.directOverheardCost}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>{spCriterionHolder?.ftaOriginCalculationEntity?.directOverheadCost !== null ? spCriterionHolder?.ftaOriginCalculationEntity?.directOverheadCost : `-`}</label>
                                    </span>
                                </div>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={4}>
                                <div className={`field-vertical--label-value`}>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-label`}>{FtaTranslatedTextsObject.qvc} &#47;{FtaTranslatedTextsObject.rvc}</label>
                                    </span>
                                    <span className={`field-vertical--item`}>
                                        <label className={`field-value`}>{qvcRvc}</label>
                                    </span>
                                </div>
                            </CngGridItem>
                        </Grid>
                    </CngGridItem>
                </Grid>
            </div>
            {/* end card body */}

            {/* card footer */}
            <RulesOfOriginListCardDetailBottom product={dates} key={datesCardKey}/>

        </Card>
    )
}

const RulesOfOriginSPDetails = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default RulesOfOriginSPDetails;