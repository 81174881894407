import React, { useState } from 'react'
import {
  Card,
  Paper,
  CardContent,
  Grid,
  Checkbox,
  Box,
  Typography,
  useTheme,
  makeStyles,
  Tooltip
} from '@material-ui/core'
import Utils from 'src/shared/Utils'
import { useFormContext } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { components, useServices, constants, Yup } from 'cng-web-lib'
import FtaActivityHistoryFormProperties from './FtaActivityHistoryFormProperties'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'

const {
  form: { CngViewForm, CngForm }
} = components

const { FormState, NotificationType } = constants

const useStyles = makeStyles((theme) => ({}))

function FtaActivityHistoryPage(props) {
  const classes = useStyles()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const {
    showNotification,
    location: { pathname }
  } = props

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  const validationSchema = Yup.object().shape(
    {
      materialNoSkuProductDesc: Yup.string().nullable(),
      hsCodeAndDescription: Yup.string().nullable(),
      productDescription: Yup.string().nullable(),
      companyName: Yup.string().nullable(),
      companyRefNo: Yup.string().nullable(),
      origin: Yup.string()
        .nullable()
        // .required(FtaTranslatedTextsObject.originRequiredMsg)
        .when('destination', (destination, schema) => {
          if (destination) {
            return schema.notOneOf(
              [Yup.ref('destination')],
              FtaTranslatedTextsObject.destinationCannotBeSameMsg
            )
          }
        }),
      destination: Yup.string()
        .nullable()
        // .required(FtaTranslatedTextsObject.destinationRequiredMsg)
        .when('origin', (origin, schema) => {
          if (origin) {
            return schema.notOneOf(
              [Yup.ref('origin')],
              FtaTranslatedTextsObject.originCannotBeSameMsg
            )
          }
        })
    },
    [['origin', 'destination']]
  )

  function onSubmit(data) {
    history.push('/')
  }

  return (
    <>
      <CngViewForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        history={history}
        showNotification={showNotification}
        bodySection={
          <>
            <FtaActivityHistoryFormProperties.FormFields
              disabled={false}
              showNotification={showNotification}
              pathname={pathname}
            />
          </>
        }
        formikProps={{
          ...FtaActivityHistoryFormProperties.formikProps,
          validationSchema: Yup.lazy((values) => {
            return validationSchema
          }),
          onSubmit: onSubmit
        }}
      />
    </>
  )
}

export default FtaActivityHistoryPage
