import React, { useEffect, useState } from 'react'
import {
  makeStyles
} from '@material-ui/core'
import { components, constants, useServices } from 'cng-web-lib'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import CountryAutocompleteField from 'src/components/autocomplete/CountryAutocompleteField'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import FtaListNoResult from '../shared/FtaListNoResult'
import { useFormContext } from 'react-hook-form'
import CngBackdrop from '../components/CngBackdrop'
import RulesOfOriginListCardComponent from './RulesOfOriginListCardComponent'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: theme.palette.text.widgetTitle
  }
}))

const DEFAULT_INITIAL_VALUES = Object.freeze({})

const {
  form: {
    field: { CngTextField, CngLookupAutocompleteField },
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}


function FormFields({ history, detailsData, showNotification, disabled, ...props }) {
  const { setValue, getValues, handleSubmit, clearErrors } = useFormContext()
  const { securedSendRequest, fetchRecords, fetchPageableRecords } =
    useServices()
  const [loading, setLoading] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [errorList, setErrorList] = useState([])
  const [shownCount, setShownCount] = useState(0)
  const classes = useStyles()
  const { ftaActivityHistoryId, ftaActivityResultId } = props.rooPayload
  const [displayedRulesOfOriginList, setDisplayedRulesOfOriginList] = useState([])

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  const fetchRulesOfOrigin = () => {
    setLoading(true)

    securedSendRequest.execute(
      'post',
      TradeMgmtApiUrls.GET_RULES_OF_ORIGIN,
      {
        ftaActivityHistoryId: ftaActivityHistoryId,
        hsCode: detailsData.hsCode,
        ftaCode: props.ftaName
      },
      (response) => {
        setDisplayedRulesOfOriginList(response?.data?.results || [])
        setShownCount(response?.data?.results.length || 0)

        setLoading(false)
        setPageError(false)
      },
      (error) => {
        onError(error)
        console.error(error)
      },
      // On compelte
      () => {
        // Hide data loader overlay
        setLoading(false)
      },
      {}
    )
  }

  useEffect(() => {
    fetchRulesOfOrigin()
  }, [])

  // Set state whenever the error is encountered
  const onError = (error) => {
    //console.log('connection-request error :: \n', error.message)
    setPageError(true)
    setLoading(false)
  }

  // Make sure the results set is empty or not
  const showNoResultPage = () => {
    return displayedRulesOfOriginList.length === 0 && !loading
  }

  return (
    <>
      {/* Section: Rules of origin */}
      <section className='section-grid row-gap-0 child-of-grid one-col'>
        <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
          <CngBackdrop loading={loading} />
        </CngGridItem>

        {showNoResultPage() && (
          <FtaListNoResult
            displayedItems={displayedRulesOfOriginList}
            shownCount={shownCount}
            pageError={pageError}
          />
        )}

        {!showNoResultPage() && (
          <>
            <header className='section-grid__col one-col'>
              <div className={`cng-summary-header  mb-10`}>
                <div className={`cng-summary-header-col`}>
                  <span className={`summary-count-label-wrapper`}>
                    <label className={`${classes.sectionSecondaryLabel}`}>
                      {shownCount}
                      {` ${CommonFtaTranslatedTextsObject.item}`}
                      {shownCount > 1 ? `(s)` : ``}
                    </label>
                  </span>
                </div>

                <div
                  className={`cng-summary-header-col auto cta-btn-wrapper flex-end`}
                >
                  {/* Search bar goes here */}
                </div>
              </div>
            </header>

            <article className='section-grid__col one-col'>
              <RulesOfOriginListCardComponent.FormBody
                displayedRulesOfOriginList={displayedRulesOfOriginList}
                statusCode={props.statusCode}
                productStatusCode={props?.productStatusCode}
              />
            </article>
          </>
        )}
      </section>
    </>
  )
}

function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const RulesOfOriginForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default RulesOfOriginForm
