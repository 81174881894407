import { Download, Edit, Trash } from 'react-feather'
import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'

export default function DocumentActionMenu(props) {
  const {
    id,
    removeRowClick,
    editRowClick,
    downloadRowClick,
    downloadLogClick
  } = props
  const translatedTextsObject = CalistaUiComponentTranslationText()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    console.log('handle close')
    setAnchorEl(null)
  }

  const editAction = (idx) => () => {
    console.log('edit1 ' + idx)
    editRowClick(idx)
    setAnchorEl(null)
    //
  }

  const removeAction = (idx) => () => {
    console.log('remove ' + idx)
    removeRowClick(idx)
    setAnchorEl(null)
    //
  }

  const downloadAction = (idx) => () => {
    console.log('download ' + idx)
    downloadRowClick(idx)
    setAnchorEl(null)
    //
  }

  const downloadLogFileAction = (idx) => () => {
    console.log('download Log' + idx)
    downloadLogClick(idx)
    setAnchorEl(null)
    //
  }

  return (
    <Box>
      <IconButton
        edge='end'
        className={`row-more-actions`}
        onClick={handleClick}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 'auto',
            width: 'auto'
          }
        }}
      >
        {!props.hideDelete ? (
          <MenuItem onClick={removeAction(id)}>
            <Trash size='16px' />
            <Box ml={1}>
              <Typography variant='inherit' style={{ fontSize: 'small' }}>
                {' '}
                {translatedTextsObject.deleteText}
              </Typography>
            </Box>
          </MenuItem>
        ) : null}
        {!props.hideEdit ? (
          <MenuItem onClick={editAction(id)}>
            <Edit size='16px' />
            <Box ml={1}>
              <Typography variant='inherit' style={{ fontSize: 'small' }}>
                {' '}
                {translatedTextsObject.edit}
              </Typography>
            </Box>
          </MenuItem>
        ) : null}
        <MenuItem onClick={downloadAction(id)}>
          <Download size='16px' />
          <Box ml={1}>
            <Typography variant='inherit' style={{ fontSize: 'small' }}>
              {' '}
              Download
            </Typography>
          </Box>
        </MenuItem>
        {!props.hideDownloadLog ? (
          <MenuItem onClick={downloadLogFileAction(id)}>
            <Download size='16px' />
            <Box ml={1}>
              <Typography variant='inherit' style={{ fontSize: 'small' }}>
                {' '}
                Download Error Log
              </Typography>
            </Box>
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  )
}
