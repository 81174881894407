import { useSnackbar } from 'notistack'
import { components, constants, useServices } from 'cng-web-lib'
const {
  CodeMaintenanceType,
  apiUrl: { CodeMaintenanceApiUrls },
  filter: { EQUAL }
} = constants


export * from './columns'
export * from './validation'
export * from './bom'

export const fetchCode = (fn, code, success, error, complete) => {
  return fn.execute(
    CodeMaintenanceApiUrls.GET,
    {
      customData: { codeMType: CodeMaintenanceType[code] },
      filters: [{
        field: 'active',
        operator: EQUAL,
        value: true
      }],
    },
    (res) => {
      if (res.content && res.content.length > 0)
        success(res.content)
    },
    error, complete
  )
}

export const parseFormData = (obj, defaultData) => {
  let n = defaultData || {}
  Object.entries(obj).forEach(([k, v]) => {
    if (v === null)
      v = ''
    else if (v.hasOwnProperty('id'))
      v = parseFormData(v)
    else if (Array.isArray(v))
      v = v.map(a => parseFormData(a))
    n[k] = v
  })

  return n
}

export const parseSubmission = (formData, keyList) => {
  let ret = {}
  Object.entries(formData).forEach(([k, v]) => {
    if (keyList.includes(k))
      ret[k] = v
  })
  return ret
}

export const tmpFilterCode = code => ({
  field: 'codeType',
  operator: EQUAL,
  value: `TMP_COMPONENT_${code.toUpperCase()}`
})
export const tmpFilterStatus = {
  field: 'status',
  operator: EQUAL,
  value: true
}

export const DUPE_KEY = 'DUPLICATE'
export const STATE_KEY = 'PROD_STATE'
export const COMPANY_REF_INIT = {
  registerNo: '',
  referenceNo: '',
  address: '',
}
export const PROD_INIT = {
  bomDetail: {
    bomName: "",
    componentDetail: [],
    directLaborCost: "",
    directOverheadCost: "",
    errorMessages: "",
    id: "",
    myProductId: "",
    otherCost: "",
    profit: "",
    quantity: "",
    updatedBy: "",
    updatedDate: "",
    version: "",
  },
  baseQuantity: '',
  companyId: '',
  REF_company: { ...COMPANY_REF_INIT },
  countryOfManufacture: '',
  createdBy: '',
  createdDate: '',
  currency: '',
  exworkPrice: '',
  fobPrice: '',
  transactionValue: '',
  netCosts: '',
  totalCosts: '',
  hsCodeAndDescription: '',
  hsHeader: '',
  hsMasterId: '',
  id: '',
  materialSkuNo: '',
  materialSkuProductDescription: '',
  model: '',
  productDescription: '',
  uomCode: '',
  uomId: '',
  uomName: '',
  updatedBy: '',
  updatedDate: '',
  version: '',
  REF_components: [],
  pendingSupportDocuments: [],
  countryOfManufactureCode: ''
}
