import React, { useContext, useState, useEffect, useMemo } from 'react'
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { components, constants, useServices } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'

import { ProductContext } from '../ProductPage'

const {
  button: { CngButton },
  form: {
    field: { CngTextField },
  },
} = components

const {
  CodeMaintenanceType,
  apiUrl: { CodeMaintenanceApiUrls },
  filter: { EQUAL }
} = constants

const useStyles = makeStyles(theme => ({
  lightText: { color: theme.palette.text.textSecondary },
  readOnly: {

  },
  input: {

  }
}))

function Field({
  label, render, component, readOnly,
  codeLookup, onLookup, disableActive,
  ...props
}) {
  const { getValues } = useFormContext()
  const { editing, loading } = useContext(ProductContext)
  const classes = useStyles()
  const { fetchRecords } = useServices()

  const [lookupData, setLookupData] = useState({})
  const [lookingUp, setLookingUp] = useState(false)
  const displayLabel = useMemo(() => {
    if (codeLookup)
      return 'descriptionEn'
    return props.lookupProps?.label || 'label'
  }, [codeLookup, props.lookupProps])
  const displayValue = useMemo(() => {
    let ret = props.lookupProps
      ? lookupData[displayLabel]
      : getValues(props.name)
    return ret || <Box component={'i'}
      color="divider"
      fontSize=".9rem"
      display="flex"
      alignItems="center"
    >
      No data
    </Box>
  }, [lookupData, props.lookupProps, props.name, displayLabel, getValues(props.name)])

  useEffect(() => {
    if (getValues(props.name) !== '') {
      let lookupProps = {
        filters: [],
        ...props.lookupProps
      }
      if (codeLookup) {
        lookupProps = {
          url: CodeMaintenanceApiUrls.GET,
          label: 'descriptionEn',
          value: 'code',
          dataToBeSent: {
            customData: { codeMType: CodeMaintenanceType[codeLookup.toUpperCase()] },
            ...lookupProps,
            filters: [
              ...lookupProps.filters,
              ...!disableActive && ({
                field: 'active',
                operator: EQUAL,
                value: true
              }), {
                field: lookupProps.value || 'id',
                operator: EQUAL,
                value: getValues(props.name)
              }
            ],
          }
        }
      } else {
        lookupProps.dataToBeSent = {
          data: {
            [lookupProps.value || 'id']: getValues(props.name)
          },
          filters: lookupProps.filters,
          customData: lookupProps.customData
        }
      }

      if (lookupProps.url) {
        setLookingUp(true)
        fetchRecords.execute(
          lookupProps.url,
          lookupProps.dataToBeSent,
          (res) => {
            if (res.content && res.content.length === 1) {
              setLookupData(res.content[0])
              onLookup(res.content[0])
            }
          }, () => null, () => setLookingUp(false)
        )
      }

    }
  }, [getValues(props.name)])

  if (!editing)
    return <Box className={classes.readOnly}>
      <Typography variant="caption" className={classes.lightText}>{label}</Typography>
      {loading || lookingUp ? <Box><Skeleton /></Box>
        : <Box minHeight="1.5rem">{render ?
          render(getValues(props.name), lookupData)
          : <Typography>
            {displayValue}
          </Typography>
        }</Box>
      }
    </Box>
  return <Box className={classes.input}>
    <Box component={component} label={label} {...props} />
  </Box>
}

Field.defaultProps = {
  component: CngTextField
}

export { Field }
