import {
  Box,
  Paper,
  Grid,
  Chip,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CngBackdrop from '../components/CngBackdrop'
import { useFormContext } from 'react-hook-form'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faLacrosseStick } from '@fortawesome/pro-light-svg-icons'
import Utils from 'src/shared/Utils'
import GoBackButton from 'src/components/button/GoBackButton'

const { CngGridItem } = components

const useStyles = makeStyles((theme) => ({
  sectionPrimaryLabel: {
    color: theme.palette.text.widgetTitle
  },
  sectionSecondaryLabel: {
    color: theme.palette.text.textSecondary
  },
  sectionSummaryCardHeader: {
    backgroundColor: theme.palette.background.white,
    paddingInline: '20px',
    paddingBlock: '20px'
  }
}))

const FORM_INITIAL_VALUES = Object.freeze({})

const FormBody = ({ disabled, ...props }) => {
  const classes = useStyles()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return (
    <Grid container spacing={1}>
      <CngGridItem item xs={12} sm={12}>
        <div
          className={`cng-summary-header ${classes.sectionSummaryCardHeader}`}
        >
          <div className={`cng-summary-header-col`}>
            <span className={`summary-count-label-wrapper`}>
              <Typography variant='h5' className={classes.sectionPrimaryLabel}>
                {FtaTranslatedTextsObject.calculationResults}
              </Typography>
              <label className={`ml-5 ${classes.sectionSecondaryLabel}`}>
                &#40;{props.shownCount}&#41;
              </label>
            </span>
          </div>

          <div
            className={`cng-summary-header-col auto cta-btn-wrapper flex-end`}
          >
            {/* Search bar goes here */}
          </div>
        </div>
      </CngGridItem>
    </Grid>
  )
}

const ProductDetailsParamCalculationResultsList = Object.freeze({
  FormBody: FormBody,
  initialValues: FORM_INITIAL_VALUES
})

export default ProductDetailsParamCalculationResultsList
