import React, { useContext, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { components } from 'cng-web-lib'

import { Field, TableBody } from '.'

const {
  form: {
    field: { CngTextField },
  },
} = components


function FormCard({ title, subtitle, searchText, tableData, toolbar, ...props }) {

  const [pageInfo, setPageInfo] = useState({
    totalRecords: 0,
    itemPerPage: 5,
  })

  return <Card>
    <Box bgcolor="#F8F9FA" p={2}>
      <Box fontSize="1.2rem" display="inline" fontWeight="fontWeightBold">
        {title}
      </Box>
      {subtitle &&
        <Box component={Typography} variant="caption" color="text.textSecondary">
          &ensp;/&ensp;{subtitle}
        </Box>
      }
    </Box>
    <CardContent>
      {props.children}
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField placeholder={searchText || "Search"}
            InputProps={{
              endAdornment: <Box component={InputAdornment} position="end" px={1}>
                <FontAwesomeIcon icon={['far', 'search']} />
              </Box>
            }}
          />
        </Grid>
        {toolbar}
      </Grid>
    </CardContent>
    {tableData.loading ? <Skeleton variant="rect" height={120} />
      : <TableBody data={tableData.data || []}
        columns={tableData.cols}
        isLoading={tableData.loading}
        TableProps={tableData.TableProps}
        noMT
      />}
  </Card>
}

export { FormCard }
