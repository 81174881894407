import React, { useState, useEffect, memo, useContext } from 'react'
import { TextField } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { DialogContext } from './SearchDialog'

const {
  form: { field: { CngTextField } }
} = components

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

function EditCell({rowData, holder, ref, ...props}) {
  const { qty, qtyInput } = useContext(DialogContext)
  const { setValue, getValues } = useFormContext()
  const [input, setInput] = useState(
    qty.current[rowData.tableData.id]
    || getValues(`REF_components[${rowData.tableData.id}].quantity`)
    || ''
  )

  const debouncedValue = useDebounce(input, 750)

  useEffect(() => {
    qty.current[rowData.tableData.id] = debouncedValue
  }, [debouncedValue])

  return <TextField
    label="Units" required
    size="small" type="number"
    value={input}
    ref={el => qtyInput.current[rowData.tableData.id] = el}
    onChange={e => setInput(e.target.value)}
  />
}

export { EditCell }
