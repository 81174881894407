import * as Yup from 'yup'

export const PROD_SCHEMA = Yup.object({
  hsMasterId: Yup.number().required(),
  materialSkuNo: Yup.string().required(),
  productDescription: Yup.string().required(),
  model: Yup.string(),
  baseQuantity: Yup.number().required(),
  uomId: Yup.number().required(),
  countryOfManufactureCode: Yup.string().required(),
  exworkPrice: Yup.number(),
  fobPrice: Yup.number(),
  transactionValue: Yup.number(),
  netCosts: Yup.number(),
  totalCosts: Yup.number(),
  currency: Yup.string().length(3).required(),
  companyId: Yup.number().required(),
  bomDetail: Yup.object({
    bomName: Yup.string().required(),
    profit: Yup.number(),
    otherCost: Yup.number(),
    directLaborCost: Yup.number(),
    directOverheadCost: Yup.number(),
    components: Yup.array().of(Yup.object({
      componentId: Yup.number().required(),
      quantity: Yup.number().required()
    }))
  }),
  REF_components: Yup.array().of(Yup.object({
    componentId: Yup.number(),
    attached: Yup.boolean(),
    quantity: Yup.number('Please enter a number.')
  }))
})
