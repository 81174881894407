import React, { useState } from 'react'

import { Typography, CardContent, IconButton, Menu } from '@material-ui/core'
import { Stack, MenuItem } from '@mui/material'
import SortIcon from '@material-ui/icons/ImportExport'
import CheckIcon from '@material-ui/icons/Check'
import SearchHistorys from './SearchHistorys'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'

export default function SearchHistorysCard(props) {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()

  function makeTranslatedSourceItem() {
    let paginationNameAsc = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.PAGINATION_NAME_ASC
    )
    let paginationNameDesc = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.PAGINATION_NAME_DESC
    )
    let paginationSearchNew = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.PAGINATION_SEARCH_NEW
    )
    let paginationSearchOld = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.PAGINATION_SEARCH_OLD
    )
    let fieldResult = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.FIELD_RESULTS
    )

    return {
      paginationNameAsc,
      paginationNameDesc,
      paginationSearchNew,
      paginationSearchOld,
      fieldResult
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const expandSortMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const closeSortMenu = () => {
    setAnchorEl(null)
  }

  const [sort, setSort] = useState(1)
  const setSortMenu = (item) => {
    setAnchorEl(null)
    setSort(item)

    switch (item) {
      case 1:
        props.sorting('createdDate,desc')
        return
      case 2:
        props.sorting('createdDate,asc')
        return
      case 3:
        props.sorting('name,asc')
        return
      case 4:
        props.sorting('name,desc')
        return
      default:
        return
    }
  }

  return (
    <CardContent>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography sx={{ fontSize: 14 }} color='textSecondary' gutterBottom>
          {props.response.length}
          {translatedItem.fieldResult}
        </Typography>

        <IconButton
          style={{ borderRadius: '11px' }}
          id='sortMenu'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={expandSortMenu}
        >
          <SortIcon color='disabled' />
        </IconButton>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={closeSortMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => setSortMenu(1)}
          >
            <div style={{ width: '24px', textAlign: 'center' }}>
              {sort === 1 && <CheckIcon fontSize='small' />}
            </div>
            <Typography variant='h5' style={{ marginLeft: '3px' }}>
              {translatedItem.paginationSearchNew}
            </Typography>
          </MenuItem>
          <MenuItem
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => setSortMenu(2)}
          >
            <div style={{ width: '24px', textAlign: 'center' }}>
              {sort === 2 && <CheckIcon fontSize='small' />}
            </div>
            <Typography variant='h5' style={{ marginLeft: '3px' }}>
              {translatedItem.paginationSearchOld}
            </Typography>
          </MenuItem>
          <MenuItem
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => setSortMenu(3)}
          >
            <div style={{ width: '24px', textAlign: 'center' }}>
              {sort === 3 && <CheckIcon fontSize='small' />}
            </div>
            <Typography variant='h5' style={{ marginLeft: '3px' }}>
              {translatedItem.paginationNameAsc}
            </Typography>
          </MenuItem>
          <MenuItem
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => setSortMenu(4)}
          >
            <div style={{ width: '24px', textAlign: 'center' }}>
              {sort === 4 && <CheckIcon fontSize='small' />}
            </div>
            <Typography variant='h5' style={{ marginLeft: '3px' }}>
              {translatedItem.paginationNameDesc}
            </Typography>
          </MenuItem>
        </Menu>
      </Stack>

      {props.response.map((record) => (
        <SearchHistorys
          isLoading={props.isLoading}
          getHistory={props.getHistory}
          searchAgain={props.searchAgain}
          downloadPDF={props.downloadPDF}
          party={record}
          key={uuidv4()}
        />
      ))}
    </CardContent>
  )
}
