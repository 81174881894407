import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  FormLabel,
  makeStyles
} from '@material-ui/core'

import CalcResultsListCardDetailTop from './CalcResultsListCardDetailTop'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { generatePath, useHistory } from 'react-router-dom'
import Utils from 'src/shared/Utils'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const useStyles = makeStyles((theme) => ({
  rowDivider: {
    marginInline: '15px',
    width: `calc(100% - 30px)`,

    '&:nth-child(1)': {
      borderBottom: `1px solid ${Utils.hexToRgba(
        theme.palette.text.primary,
        0.06
      )}`
    }
  },
  tooltipContent: {
    fontSize: '14px',
    fontWeight: 400
  },
  tooltipLabel: {
    fontSize: '14px',
    fontWeight: 400
  }
}))

const { CngGridItem } = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
  const history = useHistory()

  const {
    product,
    ftaName,
    currencyCode,
    sequenceNo,
    paramsData,
    detailsData,
    calculationResults,
    ftaActivityHistoryId,
    isRoutefromCalcScreen,
    productStatusCode
  } = props

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  const classes = useStyles()

  // Prepare route to view details page
  const handleViewFtaProductDetails = (product, sNo) => {
    history.push(`/cal-tmp/ftacalculator/productdetails-viewfta`, {
      individualProduct: product,
      ftaName: ftaName,
      paramsData: paramsData,
      detailsData: detailsData,
      calculationResults: calculationResults,
      sequenceNo: sNo,
      currencyCode: currencyCode,
      ftaActivityHistoryId: ftaActivityHistoryId,
      isRoutefromCalcScreen: isRoutefromCalcScreen,
      productStatusCode: productStatusCode
    })
  }

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  // @purpose: This is used to render the pill shape at the card header
  const statusColorCodes = [
    {
      statusCode: 'QUAL',
      statusName: FtaTranslatedTextsObject.statusQualified,
      color: '#7CE7AC',
      actions: []
    },
    {
      statusCode: 'DQUA',
      statusName: FtaTranslatedTextsObject.statusDNQualified,
      color: '#1C1D214D',
      actions: []
    },
    {
      statusCode: 'SELF',
      statusName: FtaTranslatedTextsObject.statusSelfAssessmentRequired,
      color: '#FF808B',
      actions: []
    },
    {
      statusCode: 'PRIC',
      statusName: FtaTranslatedTextsObject.statusPriceDependent,
      color: '#5E81F44D',
      actions: []
    },
    {
      statusCode: 'INSD',
      statusName: FtaTranslatedTextsObject.statusInsufficientData,
      color: '#E8E800',
      actions: []
    }
  ]

  // Find status color codes
  const statusNameStr = (statusCode) => {
    return statusColorCodes.find((b) => b.statusCode === statusCode)?.statusName
  }

  const statusColorCode = (statusCode) => {
    return statusColorCodes.find((b) => b.statusCode === statusCode)?.color
  }

  return (
    <Card className={`summary-list-item--card`}>
      <CalcResultsListCardDetailTop product={product} ftaName={ftaName} />

      <CardActionArea
        className={`cng-summary-card--body-box`}
        onClick={() => handleViewFtaProductDetails(product, sequenceNo)}
      >
        {product.slice(0, 2).map((rulesObj, idx) => (
          <Grid
            container
            className={`cng-summary-card--body-grid ${classes.rowDivider}`}
            key={idx}
          >
            <CngGridItem xs={12} sm={3}>
              <div
                className={`field-vertical--label-value`}
                style={{ marginInlineStart: '12px' }}
              >
                <span className={`field-vertical--item`}>
                  <label className={`field-value font-16`}
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', wordBreak: 'break-word' }}
                  >
                    <Tooltip
                      title={rulesObj.calculation !== null ? rulesObj.calculation : `-`}
                      placement='bottom'
                      className={classes.tooltipContent}
                      classes={{
                        tooltip: classes.tooltipLabel
                      }}
                    >
                      <p>
                        {rulesObj.calculation !== null ? rulesObj.calculation.substring(0, 50) + '...' : `-`}
                      </p>
                    </Tooltip>


                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label
                    className={`field-value font-16 font-mediumBold`}
                    style={{
                      color:
                        rulesObj.statusCode !== null
                          ? statusColorCode(rulesObj.statusCode)
                          : `inherit`
                    }}
                  >
                    {rulesObj.statusCode !== null
                      ? statusNameStr(rulesObj.statusCode)
                      : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.qualificationExpiry}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label
                    className={`field-value clamp-line-to-two font-mediumBold`}
                  >
                    {rulesObj.qualificationExpiry !== null
                      ? formatDate(rulesObj.qualificationExpiry)
                      : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.qualificationDate}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {rulesObj.qualificationDate !== null
                      ? formatDate(rulesObj.qualificationDate)
                      : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.assessedBy}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {rulesObj.assessedBy !== null ? rulesObj.assessedBy : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>
          </Grid>
        ))}
      </CardActionArea>

    </Card>
  )
}

const CalcResultsListCardDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default CalcResultsListCardDetailComponent
