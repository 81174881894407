import { Grid, Typography } from '@material-ui/core'

import React from 'react'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const RulesOfOriginListCardDetailBottom = (props) => {
  const { product } = props

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit'
    }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <footer className={`cng-summary-card--footer-box`}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--footer-box--left`}>
            <Typography className={'frb-card-label-large-grey'}>
              {FtaTranslatedTextsObject.qualificationDate}
              <b className='ml-5 field-value dark font-mediumBold'>
                {product.qualificationDate !== null
                  ? formatDate(product.qualificationDate)
                  : `-`}
              </b>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--footer-box--right`}>
            <div className={`footer-box--right-col-1 text-right-align`}>
              <Typography className={'frb-card-label-large-grey'}>
                {FtaTranslatedTextsObject.qualificationExpiry}
                <b className='ml-5 field-value dark font-mediumBold'>
                  {product.qualificationExpiry !== null
                    ? formatDate(product.qualificationExpiry)
                    : `-`}
                </b>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </footer>
  )
}

export default RulesOfOriginListCardDetailBottom
