import React, { useEffect, useState } from 'react'
import {
  Box,
  Paper,
  IconButton,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'
import { components, constants, useServices } from 'cng-web-lib'
import ResetButton from 'src/components/button/ResetButton'
import ArrowUpRightButton from 'src/components/button/ArrowUpRightButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from '@material-ui/core'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import CountryAutocompleteField from 'src/components/autocomplete/CountryAutocompleteField'
import ClearFieldsButton from 'src/components/button/ClearFieldsButton'
import SearchButton from 'src/components/button/SearchButton'
import Table from '../components/HistoryTable'
import Utils from 'src/shared/Utils'
import { useFormContext } from 'react-hook-form'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'

const DEFAULT_INITIAL_VALUES = Object.freeze({
  origin: '',
  destination: '',
  materialNoSkuProductDesc: '',
  hsCode: '',
  hsDescription: '',
  productDescription: '',
  companyName: '',
  companyRefNo: ''
})

const {
  form: {
    field: { CngTextField, CngLookupAutocompleteField },
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit'
  }
}))

function FormFields({ history, showNotification, disabled, ...props }) {
  const [origin, , { setValue: setOrigin }] = useField('origin')
  const [destination, , { setValue: setDestination }] = useField('destination')
  const [
    materialNoSkuProductDesc,
    ,
    { setValue: setMaterialNoSkuProductDesc }
  ] = useField('materialNoSkuProductDesc')
  const [hsCode, , { setValue: setHsCode }] = useField('hsCode')
  const [hsDescription, , { setValue: setHsDescription }] =
    useField('hsDescription')
  const [productDescription, , { setValue: setProductDescription }] =
    useField('productDescription')
  const [companyName, , { setValue: setCompanyName }] = useField('companyName')
  const [companyRefNo, , { setValue: setCompanyRefNo }] =
    useField('companyRefNo')

  const [anchorEl, setAnchorEl] = React.useState(null)
  const { setValue, getValues, handleSubmit, clearErrors } = useFormContext()
  const classes = useStyles()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  const resetForm = () => {
    setOrigin('')
    setDestination('')
    setMaterialNoSkuProductDesc('')
    setHsCode('')
    setHsDescription('')
    setProductDescription('')
    setCompanyName('')
    setCompanyRefNo('')
  }

  // @purpose: A function to validiate the form data
  const searchFTA = () => {
    handleSubmit(onValid, onInvalid)()
  }

  // @purpose: Upon form is valid
  const onValid = (data) => {
    // console.log('Inside form ::\n', data)
    props.onSearchClick(data)
  }

  // @purpose: Upon form is invalid
  const onInvalid = (data) => {
    console.error('Missing mandatory field value(s):\n', data)
  }

  const handlePopOverClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const popOverOpen = Boolean(anchorEl)
  const popOverId = popOverOpen ? 'simple-popover' : undefined

  return (
    <>
      <div className={`page-grid one-col p-0`}>
        <header className='page-grid__col two-col'>
          <div className='page-grid__col two-col'>
            <span>
              <CountryAutocompleteField
                label={FtaTranslatedTextsObject.origin}
                name='origin'
                accessor='countryList'
                url={TradeMgmtApiUrls.GET_TMP_COUNTRY}
                lookupProps={{
                  label: 'name',
                  value: 'code',
                  dataAccessor: 'content',
                  url: TradeMgmtApiUrls.GET_TMP_COUNTRY
                }}
              />
            </span>

            <span>
              <CountryAutocompleteField
                label={FtaTranslatedTextsObject.destination}
                name='destination'
                accessor='countryList'
                url={TradeMgmtApiUrls.GET_TMP_COUNTRY}
                lookupProps={{
                  label: 'name',
                  value: 'code',
                  dataAccessor: 'content',
                  url: TradeMgmtApiUrls.GET_TMP_COUNTRY
                }}
              />
            </span>
          </div>
        </header>

        {/* Search form */}
        <section className='section-grid child-of-grid one-col m-0'>
          <article className='section-grid__col has-more-childs one-col'>
            <div className='three-col align-items-baseline'>
              <span>
                <CngTextField
                  disabled={disabled}
                  name={`materialNoSkuProductDesc`}
                  label={FtaTranslatedTextsObject.materialNoSku}
                />
              </span>

              <span>
                <CngTextField
                  disabled={disabled}
                  name={`hsCode`}
                  label={FtaTranslatedTextsObject.hsCode}
                />
              </span>

              <span>
                <CngTextField
                  disabled={disabled}
                  name={`hsDescription`}
                  label={FtaTranslatedTextsObject.hsDescription}
                />
              </span>
            </div>

            <div className='three-col align-items-baseline'>
              <span>
                <CngTextField
                  disabled={disabled}
                  name={`productDescription`}
                  label={FtaTranslatedTextsObject.productDescription}
                />
              </span>
              <span>
                <CngTextField
                  disabled={disabled}
                  name={`companyName`}
                  label={FtaTranslatedTextsObject.companyName}
                />
              </span>

              <span>
                <CngTextField
                  disabled={disabled}
                  name={`companyRefNo`}
                  label={FtaTranslatedTextsObject.companyRefNo}
                />
              </span>
            </div>

            <div className='one-col align-items-center'>
              <span className='cta-btn-wrapper flex-end'>
                <ClearFieldsButton onClick={() => resetForm()} />
                <SearchButton
                  onClick={() => searchFTA()}
                  label={CommonFtaTranslatedTextsObject.btnSearch}
                />
              </span>
            </div>
          </article>
        </section>

        {/* Results table */}
        <section className='section-grid child-of-grid one-col d-none'>
          <article className='section-grid__col has-more-childs one-col'>
            <Table />
          </article>
        </section>
      </div>
    </>
  )
}

function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const FTAVersionLogsForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FTAVersionLogsForm
