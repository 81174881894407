import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  makeStyles,
  TextField
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { components, useServices } from 'cng-web-lib'

import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import pathMap from 'src/paths/PathMap_TradeManagement'

const {
  button: { CngButton },
  form: {
    field: { CngTextField },
  },
} = components

const useStyles = makeStyles((theme) => ({
  count: {
    color: theme.palette.text.textSecondary
  },
  searchBar: {
    display: 'flex',
    border: `1px solid ${theme.palette.color.lightGrey}`,
    borderRadius: theme.shape.borderRadius * 2,
    maxWidth: '36em',
    marginRight: 'auto',
    '& > *:nth-child(1)': {
      textTransform: 'none',
      color: theme.palette.text.cngBlack,
      fontWeight: 900,
      flexWrap: 'nowrap',
      flexBasis: 0,
      minWidth: 'auto',
      '& > .MuiButton-label': {
        whiteSpace: 'nowrap'
      }
    },
    '& > *:nth-child(2)': {
      '& .MuiInputBase-root': {
        flexGrow: 1,
        height: '100%',
        backgroundColor: 'transparent !important',
        '&, &:hover': {
          borderColor: 'transparent !important',
        },
        '& > input.MuiInputBase-input': {
          padding: theme.spacing(1, 2) + ' !important',
        }
      }
    }
  }
}))


function TableToolbar({ total, ...props }) {
  const classes = useStyles()
  const { fetchRecords } = useServices()
  const { setValue, register } = useFormContext()
  const history = useHistory()

  const [searchTypeOptions, setSearchTypeOptions] = useState([])
  const [searchType, setSearchType] = useState({ text: 'All', value: 'ALL' })
  const [anchorEl, setAnchorEl] = useState(null)

  const handleCloseMenu = val => {
    if (val.value)
      setSearchType(val)
    setAnchorEl(null)
  }

  useEffect(() => {
    let isSubscribed = true
    CodeMasterApi.fetchCodeMaster(fetchRecords, 'PROD_SEARCH_TYPE', true, (res) => {
      if (res && res.length && isSubscribed) {
        setSearchTypeOptions(res)
        setSearchType(res[0])
      }
    })
    return () => (isSubscribed = false)
  }, [])

  useEffect(() => {
    setValue('searchType', searchType.value)
  }, [searchType])

  return (
    <Box component={Grid} container direction="column"
      alignItems="stretch" px={2}
    >
      <Grid item><Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2" className={classes.count}
          >
            {total} item(s)
          </Typography>
        </Grid>
        <Grid item xs className={classes.searchBar}>
          <Button
            color="primary"
            size="small"
            endIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']}
              size="xs"
            />}
            aria-controls="search_type" aria-haspopup="true"
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            {searchType.text}
          </Button>
          <Menu
            id="search_type"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            getContentAnchorEl={null}
          >
            {searchTypeOptions.map(item =>
              <MenuItem key={item.value} onClick={() => handleCloseMenu(item)}>
                {item.text}
              </MenuItem>
            )}
          </Menu>
          {/* <CngTextField name="searchValue" size="small" */}
          <TextField size="small"
            {...register('searchValue')}
            placeholder="Search anything"
            fullWidth={false}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="search"
                  color="primary"
                  type="submit"
                >
                  <FontAwesomeIcon icon={['fal', 'search']}
                    size="xs"
                  />
                </IconButton>
              </InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Coming soon!"><Box>
            <IconButton disabled
              aria-label="export"
            >
              <FontAwesomeIcon icon={['fal', 'file-export']}
                size="xs"
              />
            </IconButton>
          </Box></Tooltip>
        </Grid>
        <Grid item>
          <CngButton
            onClick={() => history.push(`${pathMap.MY_PRODUCT_FTA}/new`)}
            startIcon={<FontAwesomeIcon icon={['fal', 'circle-plus']} />}
          >
            Create product
          </CngButton>
        </Grid>
      </Grid></Grid>
    </Box>
  )
}

export { TableToolbar }
