import React, {useEffect, useState} from 'react'
import { components} from 'cng-web-lib'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngCheckboxField
        },
    },
    table: { useDefaultNotification },
    button: { CngPrimaryButton, CancelButton },
    CngGridItem
} = components

function OriginCalculationUserSelectionCheckBox(props) {

    const {data, index, originCalSelection, setOriginCalSelection, uncheckAll, setUncheckAll, setCheckBoxSectionKey, checkBoxSectionKey} = props

    const [checked, setChecked] = useState(originCalSelection.includes(data.id))

    const handleChange = (e) => {
        let isChecked = e.target.checked
        let id = Number(e.target.value)

        if(uncheckAll) {
            let newList = []
            newList.push(id)
            setOriginCalSelection(newList)
            setChecked(isChecked)
            setUncheckAll(false)
            setCheckBoxSectionKey(checkBoxSectionKey + 1)
        } else {
            let newList = originCalSelection
            if(isChecked && !newList.includes(id)) {
                newList.push(id)
            } else if(!isChecked && newList.includes(id)) {
                newList = newList.filter(item => item !== id)
            }
            setOriginCalSelection(newList)
            setChecked(isChecked)
        }
    }

    useEffect(() => {
        uncheckAll ? setChecked(false) : setChecked(originCalSelection.includes(data.id))
    },[])

    return (
        <CngGridItem item xs={12}>
            <CngCheckboxField
                name={`originCalculation[${index}]`}
                onChange={handleChange}
                label={data.productSpecificRule}
                value={data.id}
                key={data.id+"-"+index}
                checked={checked}
            />
        </CngGridItem>
    )

}

export default OriginCalculationUserSelectionCheckBox;