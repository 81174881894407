import { Yup } from 'cng-web-lib'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'

function FTACalclatorValidationSchema() {
  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return Yup.object().shape(
    {
      hsCodeAndDescription: Yup.string()
        .nullable()
        .required(FtaTranslatedTextsObject.hsCodeAndDescRequiredMsg),
      materialNoSkuProductDesc: Yup.string()
        .nullable()
        .required(FtaTranslatedTextsObject.materialNoSKURequiredMsg),
      model: Yup.string()
        .nullable(),
      unitOfMeasurement: Yup.string()
        .nullable()
        .required(FtaTranslatedTextsObject.unitOfMeasurementRequiredMsg),
      currency: Yup.string()
        .nullable()
        .required(FtaTranslatedTextsObject.currencyRequiredMsg),
      exworksCost: Yup.string()
        .nullable(),
      origin: Yup.string()
        .nullable()
        .required(FtaTranslatedTextsObject.originRequiredMsg)
        .when('destination', (destination, schema) => {
          if (destination) {
            return schema.notOneOf(
              [Yup.ref('destination')],
              FtaTranslatedTextsObject.destinationCannotBeSameMsg
            )
          }
        }),
      destination: Yup.string()
        .nullable()
        .required(FtaTranslatedTextsObject.destinationRequiredMsg)
        .when('origin', (origin, schema) => {
          if (origin) {
            return schema.notOneOf(
              [Yup.ref('origin')],
              FtaTranslatedTextsObject.originCannotBeSameMsg
            )
          }
        }),
      cifValue: Yup.number()
        .nullable()
        .min(0, 'Customs value must be greater than or equal to 0'),
      // .required(FtaTranslatedTextsObject.cifValueRequiredMsg),
      check_hsCode: Yup.string()
        .nullable(),
        //.required(FtaTranslatedTextsObject.importHsCodeRequiredMsg),
      measures: Yup.array(
        Yup.object({
          measureQuantity: Yup.string()
            .nullable()
            .required('Cannot be empty')
        })
      )

    },
    [['origin', 'destination']]
  )
}

export default FTACalclatorValidationSchema
