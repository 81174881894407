import { Chip, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const {
  CngMoreActionsMenu,
  form: {
    field: { CngDateField }
  }
} = components

const moreActionsList = [
  {
    action: 'setDate',
    name: 'Set qualification expiry',
    icon: ['fal', 'edit']
  }
]

const noShowActionIcon = ["DELE", "INSD"]

const RulesOfOriginListCardDetailTop = ({ productStatuses, ...props }) => {
  const { product, statusCode, statusName, assessor } = props
  const {
    originCalculation: { originCalculationId, status }
  } = product
  const [isOpen, setIsOpen] = useState(false)
  const [expiryDate, setExpiryDate] = useState(new Date())

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  // Static list items
  // @purpose: To achieve multi-lingual for static list
  // @refer: Refer parent component i.e. CalcResultsListCardDetailComponent.js i.e. statusColorCodes
  const statusNameStr = productStatuses.find(
    (b) => b.statusName.toUpperCase() === product?.originCalculation?.status.toUpperCase()  
  )?.statusName
  const statusColorCode = productStatuses.find(
    (b) => b.statusName === product?.originCalculation?.status
  )?.color


  const history = useHistory()

  const handleMoreAction = (data, action) => {
    switch (action) {
      case 'setDate':
        console.log('data.action ::', 'Set expiry date')
        props.handleSetQualificationDate(props.dataIndex, true)
        break

      case 'checklist':
        console.log('data.action ::', 'Set checklist')
        break
    }
  }


  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={4}>
          <div className={`cng-summary-card--header-box--left`}>
            <Chip
              label={
                <Typography className={`font-mediumBold font-16`}>
                  {statusNameStr.toUpperCase()}
                </Typography>
              }
              style={{ backgroundColor: statusColorCode }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1 text-right-align box-col`}>
              <div className={`field-vertical--label-value`}>
                <Typography className={'frb-card-label-large-grey'}>
                  {FtaTranslatedTextsObject.assessedBy}
                  <b className='ml-5'>{assessor !== null ? assessor : `-`}</b>
                </Typography>
              </div>
            </div>


            {
              (noShowActionIcon.includes(props.productStatusCode)
                  ? <></>
                  : <div className={`header-box--right-col-2 cta-box box-col`}>
                    <div className={`cng-summary-card--more-actions-wrapper`}>
                      <CngMoreActionsMenu
                          type='primary'
                          size='large'
                          style={{color: '#7680a3'}}
                          menuItems={moreActionsList}
                          iconDirection='horizontal'
                          data={null}
                          onActionSelect={handleMoreAction}
                      />
                    </div>
                  </div>)
            }


          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default RulesOfOriginListCardDetailTop
