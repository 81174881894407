import React, { useState, useEffect } from 'react'
import {
  Typography,
  CardContent
} from '@material-ui/core'
import { Stack } from '@mui/material'
import SearchRecords from './SearchRecords'
import { v4 as uuidv4 } from 'uuid'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined'
import ExpandIcon from '@material-ui/icons/Height'
import CollapseIcon from '@material-ui/icons/VerticalAlignCenter'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

export default function SearchRecordsCard({ response, backButton, downloadPDF, searchRecordId }) {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()

  function makeTranslatedSourceItem() {
    let collapse = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_COLLAPSE
    )
    let expand = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_EXPAND
    )
    let downloadPDF = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_DOWNLOAD
    )
    let backToSearch = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_BACK
    )
    let result = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.FIELD_RESULTS
    )

    return {
      collapse,
      expand,
      downloadPDF,
      backToSearch,
      result
    }
  }

  const [expanded, setExpanded] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  function handleExpand(item) {
    setExpanded(item)
  }

  useEffect(() => {
    // Function to update window width in state
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <CardContent style={{ marginBottom: '1vh' }}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={'2vh'}
        >
          <Typography sx={{ fontSize: 14 }} color='textSecondary'>
            {response.length} {translatedItem.result}
          </Typography>
        </Stack>
        <div>
          {response.map((record, index) => (
            <SearchRecords
              indicator={`accordion+${index}`}
              expanded={expanded}
              party={record}
              key={uuidv4()}
            />
          ))}
        </div>
      </CardContent>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '-webkit-fill-available',
          background: 'white',
          padding: '10px',
          border: '1px solid #ddd',
          marginRight: '45px'
        }}
      >
        {/* Add the content of your sticky bar here */}
        <div>
          <Stack direction={'row'} spacing={2}>
            <CngSecondaryButton onClick={() => handleExpand(null)}>
              <CollapseIcon />
              {translatedItem.collapse}
            </CngSecondaryButton>
            <CngSecondaryButton onClick={() => handleExpand('all')}>
              <ExpandIcon />
              {translatedItem.expand}
            </CngSecondaryButton>
            <CngSecondaryButton onClick={() => downloadPDF(searchRecordId)}>
              <PictureAsPdfIcon />
              {translatedItem.downloadPDF}
            </CngSecondaryButton>
            <div className={'flex-grow'}></div>
            <div>
              <CngPrimaryButton onClick={() => backButton()}>
                <ArrowBackIcon />
                {translatedItem.backToSearch}
              </CngPrimaryButton>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  )
}
