import React, { useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import {
  Card,
  Paper,
  CardContent,
  Grid,
  Checkbox,
  Box,
  Typography,
  useTheme,
  makeStyles,
  Tooltip
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import InfoDialog from '../components/InfoDialog'
import { useFormContext } from 'react-hook-form'

import FTAVersionLogsForm from './FTAVersionLogsForm'
import FTACalculatorValidationSchema from './FTACalculatorValidationSchema'

import { Popover } from '@material-ui/core'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngLookupAutocompleteField },
    CngForm
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem,
  CngTabs
} = components

const { FormState, NotificationType } = constants

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    fontSize: '1em',
    display: 'flex',
    alignItems: 'center',
    color: '#8996AF'
  },
  body: {
    padding: theme.spacing(3),
    fontSize: '1em',
    display: 'block',
    alignItems: 'center'
  },
  title: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0,
    '& > svg': { margin: theme.spacing(0, 3) }
  },
  exportBtn: {
    margin: theme.spacing(0.5)
  },
  tabs: {
    margin: theme.spacing(1, 0)
  },
  listItemAvatar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  listItemEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bottomBtn: {
    padding: theme.spacing(1.25),
    fontSize: '1.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonTransform: {
    textTransform: 'none'
  }
}))

function VersionsLogsTabContent({ history, showNotification }) {
  const theme = useTheme()
  const classes = useStyles()
  const location = useLocation()
  const { securedSendRequest } = useServices()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const form = useFormContext()
  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = FTACalculatorValidationSchema()
  const [disabled, setDisabled] = useState(false)

  return (
    <>
      <CngForm
        formState={isLoading ? FormState.LOADING : FormState.COMPLETED}
        showNotification={showNotification}
        bodySection={
          <FTAVersionLogsForm.FormFields
            history={history}
            showNotification={showNotification}
            disabled={disabled}
          />
        }
        renderButtonSection={() => <></>}
        formikProps={{
          ...FTAVersionLogsForm.formikProps,
          validationSchema
        }}
        toClientDataFormat={FTAVersionLogsForm.toClientDataFormat}
        toServerDataFormat={FTAVersionLogsForm.toServerDataFormat}
      />
    </>
  )
}

export default VersionsLogsTabContent
