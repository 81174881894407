import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

import { BasePage as CngBasePage, useServices } from 'cng-web-lib'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_TradeManagement'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import pathMap from 'src/paths/PathMap_TradeManagement'

import { DeleteDialog, TableBody, TableToolbar } from './components'
import { STATE_KEY, useColumnBuilder } from './helpers'


function MyProductsPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const { securedSendRequest, deleteRecord } = useServices()
  const { enqueueSnackbar } = useSnackbar()
  const { COLS_LANDING } = useColumnBuilder()
  const history = useHistory()

  const methods = useForm({
    defaultValues: {
      searchValue: '',
      searchType: 'ALL'
    }
  })

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteData, setDeleteData] = useState({})
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchParams, setSearchParams] = useState({
    searchType: 'ALL',
    searchValue: '',
    pageNo: 1,
    itemPerPage: 5,
  })
  const [pageInfo, setPageInfo] = useState({
    totalRecords: 0,
    itemPerPage: 5,
  })

  const triggerData = () => {
    setLoading(true)
    securedSendRequest.execute("POST",
      TradeMgmtApiUrls.GET_PRODUCTS_LIST,
      searchParams,
      (res) => {
        if (res.data) {
          if (res.data.status !== 200)
            throw res.data.errorMessages
          setData(res.data.results)
          setPageInfo(res.data.pagingInfo)
        }
      },
      () => enqueueSnackbar('An error occurred while retrieving data.', {variant: 'error'}),
      () => setLoading(false)
    )
  }

  useEffect(() => {
    triggerData()
  }, [searchParams])

  const onSearchParamsUpdate = (key, val) => {
    setSearchParams({
      ...searchParams,
      [key]: val
    })
  }

  const onSubmit = formData => setSearchParams({
    ...searchParams,
    ...formData
  })

  const goToProduct = id => history.push(`${pathMap.MY_PRODUCT_FTA}/${id || 'new'}`)

  const handleEdit = (e, row) => {
    localStorage.setItem(STATE_KEY, btoa("EDIT"))
    goToProduct(row.data.id)
  }
  const handleCopy = (e, row) => {
    localStorage.setItem(STATE_KEY, btoa(row.data.id))
    goToProduct()
  }
  const handleDelete = (e, row) => {
    setDeleteData({
      id: row.data.id,
      desc: row.data.materialSkuProductDescription
    })
    setDeleteDialogOpen(true)
  }

  return (
    <CngBasePage
      moduleTitle={'Export'}
      renderPage={(showSnackbar) => (
        <Box component={Card} py={2}>
          <FormProvider {...methods}><form onSubmit={methods.handleSubmit(onSubmit)}>
            <TableToolbar total={pageInfo.totalRecords} />
            <TableBody data={data}
              columns={COLS_LANDING}
              pageInfo={pageInfo}
              onPageInfoUpdate={onSearchParamsUpdate}
              isLoading={loading}
              onRowClick={(e, rowData) => goToProduct(rowData.id)}
              TableProps={{
                actions: [{
                  icon: 'pen',
                  onClick: handleEdit,
                  tooltip: 'Edit',
                }, {
                  icon: 'copy',
                  onClick: handleCopy,
                  tooltip: 'Copy',
                }, {
                  icon: 'trash',
                  onClick: handleDelete,
                  tooltip: 'Delete',
                  iconProps: {
                    color: 'text.error'
                  }
                }]
              }}
            />
          </form></FormProvider>
          <DeleteDialog open={deleteDialogOpen != false}
            onClose={() => setDeleteDialogOpen(false)}
            onComplete={() => triggerData()}
            {...deleteData}
          />
        </Box>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { MyProductsPage }
