import React, { useEffect, useState } from 'react'

import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import UserPreferenceTranslationText from '../shared/UserPreferenceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import UserPreferenceApiUrls from 'src/apiUrls/UserPreferenceApiUrls'

import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Button
} from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'

const {
  form: {
    field: { CngSelectField, CngCheckboxField },
    adapter: {
      useFormAdapter: { useFormikContext }
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  userId: '',
  timezone: '',
  observeDst: false,
  version: 0,
  id: null
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ showNotification, loading, setLoading, refresh }) {
  const translatedTextsObject = UserPreferenceTranslationText()
  const commonTranslatedTextsObject = CalistaUiComponentTranslationText()
  const { fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()
  const defaultTimezone = "System Timezone";

  const [timezoneList, setTimezoneList] = useState([])

  useEffect(() => {
    // Load default timezone pref after submit
    setLoading(true)
    fetchTimezoneOptions()
  }, [refresh])

  function fetchTimezoneOptions() {
    fetchRecords.execute(
      UserPreferenceApiUrls.TZ_OPTIONS_GET,
      undefined,
      (data) => {
        console.log('Data returned for timezone: ' + JSON.stringify(data))
        let temp = []
        const systemTimezone = {}
        systemTimezone['text'] = defaultTimezone
        systemTimezone['value'] = defaultTimezone
        temp.push(systemTimezone)
        data.content.forEach((tz) => {
          // Uncomment below to get all timezones, currently filtered according to CG

          // if (!tz.name.includes("GMT")) {
          //   let timezone = {}
          //   timezone['text'] = tz.name
          //   timezone['value'] = tz.value
          //   temp.push(timezone)
          // }
          if (tz.value.includes('Asia')) {
            let timezone = {}
            timezone['text'] = tz.value
            timezone['value'] = tz.value
            temp.push(timezone)
          } else if (tz.value.includes('Europe')) {
            let timezone = {}
            timezone['text'] = tz.value
            timezone['value'] = tz.value
            temp.push(timezone)
          }
        })
        setTimezoneList(temp)
        fetchUserPref()
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )
  }

  function fetchUserPref() {
    fetchRecords.execute(
      UserPreferenceApiUrls.TZ_GET,
      undefined,
      (data) => {
        console.log('Data returned: ' + JSON.stringify(data))
        const dataArr = Object.entries(data)
        dataArr.forEach(([key, val]) => {
          if(val != null){
            setFieldValue(key, val)
          }
          if (key === 'timezone' && (data["timezone"] === '' || !data["timezone"] )) {
            setFieldValue(key, defaultTimezone)
          }
        })
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Typography className={'boldLabel'} style={{ fontSize: 15 }}>
            {translatedTextsObject.timezoneTitle}
          </Typography>
        </Grid>

        <Box mt={3} pl={2}>
          <Grid
            container
            spacing={2}
            xs={12}
            sm={12}
            justifyContent='flex-start'
          >
            <CngGridItem xs={6} sm={6}>
              <CngSelectField
                name='timezone'
                label={translatedTextsObject.timezone}
                items={timezoneList}
                required
                SelectProps={{
                  classes: {
                    root: 'select-props'
                  },
                  MenuProps: {
                    MenuListProps: {
                      classes: {
                        root: 'menu-list'
                      }
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
              />
            </CngGridItem>
          </Grid>
          <Grid
            container
            spacing={2}
            xs={12}
            sm={12}
            justifyContent='flex-start'
          >
            <Box mt={1} ml={1}>
              <CngCheckboxField
                name='observeDst'
                label={translatedTextsObject.supportDST}
              />
            </Box>
          </Grid>
        </Box>
        <Box pt={2} mr={2}>
          <Grid container justify='flex-end'>
            <Button
              variant='contained'
              className='button-blue originalText'
              onClick={() => {
                submitForm()
              }}
            >
              {commonTranslatedTextsObject.saveChanges}
            </Button>
          </Grid>
        </Box>
        <Grid>
          <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
            <CngBackdrop loading={loading} />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const TimezoneUserPrefFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: Fields
})

export default TimezoneUserPrefFormProperties
