import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  FormLabel
} from '@material-ui/core'

import RulesOfOriginListCardDetailTop from './RulesOfOriginListCardDetailTop'
import RulesOfOriginListCardDetailBottom from './RulesOfOriginListCardDetailBottom'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { generatePath, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const {
  CngGridItem,
  button: { CngIconButton }
} = components

const initialValues = Object.freeze({})

const useStyles = makeStyles((theme) => ({
  fnButtonCircle: {
    '& .ng-button-func': {
      borderRadius: '50% !important',
      '&.medium': {

        width: '32px',
        height: '32px',
        minHeight: '32px',

        boxShadow: 'none',
        backgroundColor: 'transparent',
        borderWidth: '0'
      },
      '& svg': {
        backgroundColor: theme.palette.text.textSecondary,
        borderRadius: '50%',
        color: theme.palette.color.white,
        fontSize: '20px !important',
        padding: '4px'
      }
    }
  },
  tooltipContent: {
    fontSize: '14px',
    fontWeight: 400
  },
  tooltipLabel: {
    fontSize: '14px',
    fontWeight: 400
  },
  chipInfo: {
    color: theme.palette.text.textSecondary,
    cursor: 'pointer',
    fontWeight: 500,
    paddingBlock: '15px'
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',

    '& svg': {
      fontSize: '20px',
      marginInlineStart: '5px'
    }
  }
}))

const FormBody = (props) => {
  const classes = useStyles()

  const history = useHistory()
  const {
    calculationMethodFormula,
    criterion,
    productSpecificRules,
    originCalculation: {
      calculation,
      assessor,
      nonOriginatingValue,
      baseUnit,
      thresholdValuePerUom,
      directLabourCost,
      directOverheardCost,
      profit,
      otherCost,
      qvc,
      rvC,
      qualitifcationDate,
      qualificationExpiry,
      status
    }
  } = props.product

  const dates = {
    qualificationDate: qualitifcationDate,
    qualificationExpiry: qualificationExpiry
  }

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  // @purpose: This is used to render the pill shape at the card header
  const statusColorCodes = [
    {
      statusCode: 'QUAL',
      statusName: FtaTranslatedTextsObject.statusQualified,
      color: '#7CE7AC4D',
      actions: []
    },
    {
      statusCode: 'DQUA',
      statusName: FtaTranslatedTextsObject.statusDNQualified,
      color: '#1C1D214D',
      actions: []
    },
    {
      statusCode: 'SELF',
      statusName: FtaTranslatedTextsObject.statusSelfAssessmentRequired,
      color: '#F578B24D',
      actions: []
    },
    {
      statusCode: 'PRIC',
      statusName: FtaTranslatedTextsObject.statusPriceDependent,
      color: '#5E81F44D',
      actions: []
    },
    {
      statusCode: 'INSD',
      statusName: FtaTranslatedTextsObject.statusInsufficientData,
      color: '#E8E800',
      actions: []
    }
  ]

  return (
    <Card className={`summary-list-item--card`}>
      {/* Card header */}
      <RulesOfOriginListCardDetailTop
        assessor={assessor}
        statusCode={props.statusCode}
        statusName={status}
        productStatuses={statusColorCodes}
        handleSetQualificationDate={props.handleSetQualificationDate}
        product={props.product}
        dataIndex={props.dataIndex}
        productStatusCode={props.productStatusCode}
      />

      {/* Card body */}
      <div className={`cng-summary-card--body-box`}>
        <Grid container className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={12} sm={3}>
            <div
              className={`field-vertical--label-value`}
              style={{ marginInlineStart: '12px' }}
            >
              <span className={`field-vertical--item`}>
                <label
                  className={`field-value clamp-line-to-two font-16 pr-10`}
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden', wordBreak: 'break-word' }}
                >
                  <Tooltip
                    title={criterion}
                    placement='bottom'
                    className={classes.tooltipContent}
                    classes={{
                      tooltip: classes.tooltipLabel
                    }}
                  >
                    <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      {criterion}
                    </p>
                  </Tooltip>

                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-label font-14 font-mediumBold`}>
                  {/* {regionalValue !== null ? regionalValue : `-`} */}
                  {`-`}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-label font-14 pr-10`}>
                  <Tooltip
                    title={productSpecificRules}
                    placement='bottom'
                    className={classes.tooltipContent}
                    classes={{
                      tooltip: classes.tooltipLabel
                    }}
                  >
                    <Chip
                      label={
                        <>
                          Product Specific Rule
                          <FontAwesomeIcon icon={faCircleInfo} />
                        </>
                      }
                      classes={{
                        label: classes.chipLabel
                      }}
                      size='small'
                      className={classes.chipInfo}
                    />
                  </Tooltip>
                </label>
              </span>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={9}>
            <Grid container>
              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.calculation}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {calculation !== null ? calculation : `-`} &#37;
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.thresholdValuePerUOM}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {thresholdValuePerUom !== null
                        ? thresholdValuePerUom
                        : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.profit}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {profit !== null ? profit : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.nonOriginatingValue}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {nonOriginatingValue !== null ? nonOriginatingValue : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.directLabourCost}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {directLabourCost !== null ? directLabourCost : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.otherCost}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {otherCost !== null ? otherCost : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.baseUnit}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {baseUnit !== null ? baseUnit : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.directOverheardCost}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {directOverheardCost !== null ? directOverheardCost : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.qvc} &#47;
                      {FtaTranslatedTextsObject.rvc}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {qvc !== null ? qvc : `-`}
                      &#47;
                      {rvC !== null ? rvC : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </div>

      {/* Card footer */}
      <RulesOfOriginListCardDetailBottom product={dates} />
    </Card>
  )
}

const RulesOfOriginListCardDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default RulesOfOriginListCardDetailComponent
