import React, { useEffect, useRef, useState, useMemo, useCallback, createContext } from 'react'
import {
  Box,
  Grid,
  TextField,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { components, useServices } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import pathMap from 'src/paths/PathMap_TmpAdmin'
import CreateComponentDialog from './CreateComponentDialog.js'
import { COLUMN_BUILDER_URLS, useColumnBuilder, useBOMComponents } from '../helpers'
import {
  AttachedFilter,
  CombinedQty,
  EditCell,
  TableBody,
} from '.'

const {
  button: { CngPrimaryButton, CngButton },
  form: { field: { CngTextField } },
  CngDialog
} = components

const useStyles = makeStyles(theme => ({
  btnAttached: {
    "& > *:first-child": {
      background: theme.palette.text.error + '0f !important',
      color: theme.palette.text.error + ' !important'
    }
  },
  field: {
    "& > *": {
      borderRadius: 8
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: 'translate(14px, -1px) scale(.75)'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.background.ctaBtnActiveBorder,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.component.textFormControl.hoverBorder
    },
    "& .MuiFormHelperText-root": { margin: theme.spacing(1, 0, 0) }
  },
  hide: { display: 'none' }
}))

const DialogContext = createContext(null)

function SearchDialog({ onClose, ...props }) {
  const { fetchRecords } = useServices()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { loading: colLoading, COLS_BOM } = useColumnBuilder([
    { url: COLUMN_BUILDER_URLS.tmp, payload: "TMP_COMPONENT_MATERIAL" },
    { url: COLUMN_BUILDER_URLS.getTmp('GET_COUNTRY'), payload: 'COUNTRY' }
  ], true)
  const { getValues, setValue, watch } = useFormContext()
  const { loading, getComponentDetail } = useBOMComponents()
  const watchComps = watch('REF_components', [])
  const allState = useMemo(() => {
    const count = (getValues('REF_components') || []).filter(x => x.attached).length
    if (count === 0)
      return "detached"
    else if (count === (getValues('REF_components') || []).length)
      return "attached"
    return ""
  }, [getValues('REF_components')])

  const [qtyEditOpen, setQtyEditOpen] = useState(false)
  const [qtyEdit, setQtyEdit] = useState('')
  const qty = useRef([])
  const qtyInput = useRef([])
  const [refreshComponent, setRefreshComponent] = useState(0)

  const handleRefreshComponent = () => {
    setRefreshComponent(refreshComponent + 1)
  }

  const handleAttachAll = () => {
    setValue('REF_components', getValues('REF_components').map(x => {
      x.attached = allState !== 'attached'
      return x
    }))
  }
  const handleOpenQty = () => {
    setQtyEdit('')
    setQtyEditOpen(true)
  }
  const handleQtyEdit = () => {
    setValue('REF_components', getValues('REF_components').map(x => {
      x.quantity = qtyEdit
      return x
    }))
    setQtyEditOpen(false)
  }

  const handleAttach = rowData => {
    let id = rowData.tableData.id
    let attached = getValues(`REF_components[${id}].attached`) || false
    setValue(`REF_components[${id}].attached`, !attached)
  }

  const handleClose = (e, reason) => {
    let missing = getValues('REF_components').filter((x, i) => x.attached && !qty.current[i] && !x.quantity)
    if (missing.length)
      enqueueSnackbar(
        'Please add unit quantity for attached components.',
        { variant: 'error' }
      )
    else {
      setValue('REF_components', getValues('REF_components').map((x, i) => {
        if (x.attached && qty.current[i])
          x.quantity = parseFloat(qty.current[i])
        return x
      }))
      qty.current = []
      onClose(e, reason)
    }
  }

  const [isCompomnentDialogOpen, setComponentDialogOpen] = useState(false)

  function closeComponentDialog() {
    setComponentDialogOpen(false)
    getComponentDetail()
    handleRefreshComponent()
  }


  return <>
    <CngDialog
      customDialogContent={<DialogContext.Provider value={{
        qty,
        qtyInput
      }}>
        <Box mx={2} my={1}><Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Box component={Typography} variant="body2" color="text.textSecondary">
              {watchComps?.length} items found.
            </Box>
          </Grid>
          <Grid item>
            <IconButton size='small'
              onClick={getComponentDetail}
            >
              <FontAwesomeIcon icon={['fal', 'arrows-rotate']} />
            </IconButton>
          </Grid>
          <Grid item>
            <CngButton color="secondary"
              startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
              onClick={() => {
                setComponentDialogOpen(true)
              }}
            >
              Create Component
            </CngButton>
          </Grid>
          <Grid item>
            <CngButton color="secondary"
              startIcon={<FontAwesomeIcon icon={['fal', 'pen']} />}
              onClick={handleOpenQty}
              disabled={allState !== "attached"}
            >
              Quick edit
            </CngButton>
          </Grid>
          <Grid item className={allState === 'attached' ? classes.btnAttached : ''}>
            <CngButton color="secondary"
              startIcon={<FontAwesomeIcon icon={['fal',
                allState === 'attached' ? 'link-horizontal-slash' : 'link-horizontal'
              ]} />}
              onClick={handleAttachAll}
            >
              {allState === 'attached' ? "Detach" : "Attach"} all
            </CngButton>
          </Grid>
        </Grid></Box>
        {(colLoading || !props.open) ? <Skeleton variant="rect" height={120} />
          : <TableBody
            key={refreshComponent}
            data={getValues('REF_components') || []}
            columns={[
              ...COLS_BOM,
              {
                title: 'Quantity', field: 'attached', type: 'boolean',
                filtering: true,
                width: '20%',
                render: rowData => <CombinedQty {...rowData} />,
                filterComponent: fProps => <AttachedFilter
                  classes={classes}
                  {...fProps}
                />,
                customFilterAndSearch: (val, rowData) => {
                  if (val === 'All')
                    return true
                  if (val === 'Detached')
                    return !rowData.attached
                  return rowData.attached
                }
              },
            ]}
            isLoading={loading}
            noMT
            TableProps={{
              options: { filtering: true },
            }}
          />
        }
      </DialogContext.Provider>
      }
      fullWidth
      maxWidth='xl'
      shouldShowCloseButton
      dialogAction={
        <>
          <CngPrimaryButton onClick={handleClose}>
            Confirm
          </CngPrimaryButton>
        </>
      }
      onClose={handleClose}
      {...props}
    />
    <CreateComponentDialog
      isDialogOpen={isCompomnentDialogOpen}
      closeDialog={closeComponentDialog}
      showNotification={() => { }} />

    <CngDialog shouldShowCloseButton
      open={qtyEditOpen}
      dialogTitle='Edit unit quantities'
      fullWidth maxWidth="xs"
      customDialogContent={<Box p={2}>
        <TextField className={classes.field}
          placeholder='Set quantity'
          variant="outlined"
          value={qtyEdit}
          type="number" fullWidth
          onChange={e => setQtyEdit(e.target.value)}
        />
      </Box>}
      dialogAction={<>
        <CngButton color="secondary" onClick={() => setQtyEditOpen(false)}>
          Cancel
        </CngButton>
        <CngButton onClick={handleQtyEdit}>
          Confirm
        </CngButton>
      </>}
    />
  </>
}

export { SearchDialog, DialogContext, useStyles as useFieldStyles }
