import {
  Card,
  CardActionArea,
  Grid,
  IconButton,
  FormLabel
} from '@material-ui/core'

import FtaActivityHistoryListCardDetailTop from './FtaActivityHistoryListCardDetailTop'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { generatePath, useHistory } from 'react-router-dom'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import FtaActivityHistoryListCardDetailBottom from './FtaActivityHistoryListCardDetailBottom'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const InfoIconButton = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(IconButton)

const BulkParcelIconLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.color.primaryColor
  }
}))(FormLabel)

const FormBody = ({ showNotification, ...props }) => {
  const history = useHistory()
  const { securedSendRequest } = useServices()
  const [loading, setLoading] = useState(false)
  const [pageError, setPageError] = useState(false)

  const { product, countryCodes } = props

  const {
    ftaActivityHistoryId,
    ftaActivityResultId,
    origin,
    originCountryCode,
    destination,
    destinationCountryCode,
    materialNo,
    hsCode,
    hsDescription,
    productDescription,
    companyName,
    companyRefNo,
    updatedDate,
    updatedBy,
    statusCode
  } = product

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  // Prepare route to view details page
  const handleViewFtaProductDetails = (product) => {
    console.log('fetch details from parent and route to product details page')
    props.fetchProductAndRouteToFtaDetails(ftaActivityHistoryId, product.statusCode)
  }

  // Set state whenever the error is encountered
  const onError = (error) => {
    setPageError(true)
    setLoading(false)
  }

  // @purpose: This is used to render the pill shape at the card header
  const statusColorCodes = [
    {
      statusCode: 'DELE',
      statusName: FtaTranslatedTextsObject.statusDeleted,
      color: '#F578B24D',
      actions: []
    },
    {
      statusCode: 'ACTV',
      statusName: FtaTranslatedTextsObject.statusActive,
      color: '#7CE7AC4D',
      actions: []
    }
  ]

  return (
    <Card className={`summary-list-item--card`}>
      <FtaActivityHistoryListCardDetailTop
        product={product}
        companyRefNo={companyRefNo}
        updatedDate={updatedDate}
        statusColorCodes={statusColorCodes}
        {...props}
      />

      <CardActionArea
        className={`cng-summary-card--body-box`}
        onClick={() => handleViewFtaProductDetails(product)}
      >
        <Grid container className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={12} sm={3}>
            <div
              className={`field-vertical--label-value`}
              style={{ marginInlineStart: '12px' }}
            >
              <span className={`field-vertical--item`}>
                <label className={`field-value font-20 font-mediumBold`}>
                  {materialNo !== null ? materialNo : `-`}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value font-16`}>
                  {hsCode !== null ? hsCode : `-`}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value font-16`}>
                  {companyName !== null ? companyName : `-`}
                </label>
              </span>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={9}>
            <Grid container>
              <CngGridItem xs={12} sm={6}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.hsDescription}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {hsDescription !== null ? hsDescription : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={6}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.productDescription}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {productDescription !== null ? productDescription : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={6}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.countryOrigin}
                    </label>
                  </span>

                  <div className={`field-country-details`}>
                    {originCountryCode != '' ? (
                      <img
                        className={`field-country-flag`}
                        alt='Flag'
                        src={`${process.env.PUBLIC_URL
                          }/static/images/countries/${originCountryCode.substring(
                            0,
                            2
                          )}.svg`}
                      ></img>
                    ) : (
                      <img
                        className={`field-country-flag`}
                        alt='Flag'
                        src={`${process.env.PUBLIC_URL}/static/images/countries/genericCountry.svg`}
                      ></img>
                    )}

                    {origin}
                  </div>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={6}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.countryDestination}
                    </label>
                  </span>

                  <div className={`field-country-details`}>
                    {destinationCountryCode != '' ? (
                      <img
                        className={`field-country-flag`}
                        alt='Flag'
                        src={`${process.env.PUBLIC_URL
                          }/static/images/countries/${destinationCountryCode.substring(
                            0,
                            2
                          )}.svg`}
                      ></img>
                    ) : (
                      <img
                        className={`field-country-flag`}
                        alt='Flag'
                        src={`${process.env.PUBLIC_URL}/static/images/countries/genericCountry.svg`}
                      ></img>
                    )}

                    {destination}
                  </div>
                </div>
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

const FtaActivityHistoryListCardDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default FtaActivityHistoryListCardDetailComponent
