import React from 'react'
import { Card, CardContent, CardHeader, Grid, Link, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace';
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch';


function SourceInfo(props) {

    const {translate} = useTranslation(Namespace.TMP_DPS)
    const translatedItem = makeTranslatedSourceItem()

    function makeTranslatedSourceItem() {
        let source = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.SOURCE_SOURCE)
        let sourceLink = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.SOURCE_SOURCELINK)
        let restrictSource = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.SOURCE_RESTRICTSOURCE)
        let effectiveDate = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.SOURCE_EFFDATE)
    
        return {
            source,
            sourceLink,
            restrictSource,
            effectiveDate
      }
    }

    return (
    <Card variant="outlined" style={{borderRadius:'0px'}}>
            <CardHeader title={"Source Information"} titleTypographyProps={{variant:'h5', style:{fontWeight:"bold"}}} style={{backgroundColor: "#ececec", padding:'2vh'}}/>
            <CardContent style={{padding: "2vh"}}>
                <Grid container style={{marginBottom:"3vh"}}>
                    <Grid item xs={6}>
                        <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.source}</Typography>
                            <Typography variant="h5" color="textPrimary">{props.party.dataSourceName === null ? "- -" : props.party.dataSourceName}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                    <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.sourceLink}</Typography>
                            {props.party.dpsRiskResultAdditionalInfo.sourceLink === null ? (
                                <Typography variant="h5">{"- -"}</Typography>
                            ) : (
                                <Link href={props.party.dpsRiskResultAdditionalInfo.sourceLink === null ? '#' : props.party.dpsRiskResultAdditionalInfo.sourceLink} underline='always'>
                                    <Typography variant="h5">{props.party.dpsRiskResultAdditionalInfo.sourceLink === null ? "- -" : props.party.dpsRiskResultAdditionalInfo.sourceLink}</Typography>
                                </Link>
                            ) }
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container style={{marginBottom:"3vh"}}>
                    <Grid item xs={12}>
                    <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.restrictSource}</Typography>
                            <Typography variant="h5" color="textPrimary">{props.party.dpsRiskResultAdditionalInfo.restrictionSource ? "- -" : props.party.dpsRiskResultAdditionalInfo.restrictionSource}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container style={{marginBottom:"3vh"}}>
                    <Grid item xs={12}>
                    <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.effectiveDate}</Typography>
                            <Typography variant="h5" color="textPrimary">{props.party.dpsRiskResultAdditionalInfo.restrictionDate === null ? "- -" : props.party.dpsRiskResultAdditionalInfo.restrictionDate}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SourceInfo