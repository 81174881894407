import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  FormLabel
} from '@material-ui/core'

import DutySavingsListCardDetailTop from './DutySavingsListCardDetailTop'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { withStyles } from '@material-ui/core/styles'
import { generatePath, useHistory } from 'react-router-dom'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const InfoIconButton = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(IconButton)

const BulkParcelIconLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.color.primaryColor
  }
}))(FormLabel)

const FormBody = ({ ...props }) => {
  const history = useHistory()
  const {
    defaultDutyAmount,
    ftaDutyAmount,
    potentialDutySavings,
    potentialDutySavingsPercentage
  } = props.product

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return (
    <Card className={`summary-list-item--card`}>
      {/* Card header */}
      <DutySavingsListCardDetailTop
        potentialDSPercent={potentialDutySavingsPercentage}
      />

      {/* Card body */}
      <div className={`cng-summary-card--body-box`}>
        <Grid container className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={12} sm={12}>
            <Grid container>
              <CngGridItem xs={12} sm={4}>
                <div
                  className={`field-vertical--label-value`}
                  style={{ marginInlineStart: '12px' }}
                >
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.defaultDutyAmount}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      <Chip
                        label={
                          <b style={{ fontSize: 10 }}>{props.currencyCode}</b>
                        }
                        size='small'
                        style={{ marginRight: 5 }}
                        className={`field-country-code`}
                      />
                      {defaultDutyAmount !== null ? defaultDutyAmount : `-`}{' '}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.ftaDutyAmount}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      <Chip
                        label={
                          <b style={{ fontSize: 10 }}>{props.currencyCode}</b>
                        }
                        size='small'
                        style={{ marginRight: 5 }}
                        className={`field-country-code`}
                      />
                      {ftaDutyAmount !== null ? ftaDutyAmount : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.potentialDutySavings}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      <Chip
                        label={
                          <b style={{ fontSize: 10 }}>{props.currencyCode}</b>
                        }
                        size='small'
                        style={{ marginRight: 5 }}
                        className={`field-country-code`}
                      />
                      {potentialDutySavings !== null
                        ? potentialDutySavings
                        : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </div>
    </Card>
  )
}

const ParcelListDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ParcelListDetailComponent
