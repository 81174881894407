import { Chip, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngMoreActionsMenu } = components

const DutySavingsListCardDetailTop = ({ ...props }) => {
  const { potentialDSPercent } = props

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  const history = useHistory()

  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={4}>
          <div className={`cng-summary-card--header-box--left`}></div>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1 text-right-align box-col`}>
              <div className={`field-vertical--label-value`}>
                <Typography className={'frb-card-label-large-grey'}>
                  {FtaTranslatedTextsObject.potentialDutySavingsPercentage}
                  <b className='ml-5'>
                    {potentialDSPercent !== null ? potentialDSPercent : `-`}{' '}
                    &#37;
                  </b>
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default DutySavingsListCardDetailTop
