import { Box, Paper, Grid, Chip, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CngBackdrop from '../components/CngBackdrop'
import { useFormContext } from 'react-hook-form'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faLacrosseStick } from '@fortawesome/pro-light-svg-icons'
import Utils from 'src/shared/Utils'
import GoBackButton from 'src/components/button/GoBackButton'

const { CngGridItem } = components

const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    borderRadius: '8px',
    padding: '16px'
  },
  sectionDarkBlueBg: {
    backgroundColor: '#9698D61A',
    padding: '20px'
  },
  sectionHeaderDelete: {
    backgroundColor: '#B7B8BA',
    padding: '20px',
    borderRadius: '25px',
    fontWeight: 'bold'
  },
  sectionHeader: {
    paddingInline: '20px',
    paddingBlock: '20px 0'
  },
  separatorInBetween: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content',

    '& .divider-vertical': {
      height: '100%',
      width: '1px',
      backgroundColor: Utils.hexToRgba(theme.palette.color.primaryGray, 0.2),
      display: 'flex',
      marginInline: '30px'
    },

    '& > .field-vertical--label-value': {
      marginBlockEnd: 0
    }
  }
}))

const FORM_INITIAL_VALUES = Object.freeze({})

const FormBody = ({ disabled, ...props }) => {
  const {
    materialSkuProductDescription,
    hsCodeAndDescription,
    productDescription,
    chapterNo,
    model,
    unitOfMeasurement,
    unitOfMeasurementName,
    currency,
    exworksCost,
    origin,
    destination,
    originCode = '',
    destinationCode = '',
    cifValue,
    baseUnit,
    fobPrice,
    transactionValue,
    netCosts,
    totalCosts
  } = props.paramsData

  const { isRoutefromCalcScreen } = props

  const history = useHistory()
  const classes = useStyles()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  const navigateToCalculate = () => {
    history.push(`/cal-tmp/ftacalculator`)
  }

  const navigateToSearchHistory = () => {
    history.push(`/cal-tmp/ftaactivityhistory`)
  }

  return (
    <>
      {/* Section */}
      {/* {for deleted status here?} */}
      {props.productStatusCode === 'DELE' ?
        <Box className={`cng-details-page__section ${classes.sectionHeaderDelete}`}>
          DELETED
        </Box>
        : <br />
      }
      <Box className={`cng-details-page__section ${classes.sectionHeader}`}>
        <Grid container className='cng-grid-container'>
          {/* Row 1 */}
          <CngGridItem xs={12} sm={6}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.materialNoSkuPdtDescription}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>
                  {materialSkuProductDescription}
                </label>
              </span>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={6}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.hsCodeAndDescription}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{hsCodeAndDescription}</label>
              </span>
            </div>
          </CngGridItem>
        </Grid>

        <Grid container className='cng-grid-container'>
          {/* Row 2 */}
          <CngGridItem xs={12} sm={2}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.model}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{model}</label>
              </span>
            </div>
          </CngGridItem>


          <CngGridItem xs={12} sm={2}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {'Base Unit'}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{baseUnit}</label>
              </span>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={2}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.unitOfMeasurement}
                </label>
              </span>

              <div className={`field-country-details`}>
                <Chip
                  label={<b style={{ fontSize: 10 }}>{unitOfMeasurement}</b>}
                  size='small'
                  style={{ marginRight: 5 }}
                  className={`field-country-code`}
                />

                <label className={`field-value`}>{unitOfMeasurementName}</label>
              </div>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={3}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.currency}
                </label>
              </span>

              <div className={`field-country-details`}>
                <Chip
                  label={<b style={{ fontSize: 10 }}>{currency}</b>}
                  size='small'
                  style={{ marginRight: 5 }}
                  className={`field-country-code`}
                />

              </div>
            </div>
          </CngGridItem>
        </Grid>




        <Grid container className='cng-grid-container'>
          {/* Row 3 */}
          <CngGridItem xs={12} sm={3}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.exworksCost}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{exworksCost}</label>
              </span>
            </div>
          </CngGridItem>


          <CngGridItem xs={12} sm={2}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.fobPrice}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{fobPrice}</label>
              </span>
            </div>
          </CngGridItem>


          <CngGridItem xs={12} sm={2}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.transactionValue}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{transactionValue}</label>
              </span>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={2}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.netCosts}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{netCosts}</label>
              </span>
            </div>
          </CngGridItem>



          <CngGridItem xs={12} sm={3}>
            <div className={`field-vertical--label-value`}>
              <span className={`field-vertical--item`}>
                <label className={`field-label`}>
                  {FtaTranslatedTextsObject.totalCosts}
                </label>
              </span>

              <span className={`field-vertical--item`}>
                <label className={`field-value`}>{totalCosts}</label>
              </span>
            </div>
          </CngGridItem>
        </Grid>


      </Box>

      {/* Section */}
      <Box className={`cng-details-page__section ${classes.sectionDarkBlueBg}`}>
        <Grid container>
          <CngGridItem xs={12} sm={8}>
            <div
              className={`separator-in-between ${classes.separatorInBetween}`}
            >
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.origin}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={'ml-10 mr-10'}
                    />
                    {FtaTranslatedTextsObject.destination}
                  </label>
                </span>
                <div className={`field-country-details`}>
                  {originCode != '' ? (
                    <img
                      className={`field-country-flag`}
                      alt='Flag'
                      src={`${process.env.PUBLIC_URL
                        }/static/images/countries/${originCode.substring(
                          0,
                          2
                        )}.svg`}
                    ></img>
                  ) : (
                    <img
                      className={`field-country-flag`}
                      alt='Flag'
                      src={`${process.env.PUBLIC_URL}/static/images/countries/genericCountry.svg`}
                    ></img>
                  )}

                  {origin !== null ? origin : ''}

                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className={'ml-10 mr-10'}
                  />
                  {destinationCode != '' ? (
                    <img
                      className={`field-country-flag`}
                      alt='Flag'
                      src={`${process.env.PUBLIC_URL
                        }/static/images/countries/${destinationCode.substring(
                          0,
                          2
                        )}.svg`}
                    ></img>
                  ) : (
                    <img
                      className={`field-country-flag`}
                      alt='Flag'
                      src={`${process.env.PUBLIC_URL}/static/images/countries/genericCountry.svg`}
                    ></img>
                  )}

                  {destination !== null ? destination : ''}
                </div>
              </div>

              <div className={`divider-vertical`}></div>

              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.cifValue}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>{cifValue}</label>
                </span>
              </div>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <span className='cta-btn-wrapper flex-end'>
              {isRoutefromCalcScreen ? (
                <GoBackButton
                  onClick={() => navigateToCalculate()}
                  label={CommonFtaTranslatedTextsObject.btnBackToCalculator}
                />
              ) : (
                <GoBackButton
                  onClick={() => navigateToSearchHistory()}
                  label={
                    CommonFtaTranslatedTextsObject.btnBackToSearchActivityHistory
                  }
                />
              )}
            </span>
          </CngGridItem>
        </Grid>
      </Box>
    </>
  )
}

const ProductDetailsParamSummaryHeader = Object.freeze({
  FormBody: FormBody,
  initialValues: FORM_INITIAL_VALUES
})

export default ProductDetailsParamSummaryHeader
