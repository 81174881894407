import React, { useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import {
  makeStyles
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import RulesOfOriginForm from './RulesOfOriginForm'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'


const { FormState, NotificationType } = constants

const useStyles = makeStyles((theme) => ({}))

function RulesOfOriginTabContent({ history, detailsData, showNotification, ...props }) {
  const [disabled, setDisabled] = useState(true)
  

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  function onSubmit(data) {
    // console.log('Create New Parcel :: onSubmit ::\n', data)
  }

  return (
    <>
      <div className='page-grid child-of-grid one-col mh-200'>
        <main className='page-grid__col one-col'>
          <RulesOfOriginForm.FormFields
            history={history}
            showNotification={showNotification}
            disabled={disabled}
            rooPayload={props.rooPayload}
            statusCode={props.statusCode}
            detailsData={detailsData}
            productStatusCode={props?.productStatusCode}
            ftaName={props.ftaName}
          />
        </main>
      </div>
    </>
  )
}

export default RulesOfOriginTabContent
