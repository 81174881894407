import { Box, Grid, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBoxTaped,
  faBoxesStacked,
  faRefresh
} from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const FtaListNoResult = (props) => {
  const { displayedItems, shownCount, pageError } = props

  //   Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  const history = useHistory()
  const { pathname } = props

  const validateResults = () => {

    // If loop -> If any error encountered
    // |-> condition 01 & 02: some filter condition not met and "content" node is present
    // |-> condition 03: response doesn't have "content" node
    // else loop -> It is for the first time were user haven't created any items before
    if (
      (displayedItems.length === 0 && shownCount !== 0) ||
      (displayedItems.length === 0 && shownCount === 0) ||
      pageError
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={'fta-no-results--wrapper'}>
      <img
        src={
          process.env.PUBLIC_URL + '/static/images/noResult/no-items-found.svg'
        }
        alt={CommonFtaTranslatedTextsObject.noItemsYet}
      ></img>
      <Typography
        variant='h5'
        style={{ fontWeight: 'bold', paddingTop: '10px' }}
      >
        {CommonFtaTranslatedTextsObject.noItemsFound}
      </Typography>
    </div>
  )
}

export default FtaListNoResult
