import React, { useContext } from 'react'
import {
  Box,
  Chip,
  Typography,
  makeStyles
} from '@material-ui/core'
import { ProductContext } from '../ProductPage'

export { TableToolbar } from './TableToolbar'
export { TableBody } from './TableBody'
export { TableFooter } from './TableFooter'
export { TableFilters } from './TableFilters'
export { Field } from './Field'
export { CompanyInfo } from './CompanyInfo'
export { FormActions } from './FormActions'
export { FormCard } from './FormCard'
export { SearchDialog } from './SearchDialog'
export { DeleteDialog } from './DeleteDialog'
export { LTSDFilter, AttachedFilter } from './CustomFilters'
export { EditCell } from './EditCell'
export { CombinedQty } from './CombinedQty'
export { DocumentInfoForm } from './documents'

const useStyles = makeStyles(theme => ({
  chip: {
    fontWeight: theme.typography.fontWeightBold,
    borderColor: theme.palette.color.lightGrey,
    fontSize: '.75rem',
    padding: theme.spacing(.25)
  }
}))

export function SectionHeader({ title, children, ...props }) {
  const { editing } = useContext(ProductContext)

  return <Box mb={1.5} {...props}>
    <Typography variant="h4" style={{ fontWeight: 'bold' }}>
      {title}
    </Typography>
    {editing && <Box component={Typography}
      variant="caption" color="text.textSecondary"
    >
      {children}
    </Box>}
  </Box>
}

export function CodeDisplay({ chip, children, className, ...props }) {
  const classes = useStyles()
  return <Box display='flex' alignItems="center" {...props}>
    <Box component={Chip}
      className={classes.chip} label={chip}
      variant="outlined" size="small"
      mr={1}
    />
    {children}
  </Box>
}

export function TwoLines({ sub, title, children }) {
  return <Box>
    <Box>
      {title}{children}
    </Box>
    <Box component={Typography} variant="caption" color="text.textSecondary">
      {sub}
    </Box>
  </Box>
}

export { useStyles as useComponentStyles }
