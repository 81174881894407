
import React from 'react'
import { components, } from 'cng-web-lib'

import RulesOfOriginListCardDetailComponent from './RulesOfOriginListCardDetailComponent'
import RulesOfOriginSPDetails from "./RulesOfOriginSPDetails";
import RulesOfOriginDeminisDetails from './RulesOfOriginDeminimisDetails'




function RulesOfOriginListSeperator({ product, idx, handleSetQualificationDate, props, productStatusCode }) {
  if (product.spCriterionHolder != null) {
    return (
      <>
        <RulesOfOriginSPDetails.FormBody
          product={product}
          dataIndex={idx}
          {...props}
          handleSetQualificationDate={handleSetQualificationDate}
          productStatusCode={productStatusCode}
          key={idx}
        />
      </>
    )
  } else if (product.deminimisRooHolder != null) {
    return (

      <>
        <RulesOfOriginDeminisDetails.FormBody
          product={product}
          dataIndex={idx}
          {...props}
          handleSetQualificationDate={handleSetQualificationDate}
          productStatusCode={productStatusCode}
          key={idx}
        />
      </>
    )
  } else {
    return (
      <>
        <RulesOfOriginListCardDetailComponent.FormBody
          product={product}
          dataIndex={idx}
          {...props}
          handleSetQualificationDate={handleSetQualificationDate}
          productStatusCode={productStatusCode}
        />
      </>
    )
  }


}


export default RulesOfOriginListSeperator
