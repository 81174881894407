import React, { useContext, useState } from 'react'
import {
  Box,
  Tooltip,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { components, useServices } from 'cng-web-lib'
import { useSnackbar } from 'notistack'
import { useFormContext, useFormState } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'

import pathMap from 'src/paths/PathMap_TradeManagement'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'

import {
  COMPANY_REF_INIT, DUPE_KEY,
  parseFormData, parseSubmission
} from '../helpers'
import { ProductContext } from '../ProductPage'
import { DeleteDialog } from '.'

const {
  button: { CngButton }
} = components

function FormActions() {
  const history = useHistory()
  const { pathname } = useLocation()
  const { createRecord, securedSendRequest } = useServices()
  const { reset, getValues, handleSubmit } = useFormContext()
  const { errors } = useFormState()
  const { enqueueSnackbar } = useSnackbar()
  const [data] = useState({})

  const {
    editing, setEditing,
    loading, creating,
    submitting, setSubmitting
  } = useContext(ProductContext)

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  // view controls
  const handleDiscard = () => {
    reset()
    if (creating)
      history.goBack()
    setEditing(false)
  }

  const handleDuplicate = () => {
    localStorage.setItem(DUPE_KEY, btoa(JSON.stringify(getValues())))
    history.push(`${pathMap.MY_PRODUCT_FTA}/new`)
  }

  //upload function 
  function uploadDocument(respId, docData) {
    const formData = new FormData();
    docData.pendingSupportDocuments.map((file, i) => {
      const fileBlob = file.file
      formData.append("fileContents", fileBlob)
      formData.append("productId", respId)
    })

    function onSuccess(response) {
      if (response.length > 0 && response.errorMessages != null) {
        console.log('error >> ')
        enqueueSnackbar(data.errorDetails.map(e => e.errorMessage), { variant: 'error' })
      } else {
        let supportDocuments = docData.supportDocuments || []
        if (response && response.length > 0) {
          response.map((file, i) => {
            let supportDoc = {
              fileName: file.fileName,
              id: file.id,
              fileSize: file.fileSize,
            }
            supportDocuments.push(supportDoc)
          })
        }
        data.supportDocs = supportDocuments;
        enqueueSnackbar('Document save succesfully.', { variant: 'success' })
      }
    }


    createRecord.execute(
      TradeMgmtApiUrls.DOCUMENT_UPLOAD,
      formData,
      onSuccess
    )
  }
  // editing controls
  const handleSave = async (a, b, c) => {
    let subm = parseSubmission(getValues(), [
      "hsMasterId", "materialSkuNo", "productDescription",
      "model", "baseQuantity", "uomId",
      "countryOfManufacture", "countryOfManufactureCode", "exworkPrice",
      "fobPrice", "transactionValue", "netCosts", "totalCosts",
      "currency", "companyId", "pendingSupportDocuments"
    ])

    subm.bom = parseSubmission(getValues('bomDetail'), [
      "bomName", "profit", "otherCost",
      "directLaborCost", "directOverheadCost"
    ])
    subm.bom.components = (getValues('REF_components') || [])
      .filter(x => x.attached && x.quantity)
      .map(x => ({
        componentId: x.id,
        quantity: parseFloat(x.quantity)
      }))

    if (creating) {
      createRecord.execute(
        TradeMgmtApiUrls.CREATE_PRODUCT,
        subm,
        res => {
          if (res.status === 200 && res.results && res.results.id) {
            enqueueSnackbar('New product created succesfully.', { variant: 'success' })
            history.push(`${pathMap.MY_PRODUCT_FTA}/${res.results.id}`)
            if (subm.pendingSupportDocuments)
              uploadDocument(res.results.id, subm);
          } else
            throw res
        },
        ({ response: { data } }) => {
          enqueueSnackbar(data.errorDetails.map(e => e.errorMessage), { variant: 'error' })
        },
        () => setSubmitting(false)
      )
    } else {
      let productId = getValues('id')
      securedSendRequest.execute("POST",
        TradeMgmtApiUrls.UPDATE_PRODUCT,
        { ...subm, productId },
        res => {
          if (res.status === 200) {
            enqueueSnackbar(`Product ${getValues('materialSkuNo')} succesfully edited.`, { variant: 'success' })
            history.push(pathMap.MY_PRODUCT_FTA)
            console.log(res.data.results.id)
            console.log(subm)
            if (subm.pendingSupportDocuments)
              uploadDocument(res.data.results.id, subm);
          } else
            throw res
        },
        res => {
          console.log('err', res);
          enqueueSnackbar('An error occurred.', { variant: 'error' })
        },
        () => setSubmitting(false)
      )
    }
  }
  const handleError = errors => {
    if (errors.hasOwnProperty('REF_components'))
      enqueueSnackbar("Please check components in BOM.", { variant: 'error' })
  }


  // editing controls
  if (editing)
    return <>
      <CngButton size="small" color="secondary"
        onClick={handleDiscard}
      >
        <Box fontWeight="fontWeightBold" px={1}>Discard</Box>
      </CngButton>
      <CngButton size="small"
        disabled={submitting || Object.keys(errors).length > 0}
        startIcon={submitting && <FontAwesomeIcon
          icon={['fal', 'spinner-third']}
          spin
        />}
        onClick={handleSubmit(handleSave, handleError)}
      >
        <Box fontWeight="fontWeightBold" px={1}>Save &amp; close</Box>
      </CngButton>
    </>

  // view controls
  return <>
    <CngButton size="small" color="secondary"
      onClick={() => history.goBack()}
    >
      <Box fontWeight="fontWeightBold" px={1}>Back to my product listing</Box>
    </CngButton>
    <Box>
      <CngButton size="small" variant="outlined" color="inherit"
        startIcon={<FontAwesomeIcon icon={['fal', 'trash']} />}
        onClick={() => setDeleteDialogOpen(true)}
      >
        <Box fontWeight="fontWeightBold">Delete</Box>
      </CngButton>
    </Box>
    <CngButton size="small" variant="outlined" color="primary"
      startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
      onClick={handleDuplicate}
    >
      <Box fontWeight="fontWeightBold">Duplicate</Box>
    </CngButton>
    <Box>
      <CngButton size="small" variant="outlined" color="primary"
        startIcon={<FontAwesomeIcon icon={['fal', 'pen-to-square']} />}
        onClick={() => setEditing(true)}
      >
        <Box fontWeight="fontWeightBold">Edit</Box>
      </CngButton>
    </Box>


    <DeleteDialog open={deleteDialogOpen != false}
      onClose={() => setDeleteDialogOpen(false)}
      onComplete={() => history.goBack()}
      id={getValues('id')}
      desc={getValues('materialSkuProductDescription')}
    />
  </>
}

export { FormActions }
