import React, {useEffect, useState} from 'react'
import { components, constants, Yup, useServices } from 'cng-web-lib'
import {Box, DialogContentText, FormGroup, Grid, Paper} from "@mui/material";
import TradeMgmtApiUrls from "../../../apiUrls/TradeMgmtApiUrls";
import OriginCalculationUserSelectionCheckBox from "./OriginCalculationUserSelectionCheckBox";
import {makeStyles} from "@material-ui/core";

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        CngAddForm,
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        CngForm,
        field: {
            CngTextField,
            CngCheckboxField
        },
        CngEditFormButtonSection,
    },
    table: { useDefaultNotification },
    button: { CngPrimaryButton, CancelButton, CngSecondaryButton },
    CngGridItem,
} = components

const useStyles = makeStyles((theme) => ({
    popupCustomText1: {
        fontWeight: 'bolder',
        fontSize: 'medium'
    },
    popupCustomText2: {
        fontWeight: 'normal',
        fontSize: 'smaller'
    },
    popupUncheckAllButton: {
        textAlign: 'right',
        alignItems: 'end'
    }
}))

const initialValues = Object.freeze({
    testTextBox: "",
    originCalculationSelection: [],
})

const validationSchema = Yup.object({
    carrierRefNo: Yup.string()
})

function OriginCalculationSPForm(props) {

    const classes = useStyles()

    const { spStatus, setSpStatus, spCriterionHolder, setIsDialogOpen } = props

    const [originCalSelection, setOriginCalSelection] = useState(spCriterionHolder.userSelections);

    const [uncheckAll, setUncheckAll] = useState(false);

    const [checkBoxSectionKey, setCheckBoxSectionKey] = useState(1);

    const { fetchRecords } = useServices();

    useEffect(() => {
        setUncheckAll(false)
    },[])

    const handleUncheckAll = () => {
        setUncheckAll(true)
        setCheckBoxSectionKey(checkBoxSectionKey + 1)
    };

    function onSubmit(data) {
        spCriterionHolder.userSelections = []
        spCriterionHolder.userSelections.push(...originCalSelection);

        if(uncheckAll) {
            spCriterionHolder.userSelections = []
        }

        const onSuccess = (response) => {
            setSpStatus(response?.spCriterionHolder?.spStatus);
            setIsDialogOpen(false)
        }

        const onError = (error) => {
            console.log("Error in push origin selection:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => { console.log("origin selection calculations pushed..") }

        fetchRecords.execute(
            TradeMgmtApiUrls.SET_ORIGIN_SELECTION,
            {
                customData: {spCriterionHolder: spCriterionHolder},
                filters: []
            },
            onSuccess,
            onError,
            onComplete
        )

    }

    function FormBody() {
        return <Box>
            <Grid container spacing={0}>
                <CngGridItem item xs={9}>
                    <Grid container spacing={0}>
                        <CngGridItem item xs={12} className={classes.popupCustomText1}>
                            Product specific rule {props.spCriterionHolder?.userSelections.length} out of {props.spCriterionHolder?.criterionEntityList?.length}
                        </CngGridItem>
                        <CngGridItem item xs={12} className={classes.popupCustomText2}>
                            Please select all that apply
                        </CngGridItem>
                    </Grid>
                </CngGridItem>
                <CngGridItem item xs={3} className={classes.popupUncheckAllButton}>
                    <CngSecondaryButton onClick={handleUncheckAll}>Uncheck all</CngSecondaryButton>
                </CngGridItem>
                <CngGridItem item xs={12}>
                    <CngSimpleCardHeader title={"Tariff code ["+spCriterionHolder?.hsCode+"]"}/>
                </CngGridItem>
                <CngGridItem item xs={12} key={checkBoxSectionKey}>
                    {
                        spCriterionHolder?.criterionEntityList.map((data, index) => {
                            return <OriginCalculationUserSelectionCheckBox data={data} index={index} originCalSelection={originCalSelection} setOriginCalSelection={setOriginCalSelection} uncheckAll={uncheckAll} setUncheckAll={setUncheckAll} setCheckBoxSectionKey={setCheckBoxSectionKey} checkBoxSectionKey={checkBoxSectionKey} />
                        })
                    }
                </CngGridItem>
            </Grid>
            </Box>
    }

    return (
        <CngAddForm
            formikProps={{
                initialValues: {
                    ...initialValues
                },
                validationSchema: validationSchema,
                onSubmit: onSubmit,
                validateOnChange: false
            }}
            bodySection={
                <FormBody />
            }
            renderButtonSection={ () => {
                return (
                    <Grid container>
                        <Grid item xs={6}>
                            <CngSecondaryButton
                                style={{paddingLeft: '2em', paddingRight: '2em'}}
                                onClick={(e) => {setIsDialogOpen(false)}}
                            >
                                Discard
                            </CngSecondaryButton>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <CngPrimaryButton
                                style={{paddingLeft: '2em', paddingRight: '2em',}}
                                type={"submit"}
                            >
                                Save
                            </CngPrimaryButton>
                        </Grid>
                    </Grid>
                )
            }}
        />
    )

}

export default OriginCalculationSPForm;