import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useServices } from 'cng-web-lib'


import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'

export function useBOMComponents() {
  const { fetchRecords } = useServices()
  const { getValues, setValue } = useFormContext()
  const [loading, setLoading] = useState(false)

  const getComponentDetail = isSubscribed => {
    if (getValues('bomDetail.componentDetail')) {
      setLoading(true)

      fetchRecords.execute(TradeMgmtApiUrls.GET_COMPONENT, {}, res => {
        if (res && res.content && true) {
          let cont = res.content
          getValues('bomDetail.componentDetail').forEach(({ id, quantity }) => {
            let match = cont.findIndex(x => x.id === id)
            if (match > -1)
              cont[match] = {
                ...cont[match],
                attached: true,
                quantity,
                componentId: id
              }
          })
          if (isSubscribed)
            setValue('REF_components', cont)
        }
      }, () => null, () => setLoading(false))
    }
  }
  useEffect(() => {
    let isSubscribed = true
    getComponentDetail(isSubscribed)

    return () => {
      setLoading(false)
      isSubscribed = false
    }
  }, [getValues('bomDetail.componentDetail')])


  return { loading, getComponentDetail }
}
