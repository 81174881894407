import React, { useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import {
  Paper,
  Box,
  useTheme,
  makeStyles
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import InfoDialog from '../components/InfoDialog'
import { useFormContext } from 'react-hook-form'

import FTACalculatorForm from './FTACalculatorForm'
import FTACalculatorValidationSchema from './FTACalculatorValidationSchema'
import FTACalculatorTabContent from './FTACalculatorTabContent'
import VersionLogsTabContent from './VersionLogsTabContent'
import { Popover } from '@material-ui/core'




const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    fontSize: '1em',
    display: 'flex',
    alignItems: 'center',
    color: '#8996AF'
  },
  body: {
    padding: theme.spacing(3),
    fontSize: '1em',
    display: 'block',
    alignItems: 'center'
  },
  title: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0,
    '& > svg': { margin: theme.spacing(0, 3) }
  },
  exportBtn: {
    margin: theme.spacing(0.5)
  },
  tabs: {
    margin: theme.spacing(1, 0)
  },
  listItemAvatar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  listItemEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bottomBtn: {
    padding: theme.spacing(1.25),
    fontSize: '1.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonTransform: {
    textTransform: 'none'
  }
}))

function FTACalculatorPage({ history, showNotification }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopOverClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const popOverOpen = Boolean(anchorEl)
  const popOverId = popOverOpen ? 'simple-popover' : undefined

  return (
    <>
      <Box>
        <Paper>
          <FTACalculatorTabContent
            history={history}
            showNotification={showNotification}
          />
        </Paper>
      </Box>
    </>
  )
}

export default FTACalculatorPage
