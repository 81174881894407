import React from "react";
import {Box, Card, CardContent, Grid, LinearProgress, makeStyles} from '@material-ui/core'
import {components, useTranslation} from 'cng-web-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import Namespace from "../../../../constants/locale/Namespace"
import TmpDeniedPartySearchKeys from "../../../../constants/locale/key/TmpDeniedPartySearch"


const {
    form: {
        field: {
            CngTextField,
            CngSelectField
        }
    },
    button: { CngPrimaryButton }
} = components

const useStyles = makeStyles(theme => ({
    hideHints: {
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      }
    },
    btnSide: {
        height: '5.5vh',
        width:'100%'
    }
}))

export default function SearchBar( {search, handleSearchName, type, handleType, isLoading}) {
    const {translate} = useTranslation(Namespace.TMP_DPS)
    const classes = useStyles()
    const selectItem = makeTranslatedSelectItem()
    const searchItem = makeTranslatedSearchBarItem()

    function makeTranslatedSelectItem() {
        let typeAll = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.TYPE_ALL)
        let typeEntity = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.TYPE_ENTITY)
        let typeIndividual = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.TYPE_INDIVIDUAL)
        let typeVessel = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.TYPE_VESSEL)

        return {
            typeAll,
            typeEntity,
            typeIndividual,
            typeVessel
        }
    }

    function makeTranslatedSearchBarItem() {
        let fieldType = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.FIELD_TYPE)
        let fieldSearchName = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.FIELD_SEARCHNAME)
        let buttonSearch = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.BTN_SEARCH)

        return {
            fieldType,
            fieldSearchName,
            buttonSearch
        }
    }


    const TYPE_LIST = [
        {text: selectItem.typeAll, value: 'all'},
        {text: selectItem.typeEntity, value: 'entity'},
        {text: selectItem.typeIndividual, value: 'individual'},
        {text: selectItem.typeVessel, value: 'vessel'},
    ]

    return (
        <Card variant="outlined">
            <Box component={CardContent} mb={-1}>
                <Grid container
                    wrap="nowrap"
                    spacing={1}
                    alignItems="center"
                    className={classes.hideHints}
                >
                    <Grid item xs={2}>
                        <CngSelectField
                            label={searchItem.fieldType}
                            name='type'
                            items={TYPE_LIST}
                            value={type}
                            onChange={e => (handleType(e.target.value))}
                        />
                    </Grid>
                    <Grid item xs={true}>
                        <CngTextField
                            required
                            label={searchItem.fieldSearchName}
                            name='searchName'
                            value={search}
                            onChange={e => (handleSearchName(e.target.value))}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <CngPrimaryButton 
                            size={'large'}
                            className={classes.btnSide}
                            startIcon={<FontAwesomeIcon icon={['fal', 'search']} fixedWidth size='sm'/>}
                            type={'submit'}
                            disabled={isLoading}
                        >
                        {searchItem.buttonSearch}
                        </CngPrimaryButton>
                    </Grid>
                </Grid>
            </Box>
            {isLoading && <LinearProgress />}
        </Card>
    )
}