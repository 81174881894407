import React, { useEffect, useState } from 'react'
import { components, Yup, useServices } from 'cng-web-lib'
import { Box, Grid } from "@mui/material";
import TradeMgmtApiUrls from "../../../apiUrls/TradeMgmtApiUrls";
import OriginCalculationUserSelectionCheckBox from "./OriginCalculationUserSelectionCheckBox";
import { makeStyles } from "@material-ui/core";

const {
    form: {
        CngAddForm,
    },
    button: { CngPrimaryButton, CngSecondaryButton },
    CngGridItem,
} = components

const useStyles = makeStyles((theme) => ({
    popupCustomText1: {
        fontWeight: 'bolder',
        fontSize: 'medium'
    },
    popupCustomText2: {
        fontWeight: 'normal',
        fontSize: 'smaller'
    },
    popupUncheckAllButton: {
        textAlign: 'right',
        alignItems: 'end'
    }
}))

const initialValues = Object.freeze({
    testTextBox: "",
    originCalculationSelection: [],
})

const validationSchema = Yup.object({
    carrierRefNo: Yup.string()
})


const FormBody = (props) => {

    const classes = useStyles()
    const { deminimisRooHolder, handleUncheckAll, originCalSelection, setOriginCalSelection, uncheckAll, setUncheckAll, checkBoxSectionKey, setCheckBoxSectionKey } = props

    return <Box>
        <Grid container spacing={0}>
            <CngGridItem item xs={9}>
                <Grid container spacing={0}>
                    <CngGridItem item xs={12} className={classes.popupCustomText1}>
                        Product specific rule {deminimisRooHolder?.userSelections.length} out of {deminimisRooHolder?.deminimisEntityList?.length}
                    </CngGridItem>
                    <CngGridItem item xs={12} className={classes.popupCustomText2}>
                        Please select all that apply
                    </CngGridItem>
                </Grid>
            </CngGridItem>
            <CngGridItem item xs={3} className={classes.popupUncheckAllButton}>
                <CngSecondaryButton onClick={handleUncheckAll}>Uncheck all</CngSecondaryButton>
            </CngGridItem>
            <CngGridItem item xs={12} key={checkBoxSectionKey}>
                {
                    deminimisRooHolder?.deminimisEntityList.map((data, index) => {
                        return <OriginCalculationUserSelectionCheckBox
                            key={data?.id} 
                            data={data} 
                            index={index}
                            originCalSelection={originCalSelection}
                            setOriginCalSelection={setOriginCalSelection}
                            uncheckAll={uncheckAll}
                            setUncheckAll={setUncheckAll}
                            setCheckBoxSectionKey={setCheckBoxSectionKey}
                            checkBoxSectionKey={checkBoxSectionKey}
                        />
                    })
                }
            </CngGridItem>
        </Grid>
    </Box>
}



function OriginCalculationDeminimisForm(props) {

    const { setDeminimisStatus, deminimisRooHolder, setIsDialogOpen } = props
    const [originCalSelection, setOriginCalSelection] = useState(deminimisRooHolder.userSelections);
    const [uncheckAll, setUncheckAll] = useState(false);
    const [checkBoxSectionKey, setCheckBoxSectionKey] = useState(1);
    const { fetchRecords } = useServices();

    useEffect(() => {
        setUncheckAll(false)
    }, [])

    const handleUncheckAll = () => {
        setUncheckAll(true)
        setCheckBoxSectionKey(checkBoxSectionKey + 1)
    }

    function onSubmit(data) {
        deminimisRooHolder.userSelections = []
        deminimisRooHolder.userSelections.push(...originCalSelection);

        if (uncheckAll) {
            deminimisRooHolder.userSelections = []
        }

        const onSuccess = (response) => {
            setDeminimisStatus(response?.deminimisRooHolder?.deminimisStatus);
            setIsDialogOpen(false)
        }

        const onError = (error) => {
            console.log("Error in push origin selection:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => { console.log("origin selection calculations pushed..") }

        fetchRecords.execute(
            TradeMgmtApiUrls.SET_DEMINIMIS_SELECTION,
            {
                customData: { deminimisRooHolder: deminimisRooHolder },
                filters: []
            },
            onSuccess,
            onError,
            onComplete
        )

    }

    return (
        <CngAddForm
            formikProps={{
                initialValues: {
                    ...initialValues
                },
                validationSchema: validationSchema,
                onSubmit: onSubmit,
                validateOnChange: false
            }}
            bodySection={
                <FormBody
                    deminimisRooHolder={deminimisRooHolder}
                    handleUncheckAll={handleUncheckAll}
                    originCalSelection={originCalSelection}
                    setOriginCalSelection={setOriginCalSelection}
                    uncheckAll={uncheckAll}
                    setUncheckAll={setUncheckAll}
                    checkBoxSectionKey={checkBoxSectionKey}
                    setCheckBoxSectionKey={setCheckBoxSectionKey}
                />
            }
            renderButtonSection={() => {
                return (
                    <Grid container>
                        <Grid item xs={6}>
                            <CngSecondaryButton
                                style={{ paddingLeft: '2em', paddingRight: '2em' }}
                                onClick={(e) => { setIsDialogOpen(false) }}
                            >
                                Discard
                            </CngSecondaryButton>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <CngPrimaryButton
                                style={{ paddingLeft: '2em', paddingRight: '2em', }}
                                type={"submit"}
                            >
                                Save
                            </CngPrimaryButton>
                        </Grid>
                    </Grid>
                )
            }}
        />
    )

}

export default OriginCalculationDeminimisForm;