import { Chip, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngMoreActionsMenu } = components

const moreActionsList = [
  {
    action: 'delete',
    name: 'Delete activity',
    icon: ['fal', 'trash-can']
  }
]

const FtaActivityHistoryListCardDetailTop = ({ ...props }) => {
  const { product, statusColorCodes } = props

  const {
    ftaActivityHistoryId,
    updatedDate,
    updatedBy,
    statusCode
  } = product

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()


  const handleMoreAction = (data, action) => {
    switch (action) {
      case 'delete':
        console.log('From card top ::', 'delete activity', ftaActivityHistoryId)
        props.deleteActivityHistory(ftaActivityHistoryId)
        break
    }
  }

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  // Find status color codes
  const statusNameStr = (statusCode) => {
    return statusColorCodes.find((b) => b.statusCode === statusCode)?.statusName
  }

  const statusColorCode = (statusCode) => {
    return statusColorCodes.find((b) => b.statusCode === statusCode)?.color
  }

  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={4}>
          <div className={`cng-summary-card--header-box--left`}>

            {statusCode == 'DELE' && (
              // <div className={`header-box--left-col-2`}>
              <div className={`header-box--left-col-1 mr-10`}>
                <Chip
                  label={
                    <Typography className={`font-mediumBold font-16`}>
                      {statusNameStr(statusCode).toUpperCase()}
                    </Typography>
                  }
                  style={{ backgroundColor: statusColorCode(statusCode) }}
                />
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1 text-right-align box-col`}>
              <div className={`field-vertical--label-value`}>
                <Typography className={'frb-card-label-large-grey'}>
                  {FtaTranslatedTextsObject.lastModifiedOn}
                  <b className='ml-5'>
                    {updatedDate !== null ? formatDate(updatedDate) : '-'}
                    {" "}by {" "}
                    {updatedBy !== null ? updatedBy : '-'}
                  </b>
                </Typography>
              </div>
            </div>

            {statusCode !== 'DELE' && (
              <div className={`header-box--right-col-2 cta-box box-col`}>
                <div className={`cng-summary-card--more-actions-wrapper`}>
                  <CngMoreActionsMenu
                    type='primary'
                    size='large'
                    style={{ color: '#7680a3' }}
                    menuItems={moreActionsList}
                    iconDirection='horizontal'
                    data={null}
                    onActionSelect={handleMoreAction}
                  />
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default FtaActivityHistoryListCardDetailTop
