import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import ProductDetailsFormProperties from './ProductDetailsFormProperties'
import { Yup } from 'cng-web-lib'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'

const {
  form: { CngViewForm }
} = components

function ProductDetailsPage({ showNotification, ...props }) {
  const history = useHistory()
  const location = useLocation()
  const {
    location: { pathname }
  } = props
  const historyData = location.state

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  function onSubmit(data) {
    // console.log('View product details :: onSubmit ::\n', data)
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ProductDetailsFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          pathname={pathname}
        />
      }
      formikProps={{
        // initialValues: {},
        ...ProductDetailsFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    />
  )
}

export default ProductDetailsPage
