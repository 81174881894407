import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  FormLabel
} from '@material-ui/core'

import RelatedBoMListCardDetailTop from './RelatedBoMListCardDetailTop'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { withStyles } from '@material-ui/core/styles'
import { generatePath, useHistory } from 'react-router-dom'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const InfoIconButton = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(IconButton)

const BulkParcelIconLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.color.primaryColor
  }
}))(FormLabel)

const FormBody = (props) => {
  const history = useHistory()
  const {
    ftaRelatedBomId,
    componentNo,
    componentName,
    customTariffNo,
    quantity,
    uom,
    uomName,
    materialType,
    standardPricePerUom,
    supplierManufactureName,
    ltsdStatus,
    ltsdStartDate,
    ltsdEndDate,
    nonOriginatingValue,
    originatingValue,
    originCountry,
    originCountryCode
  } = props.product

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()


  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const handleViewComponentDetails = (ftaRelatedBomId, componentNo) => {
    console.log(
      'View component details ::\nftaRelatedBomId, componentNo ::',
      ftaRelatedBomId,
      ',',
      componentNo
    )
  }

  return (
    <Card className={`summary-list-item--card`}>
      {/* Card header */}
      <RelatedBoMListCardDetailTop
        componentNo={componentNo}
        customTariffNo={customTariffNo}
      />

      {/* Card body */}
      <CardActionArea
        className={`cng-summary-card--body-box`}
        onClick={() => handleViewComponentDetails(ftaRelatedBomId, componentNo)}
      >
        <Grid container className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={12} sm={3}>
            <div
              className={`field-vertical--label-value`}
              style={{ marginInlineStart: '12px' }}
            >
              <span className={`field-vertical--item`}>
                <label className={`field-value font-16`}>{componentName}</label>
              </span>

              <span className={`field-vertical--item`}>
                <label
                  className={`field-label clamp-line-to-two font-14 pr-10`}
                >
                  {supplierManufactureName}
                </label>
              </span>
            </div>
          </CngGridItem>

          <CngGridItem xs={12} sm={9}>
            <Grid container>
              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.countryOrigin}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      <div className={`field-country-details`}>
                        {originCountryCode != '' ? (
                          <img
                            className={`field-country-flag`}
                            alt='Flag'
                            src={`${process.env.PUBLIC_URL
                              }/static/images/countries/${originCountryCode.substring(
                                0,
                                2
                              )}.svg`}
                          ></img>
                        ) : (
                          <img
                            className={`field-country-flag`}
                            alt='Flag'
                            src={`${process.env.PUBLIC_URL}/static/images/countries/genericCountry.svg`}
                          ></img>
                        )}

                        {originCountry !== null ? originCountry : `-`}
                      </div>
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.materialType}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {materialType !== null ? materialType : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.nonOriginatingValue}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {nonOriginatingValue !== null ? nonOriginatingValue : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.unitOfMeasurement}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      <Chip
                        label={<b style={{ fontSize: 10 }}>{uom !== null ? uom : `-`}</b>}
                        size='small'
                        style={{ marginLeft: 5 }}
                        className={`field-country-code`}
                      />
                      
                      {uomName !== null ? uomName : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.ltsdStatus}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {ltsdStatus !== null ? ltsdStatus : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.originatingValue}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {originatingValue !== null ? originatingValue : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.unit}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {quantity !== null ? quantity : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.ltsdStartEndDate}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {ltsdStartDate !== null ? formatDate(ltsdStartDate) : `-`}
                      {` - `}
                      {ltsdEndDate !== null ? formatDate(ltsdEndDate) : `-`}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <div className={`field-vertical--label-value`}>
                  <span className={`field-vertical--item`}>
                    <label className={`field-label`}>
                      {FtaTranslatedTextsObject.standardPricePerUOM}
                    </label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-value`}>
                      {standardPricePerUom !== null ? standardPricePerUom : `-`}
                      <Chip
                        label={<b style={{ fontSize: 10 }}>{`/${uom !== null ? uom : `-`}`}</b>}
                        size='small'
                        style={{ marginLeft: 5 }}
                        className={`field-country-code`}
                      />
                    </label>
                  </span>
                </div>
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

const RelatedBoMListCardDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default RelatedBoMListCardDetailComponent
