import React, { useEffect, useState, useMemo } from 'react'
import {
  Box,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { useSnackbar } from 'notistack'

import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'

const {
  button: { CngPrimaryButton, CngButton },
  CngDialog
} = components

function DeleteDialog({open, id, desc, ...props}) {
  const { deleteRecord } = useServices()
  const { enqueueSnackbar } = useSnackbar()
  const [deleting, setDeleting] = useState(false)

  const handleDelete = () => {
    setDeleting(true)
    deleteRecord.execute(
      TradeMgmtApiUrls.DELETE_PRODUCT + `/${id}`,
      null,
      res => {
        if (res.status === 200)
          enqueueSnackbar(
            `Succesfully deleted: ${desc}`,
            { variant: 'success' }
          )
      }, () => null, () => {
        props.onComplete()
        setDeleting(false)
        props.onClose()
      }
    )
  }

  const handleClose = (e, reason) => {
    if (reason && deleting) {
      e.preventDefault(); e.stopPropagation()
    } else
      props.onClose()
  }



  return <CngDialog
    open={open}
    dialogTitle="Confirm deletion"
    shouldShowCloseButton
    fullWidth maxWidth="xs"
    dialogContent="Deleted items cannot be restored. Are you sure?"
    onClose={handleClose}
    dialogAction={<>
      <CngButton color="secondary" onClick={props.onClose}>
        No, take me back
      </CngButton>
      <CngButton onClick={handleDelete}>
        Yes, delete
      </CngButton>
    </>}
  />
}

export { DeleteDialog }
