import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from '../../../constants/locale/Namespace'
import TmpDeniedPartySearchKeys from '../../../constants/locale/key/TmpDeniedPartySearch'
import BreadcrumbNameMap from '../../../navigations/breadcrumbs/Breadcrumb_TradeManagement'
import SearchPage from './SearchPage'

function ModuleTitle(){
    const {translate} = useTranslation(Namespace.TMP_DPS)
    let searchtitle = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.TITLE_PARTYSEARCH)
    return (searchtitle);
}

function WrappedSearchPage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage = {(showSnackbar) => (
            //Content
            <SearchPage />
        )}
            extraCngBreadcrumbNameMap = {breadcrumbNameMap}
            ModuleTitle={ModuleTitle()}
        />
    )
}

export {
    WrappedSearchPage as SearchPage
}