// import React from 'react'
import { DocumentComponent, DocumentDetails } from '.'
import { components, useServices } from 'cng-web-lib'
import { useSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'

// Moment library
import Moment from 'moment';
import 'moment-timezone';
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  }

} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  ...DocumentComponent.initialValues,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

function _formatDate(timestamp, timezone, format) {
  let moment = Moment(timestamp)

  if (timezone) {
    moment.tz(timezone)
  }

  return (moment.format(format))
}

function DocumentInfoForm({ history, showNotification, loading, id }) {
  const { securedSendRequest } = useServices()
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState([])

  const removeRow = idx => {
    // delete api

    securedSendRequest.execute("DELETE",
      `${TradeMgmtApiUrls.DOCUMENT_DELETE}`,
      { id: idx },
      res => {
        if (res.data) {
          setData(retrieveData(id))
          if (res.data.errorMessages)
            throw res.data.errorMessages
        }
      }, err => {
        if (!Array.isArray(err))
          err = [err]
        err.forEach(e => typeof e == String && enqueueSnackbar(e, { variant: 'error' }))
      }

    )

  }


  const downloadRow = idx => {
    // download api

    securedSendRequest.execute("POST",
      `${TradeMgmtApiUrls.DOCUMENT_DOWNLOAD}`,
      { id: idx },
      res => {
        console.log(res.data.content[0].fileUrl)
        if (res.data) {
          if (res.data.errorMessages)
            throw res.data.errorMessages

          window.location.href = res.data.content[0].fileUrl
        }
      }, err => {
        if (!Array.isArray(err))
          err = [err]
        err.forEach(e => typeof e == String && enqueueSnackbar(e, { variant: 'error' }))
      }

    )

  }

  const retrieveData = (id) => {
    securedSendRequest.execute("POST",
      `${TradeMgmtApiUrls.DOCUMENT_GETBYPRODUCTID}`,
      { id },
      res => {
        if (res.data) {
          if (res.data.errorMessages)
            throw res.data.errorMessages
          setData(res.data)

        }
      }, err => {
        if (!Array.isArray(err))
          err = [err]
        err.forEach(e => typeof e == String && enqueueSnackbar(e, { variant: 'error' }))
      }
    )
    return null;
  }

  useEffect(() => {
    setData(retrieveData(id))
  }, [])

  return (
    <>
      <DocumentDetails
        tile={data}
        onRemoveRow={removeRow}
        onDownloadRow={downloadRow}
        id={id}
      />
      <DocumentComponent.FormBody
        id={id}
        showNotification={showNotification}
      />
    </>
  )
}

export default DocumentInfoForm
