import { Box, Paper, Grid, Chip, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CngBackdrop from '../components/CngBackdrop'
import { useFormContext } from 'react-hook-form'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import ProductDetailsParamSummaryHeader from './ProductDetailsParamSummaryHeader'
import CalcResultsListHeaderComponent from './CalcResultsListHeaderComponent'
import CalcResultsListCardComponent from './CalcResultsListCardComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faLacrosseStick } from '@fortawesome/pro-light-svg-icons'
import Utils from 'src/shared/Utils'
import GoBackButton from 'src/components/button/GoBackButton'

const { CngGridItem } = components

const DEFAULT_INITIAL_VALUES = Object.freeze({})

const FORMIK_PROPS = {
  initialValues: { ...ProductDetailsParamSummaryHeader.initialValues }
}

const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06)
  }
}))

function FormFields({ showNotification, ...props }) {
  const { setValue, getValues, handleSubmit } = useFormContext()
  const { fetchPageableRecords, fetchRecords } = useServices()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [version, setVersion] = useState(0)
  const [countryCode, setCountryCode] = useState([])
  const location = useLocation()
  const historyData = location.state
  const classes = useStyles()
  const [currencyCode, setCurrencyCode] = useState(
    historyData?.detailsData.currency || '-'
  )
  const [displayedProductsList, setDisplayedProductsList] = useState(
    historyData?.calculationResults
  )
  const [shownCount, setShownCount] = useState(
    historyData?.calculationResults.length || 0
  )

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  // Prepare html for country name and code
  const appendCountryCodeAndName = (country) => {
    return (
      <>
        {country !== null ? (
          <div className={`field-country-details`}>
            {countryCode
              .filter((codes) => codes.code == country)
              .map((thisCountry, index) => (
                <>
                  <label className={`field-country-name field-value dark`}>
                    {thisCountry.descriptionEn}
                  </label>
                  <Chip
                    label={<b style={{ fontSize: 10 }}>{thisCountry.code}</b>}
                    size='small'
                    style={{ marginLeft: 5 }}
                    className={`field-country-code`}
                  />
                </>
              ))}
          </div>
        ) : (
          `-`
        )}
      </>
    )
  }

  return (
    <Box className={`cng-details-page`}>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box
          p={2}
          className={`cng-details-page__content-container edge-to-edge ${classes.sectionBlueBg}`}
        >
          <ProductDetailsParamSummaryHeader.FormBody
            disabled={false}
            paramsData={historyData?.detailsData || {}}
            isRoutefromCalcScreen={historyData?.isRoutefromCalcScreen || false}
            productStatusCode={historyData?.productStatusCode || ""}
          />

          <CalcResultsListHeaderComponent.FormBody
            shownCount={shownCount}
          // handleSearchByChange={handleSearchByChange}
          />

          <CalcResultsListCardComponent.FormBody
            displayedProductsList={displayedProductsList}
            currencyCode={currencyCode}
            paramsData={historyData?.detailsData || {}}
            detailsData={historyData?.detailsData || {}}
            calculationResults={historyData?.calculationResults || {}}
            ftaActivityHistoryId={historyData?.ftaActivityHistoryId || null}
            isRoutefromCalcScreen={historyData?.isRoutefromCalcScreen || false}
            productStatusCode={historyData?.productStatusCode || ""}
          />
        </Box>
      </Paper>
    </Box>
  )
}

const ProductDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default ProductDetailsFormProperties
