import React, { useCallback, useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import moment from 'moment'
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  withStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  table: {
    marginTop: 0,
    minWidth: 900,
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.grey[100],
      '& .MuiTableCell-head': {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        padding: '12px 16px',
        textTransform: 'uppercase',
        '& .MuiTypography-root': {
          cursor: 'default'
        }
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected
        },
        '& .MuiTableCell-root': {
          padding: (props) => (props.compact ? '8px 16px' : '12px 16px'),
          fontSize: (props) => (props.compact ? 14 : 16)
        }
      }
    }
  },
  itemsPerPage: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    '& .MuiSelect-root': {
      fontSize: 14,
      padding: '8px 24px 8px 8px'
    }
  },
  loader: {
    alignItems: 'center',
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: 8,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  },
  headerSortSelect: {
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 700,
      padding: '4px 24px 4px 0'
    }
  },
  checkbox: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 8,
      '& .MuiCheckbox-root': {
        padding: 8
      }
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  form: {
    field: { CngCheckboxField, CngDateField, CngRadioGroupField, CngTextField }
  },
  CngGridItem
} = components

const columns = [
  {
    field: 'place',
    title: `Origin / Destination`,
    render: (data) => (
      <Box>
        <Typography
          component='div'
          variant='inherit'
          style={{ marginBottom: 4 }}
        >

          {`Singapore`}
        </Typography>
        <Typography
          color='textSecondary'
          style={{ fontSize: 12, lineHeight: 1.2 }}
        >

          {`India`}
        </Typography>
      </Box>
    )
  },
  {
    field: 'materialNoSku',
    title: `Material no / SKU`,
    render: (data) => (
      <Box>
        <Typography
          component='div'
          variant='inherit'
          style={{ marginBottom: 4 }}
        >
          {`SKU123`}
        </Typography>
        <Typography
          color='textSecondary'
          style={{ fontSize: 12, lineHeight: 1.2 }}
        >

          {`description`}
        </Typography>
      </Box>
    )
  },
  {
    field: 'hcode',
    title: `HS Code / Description`,
    render: (data) => (
      <Box>
        <Typography
          component='div'
          variant='inherit'
          style={{ marginBottom: 4 }}
        >
          {`1234132`}
        </Typography>
        <Typography
          color='textSecondary'
          style={{ fontSize: 12, lineHeight: 1.2 }}
        >
          {`description`}
        </Typography>
      </Box>
    )
  },
  {
    field: 'pdtDescription',
    title: `Product Description`
  },
  {
    field: 'compRefno',
    title: `Company Ref. No. / Name`,
    render: (data) => (
      <Box>
        <Typography
          component='div'
          variant='inherit'
          style={{ marginBottom: 4 }}
        >
          {`1234132`}
        </Typography>
        <Typography
          color='textSecondary'
          style={{ fontSize: 12, lineHeight: 1.2 }}
        >
          {`description`}
        </Typography>
      </Box>
    )
  },
  {
    field: 'lastModifiedDate',
    title: 'Last modified date',
    render: (data) =>
      data.updatedDate && moment(data.updatedDate).format('D MMM YYYY, HH:mm')
  }
]


const sortConfig = {
  type: 'column',
  defaultDirection: 'ASC'
}

function Table(props) {

  const [data, setData] = useState({
    content: [],
    totalElements: 0,
    totalPages: 0
  })


  const classes = useStyles()

  function handleColumnSortChange(sortKey) {
    console.log('handle sort')
  }

  return (
    <>
      <Card className={classes.root}>
        <PerfectScrollbar>
          <MuiTable className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((column, keyIndex) => {
                  return (
                    <TableCell
                      key={keyIndex}
                      {...(column.sortKey &&
                        sortConfig.type === 'column' && {
                        onClick: () => handleColumnSortChange(column.sortKey)
                      })}
                      {...column?.tableHeadCellProps}
                    >
                      <Box
                        alignItems='center'
                        display='flex'
                        flexDirection='row'
                        style={{ gap: 8 }}
                      >
                        <Typography color='initial' variant='inherit'>
                          {column.title}
                        </Typography>
                      </Box>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{`test row data`}</TableCell>
              </TableRow>
            </TableBody>
          </MuiTable>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

export default Table

