import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined'
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'

export default function DropdownMenu(props) {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()

  function makeTranslatedSourceItem() {
    let searchAgain = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_SEARCHAGAIN
    )
    let downloadPDF = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_DOWNLOAD
    )

    return {
      searchAgain,
      downloadPDF
    }
  }

  const handleSearchAgainClick = (event) => {
    event.stopPropagation()
    props.onDialog(event)
  }

  const handleDownloadPDFClick = (event) => {
    console.log(props)
    event.stopPropagation()
    props.downloadPDF(props.searchRecordId)
    props.off(event)
  }

  return (
    <Menu
      anchorEl={props.anchor}
      open={props.open}
      onClose={props.off}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
    >
      <MenuItem onClick={(e) => handleSearchAgainClick(e)}>
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        {translatedItem.searchAgain}
      </MenuItem>
      <MenuItem onClick={(e) => handleDownloadPDFClick(e)}>
        <ListItemIcon>
          <PictureAsPdfIcon />
        </ListItemIcon>
        {translatedItem.downloadPDF}
      </MenuItem>
    </Menu>
  )
}
