import React, { useContext, useState, useEffect } from 'react'

import { BasePage as CngBasePage } from 'cng-web-lib'
import { components, constants, useServices } from 'cng-web-lib'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_TradeManagement'
import FTACalculatorPage from './FTACalculatorPage'
import ProductDetailsPage from './ProductDetailsPage'
import ViewFtaProductDetailsPage from './ViewFtaProductDetailsPage'
import FtaActivityHistoryPage from './FtaActivityHistoryPage'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const PUBLIC_URL = process.env.PUBLIC_URL

function WrappedBasePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.bcFtaCalculator}
      renderPage={(showSnackbar) => (
        <FTACalculatorPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedProductDetailsPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.bcExport}
      renderPage={(showSnackbar) => (
        <ProductDetailsPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewFtaProductDetailsPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.bcExport}
      renderPage={(showSnackbar) => (
        <ViewFtaProductDetailsPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedFtaActivityHistoryPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.bcExport}
      renderPage={(showSnackbar) => (
        <FtaActivityHistoryPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedBasePage as BasePage,
  WrappedProductDetailsPage as FtaProductDetailsPage,
  WrappedViewFtaProductDetailsPage as ViewFtaProductDetailsPage,
  WrappedFtaActivityHistoryPage as FtaActivityHistoryPage
}
