import React from 'react'
import CardMedia from './CardMedia'
import { components, useTranslation } from 'cng-web-lib'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Typography } from '@material-ui/core'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'
import Namespace from 'src/constants/locale/Namespace'

const PUBLIC_URL = process.env.PUBLIC_URL

const {
  button: { CngPrimaryButton }
} = components

export default function NoRecordPage(props) {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()

  function makeTranslatedSourceItem() {
    let noResult = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.FIELD_NO_RESULT
    )
    let backToSearch = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.BTN_BACK
    )

    return {
      noResult,
      backToSearch
    }
  }

  return (
    <div>
      <CardMedia
        image={PUBLIC_URL + '/static/images/deniedparty/emptystate.svg'}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10vh'
        }}
      >
        <Typography color='textSecondary'>{translatedItem.noResult}</Typography>
        <CngPrimaryButton
          onClick={() => props.backButton()}
          startIcon={<ArrowBackIcon />}
        >
          {translatedItem.backToSearch}
        </CngPrimaryButton>
      </div>
    </div>
  )
}
