import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import moment from 'moment'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import CngBackdrop from '../components/CngBackdrop'

import RulesOfOriginListSeperator from './RulesOfOriginListSeperator'

const {
  CngGridItem,
  form: {
    field: { CngDateField }
  }
} = components
const initialValues = Object.freeze({})

const FormBody = ({ searchCriteria, ...props }) => {

  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [displayedRulesOfOriginList, setDisplayedRulesOfOriginList] = useState(
    []
  )
  const [pageNumber, setPageNumber] = useState(0)
  const loadAmount = 10
  const observer = useRef()
  const { fetchPageableRecords, securedSendRequest } = useServices()

  // Date picker states
  // const [productIndex, setProductIndex] = useState(0)
  const [datePickerDialog, setDatePickerDialog] = useState({
    open: false,
    productIndex: 0
  })
  const [qualificationExpiryDate, setQualificationExpiryDate] = useState(
    new Date()
  )


  // @purpose: To find, are there more pages of data to fetch and set page number & parcels
  useEffect(() => {
    setLoading(false)
    const temp = [...props.displayedRulesOfOriginList]
    if (temp.length < loadAmount) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    setPageNumber(0)

    setDisplayedRulesOfOriginList(temp.flat())
  }, [props.displayedRulesOfOriginList])

  // @purpose: Send updated qualification date to back-end
  const handleSetQualificationDate = (idx, isDateVisibe) => {
    setDatePickerDialog({ open: true, productIndex: idx })
  }

  // @purpose: DatePicker on change event handler
  const handleDatePickerOnChange = (thisDate, value) => {
    setQualificationExpiryDate(moment.utc(value).format())
    setDatePickerDialog({ open: false, productIndex: 0 })
  }

  // @purpose: DatePicker on close event handler
  const handleDatePickerOnClose = (thisDate, value) => {
    setDatePickerDialog({ open: false, productIndex: 0 })
  }

  // @purpose: DatePicker ok button click handler
  const handleDatePickerOkClick = (date) => {
    // Show data loader overlay
    setLoading(true)

    securedSendRequest.execute(
      'put',
      TradeMgmtApiUrls.SET_ROO_QUALIFICATION_DATE,
      {
        originCalculationId:
          displayedRulesOfOriginList[datePickerDialog.productIndex]
            .originCalculation.originCalculationId,
        qualifcationExpiry: moment.utc(date).format()
      },
      (response) => {
        // If response contains unique id then no need to pass index from parent to child and vice versa
        // ||-> instead find index using the id key
        let items = [...displayedRulesOfOriginList]
        const index = items.findIndex(
          (item) => item.id === response.data.results[0].id
        )
        items[index] = response.data.results[0]

        // If response has no data for the particular object i.e. only specific details
        // |-> then use the index concept for replacing it
        // let items = [...displayedRulesOfOriginList]
        // let item = { ...items[datePickerDialog.productIndex] }
        // item.originCalculation = response.data.results[0]
        // items[datePickerDialog.productIndex] = item

        setDisplayedRulesOfOriginList([...items])

        // If response has entire updated data
        // setDisplayedRulesOfOriginList([...response.data.results])

        // Hide data loader overlay
        setLoading(false)
      },
      (error) => {
        console.error(error)
      },
      // On compelte
      () => {
        // Hide data loader overlay
        setLoading(false)
      },
      {}
    )
  }


  return (
    <>
      <div className='summary-list-items--container'>
        {displayedRulesOfOriginList.map((product, idx) => (
          <div key={idx}>
            {
              <RulesOfOriginListSeperator
                product={product}
                idx={idx}
                handleSetQualificationDate={handleSetQualificationDate}
                props={props}
                productStatusCode={props?.productStatusCode}
              />
            }
          </div>
        ))}

        <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
          <CngBackdrop loading={loading} />
        </CngGridItem>

        <div style={{ display: 'none' }}>
          <CngDateField
            name={`qualificationDate`}
            label={'date'}
            format='DD-MMM-YYYY'
            orientation='landscape'
            shouldDisableDate={(date) => {
              return moment(moment().format('DD-MMM-YYYY')).isAfter(
                moment(date)
              )
            }}
            style={{
              display: 'none'
            }}
            open={datePickerDialog.open}
            onChange={(date, value) => handleDatePickerOnChange(date, value)}
            onAccept={(date) => handleDatePickerOkClick(date)}
            onClose={() => handleDatePickerOnClose()}
          />
        </div>
      </div>
    </>
  )
}

const RulesOfOriginListCardComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default RulesOfOriginListCardComponent
