import React, { useState, useMemo } from 'react'
import {
  Chip,
  Grid,
  MenuItem,
  TextField,
  makeStyles
} from '@material-ui/core'
import { DatePicker } from "@material-ui/pickers"
import { components } from 'cng-web-lib'
import { formatDate } from '../helpers'

const LTSD_OPTS = [
  'All',
  'Yes',
  'No'
]

const STATUS_OPTS = [
  'All',
  'Attached',
  'Detached'
]

const {
  button: { CngIconButton },
  CngDialog
} = components



function LTSDFilter({ classes, ...props }) {

  const [ltsd, setLTSD] = useState(LTSD_OPTS[0])
  const dateEnabled = useMemo(() => ltsd === "Yes", [ltsd])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [date, setDate] = useState('')
  const dateText = useMemo(() => {
    if (date && dateEnabled)
      return formatDate(date)
    return ''
  })

  const handleStatus = e => {
    const val = e.target.value
    setLTSD(val)
    props.onFilterChanged(props.tableData.id, { ltsd: val, date })
  }
  const handleDateDialog = (val) => {
    setDate(val)
    setDialogOpen(false)

    props.onFilterChanged(props.tableData.id, { ltsd, date: val })
  }

  return <Grid container spacing={1} wrap="nowrap">
    <Grid item xs><TextField
      id="select-ltsd-status"
      select size="small" fullWidth
      type='text'
      label="LTSD" value={ltsd}
      variant="outlined" className={classes.field}
      onChange={handleStatus}
      FormHelperTextProps={{ component: 'div' }}
      helperText={date ? <Chip label={dateText}
        onDelete={() => handleDateDialog('')}
        size="small" variant="outlined"
      /> : ''}
    >
      {LTSD_OPTS.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField></Grid>
    <Grid item className={classes.btn}><CngIconButton
      icon={['fal', 'calendar-day']}
      onClick={() => setDialogOpen(true)}
      disabled={!dateEnabled}
      size="small"
    /></Grid>
    <CngDialog open={dialogOpen}
      maxWidth="md"
      customDialogContent={<DatePicker
        autoOk
        variant="static"
        openTo="date"
        value={date}
        onChange={handleDateDialog}
      />}
    />
  </Grid>
}

function AttachedFilter({ classes, ...props }) {
  const [status, setStatus] = useState(STATUS_OPTS[0])

  const handleChange = e => {
    let val = e.target.value
    setStatus(val)
    props.onFilterChanged(props.tableData.id, val)
  }

  return <TextField
    id="select-attached-status"
    select size="small" fullWidth
    label="Status" value={status}
    variant="outlined" className={classes.field}
    onChange={handleChange}
  >
    {STATUS_OPTS.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </TextField>
}


export { LTSDFilter, AttachedFilter }
