import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useState, useEffect } from 'react'
import { constants } from 'cng-web-lib'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import DocumentActionMenu from './DocumentActionMenu'
import Utils from 'src/views/common/utils/Utils'


const {
  filter: { EQUAL }
} = constants


const DocumentDetailsComponent = (props) => {

  const [files, setFiles] = useState([])

  useEffect(() => {
    if (props.tile) {
      setFiles(props.tile)
    }
  }, [props.tile])


  if (files) {
    return (
      <TableContainer component={Paper}>
        <Table id="table">
          <TableBody>
            {files.map((elem, i) => {
              return (<TableRow key={elem.id}>
                <TableCell > <FileCopyIcon /></TableCell>
                <TableCell>
                  <DocumentInfoDecoratorComponent name={Utils.truncLongFileName(elem.fileName)} size={elem.fileSize} /> </TableCell>

                <TableCell>
                  <DocumentActionMenu id={i}
                    hideDownloadLog
                    removeRowClick={props.removeRowClick}
                    editRowClick={props.editRowClick}
                    downloadRowClick={props.downloadRowClick}
                    hideEdit={true}
                    hideDelete={true}
                  />
                </TableCell>
              </TableRow>)
            })
            }
          </TableBody >
        </Table>
      </TableContainer>

    )
  } else {
    return (<br />)
  }

}

export default DocumentDetailsComponent
