import { Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import DutySavingsListCardDetailComponent from './DutySavingsListCardDetailComponent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngGridItem } = components
const initialValues = Object.freeze({})

const FormBody = ({ searchCriteria, ...props }) => {
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [displayedDutySavingsList, setDisplayedDutySavingsList] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const loadAmount = 10
  const observer = useRef()
  const { fetchPageableRecords } = useServices()


  // @purpose: To find, are there more pages of data to fetch and set page number & parcels
  useEffect(() => {
    setLoading(false)
    const temp = [...props.displayedDutySavingsList]
    if (temp.length < loadAmount) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    setPageNumber(0)

    setDisplayedDutySavingsList(temp.flat())
  }, [props.displayedDutySavingsList])


  return (
    <>
      <div className='summary-list-items--container'>
        {displayedDutySavingsList.map((product, idx) => (
          <div key={idx}>
            <DutySavingsListCardDetailComponent.FormBody
              product={product}
              {...props}
            />
          </div>
        ))}
      </div>
    </>
  )
}

const DutySavingsListCardComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default DutySavingsListCardComponent
