import React, { useState } from 'react'

import DutySavingsForm from './DutySavingsForm'

import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'


function DutySavingsTabContent({ history, showNotification, ...props }) {

  const [disabled, setDisabled] = useState(true)

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  function onSubmit(data) {
    // console.log('Create New Parcel :: onSubmit ::\n', data)
  }

  return (
    <>
      <div className='page-grid child-of-grid one-col mh-200'>
        <main className='page-grid__col one-col'>
          <DutySavingsForm.FormFields
            history={history}
            showNotification={showNotification}
            disabled={disabled}
            dutySavingsPayload={props.dutySavingsPayload}
            statusCode={props.statusCode}
            currencyCode={props.currencyCode}
          />
        </main>
      </div>
    </>
  )
}

export default DutySavingsTabContent
