import { Grid, Card, Typography, IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/styles'
import DropdownMenu from './DropdownMenu'
import ResearchDialog from './ResearchDialog'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'

const useStyles = makeStyles({
  moreIconButton: {
    borderRadius: '11px',
    '&:hover': {
      backgroundColor: 'rgba(118, 128, 163, 0.3)'
    }
  }
})

function SearchHistorys(props) {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()

  function makeTranslatedSourceItem() {
    let searchMatches = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.FIELD_SEARCH_MATCHES
    )

    return {
      searchMatches
    }
  }

  const classes = useStyles()

  const [anchor, setAnchor] = useState(false)
  const open = Boolean(anchor)
  const handleAnchorOpen = (event) => {
    event.stopPropagation()
    setAnchor(event.currentTarget)
  }
  const handleAnchorClose = (event) => {
    event.stopPropagation()
    setAnchor(null)
  }

  const [dialog, setDialog] = useState(false)

  const handleDialogOpen = (event) => {
    event.stopPropagation()
    setDialog(true)
  }

  const handleDialogClose = (event) => {
    event.stopPropagation()
    setAnchor(null)
    setDialog(false)
  }

  const handleGetHistory = (event, id) => {
    if(props.isLoading) {
      return;
    }
    event.stopPropagation()
    props.getHistory(id)
  }

  return (
    <Card
      onClick={(e) => handleGetHistory(e, props.party.uuid)}
      style={{
        backgroundColor: 'rgba(137,150,175,.0627451)',
        marginTop: '2vh',
        padding: '2vh',
        cursor: 'pointer',
        boxShadow: 'none'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography color='textPrimary' variant='h5'>
            {props.party.name}
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            {translatedItem.searchMatches} {props.party.searchMatch}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography color='textSecondary'>
            {props.party.createdDate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
            display: 'flex'
          }}
        >
          <IconButton
            className={classes.moreIconButton}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup={'true'}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleAnchorOpen}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
        <DropdownMenu
          anchor={anchor}
          open={open}
          off={handleAnchorClose}
          onDialog={handleDialogOpen}
          downloadPDF={props.downloadPDF}
          searchRecordId={props.party.uuid}
        />
        <ResearchDialog
          dialog={dialog}
          off={handleDialogClose}
          agree={props.searchAgain}
          name={props.party.name}
          partyType={props.party.partyType}
        />
      </Grid>
    </Card>
  )
}

export default SearchHistorys
