import { Chip, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngMoreActionsMenu } = components

const moreActionsList = [
  {
    action: 'setDate',
    name: 'Set qualification expiry',
    icon: ['fal', 'edit']
  }
  // {
  //   action: 'checklist',
  //   name: 'Origin calculation checklist',
  //   icon: ['fal', 'edit']
  // }
]

const RelatedBoMListCardDetailTop = (props) => {
  const { componentNo, customTariffNo } = props
  const history = useHistory()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={4}>
          <div className={`cng-summary-card--header-box--left`}>
            <div className={`header-box--left-col-1 text-left-align box-col`}>
              <div className={`field-vertical--label-value`}>
                <Typography className={'frb-card-label-large-grey'}>
                  {FtaTranslatedTextsObject.componentNo}
                  <b className='ml-5 dark'>
                    {componentNo !== null ? componentNo : `-`}
                  </b>
                </Typography>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1 text-right-align box-col`}>
              <div className={`field-vertical--label-value`}>
                <Typography className={'frb-card-label-large-grey'}>
                  {FtaTranslatedTextsObject.customsTariffNo}
                  <b className='ml-5 dark'>
                    {customTariffNo !== null ? customTariffNo : `-`}
                  </b>
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default RelatedBoMListCardDetailTop
