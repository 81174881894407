import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import FtaActivityHistoryListCardDetailComponent from './FtaActivityHistoryListCardDetailComponent'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import CircularProgress from '@material-ui/core/CircularProgress'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngGridItem } = components

const useStyles = makeStyles((theme) => ({
  sectionSummaryCards: {
    backgroundColor: theme.palette.background.white,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit'
  }
}))

const initialValues = Object.freeze({})

const FormBody = ({ searchCriteria, ...props }) => {
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [displayedProductsList, setDisplayedProductsList] = useState([
    ...props.displayedProductsList
  ])

  const { fetchPageableRecords, securedSendRequest } = useServices()
  const observer = useRef()
  const classes = useStyles()

  const [pageNumber, setPageNumber] = useState(1)
  const loadAmount = 10
  const [infinityShownCount, setInfinityShownCount] = useState(
    props.displayedProductsList.length
  )

  useEffect(() => {
    if (pageNumber > 1) {
      setLoading(true)
      lazyLoadingProducts()
    }
  }, [pageNumber])

  // @purpose: Whenever there is any change in page number trigger the API call
  // |-> This is in liase with intersection observer below
  function lazyLoadingProducts() {
    // Search payload
    const searchPayload = {
      pageNumber: pageNumber,
      pageSize: loadAmount,
      ...searchCriteria
    }

    securedSendRequest.execute(
      'post',
      TradeMgmtApiUrls.GET_ACTIVITY_HISTORY,
      searchPayload,
      (response) => {

        if (response?.data?.results?.length < loadAmount) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        // Append previous state data alond with the new data.content
        setDisplayedProductsList([
          ...displayedProductsList,
          ...response?.data?.results
        ])
        setLoading(false)

        // Update shown count number in parent
        props.updateShownCount(
          infinityShownCount + response?.data?.results?.length
        )

        // set the latest count into the state variable
        setInfinityShownCount(
          infinityShownCount + response?.data?.results?.length
        )

        setPageError(false)
      },
      (error) => {
        onError(error)
        console.error(error)
      },
      // On compelte
      () => {
        // Hide data loader overlay
        setLoading(false)
      },
      {}
    )
  }

  // Set state whenever the error is encountered
  const onError = (error) => {
    setPageError(true)
    setLoading(false)
  }

  // @purpose: To find, are there more pages of data to fetch and set page number & parcels
  useEffect(() => {
    setLoading(false)
    const temp = [...props.displayedProductsList]
    if (temp.length < loadAmount) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    setPageNumber(1)

    setDisplayedProductsList(temp.flat())
  }, [props.displayedProductsList])

  // @purpose: Intersection observer during infinite scrolling
  const lastProductCardElementRef = useCallback(
    (node) => {
      if (loading) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
        }
      })

      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  return (
    <>
      <div
        className={`summary-list-items--container ${classes.sectionSummaryCards}`}
      >
        {displayedProductsList.map((product, idx) => (
          // Uncomment below line for infinite data loading
          <div key={idx} ref={lastProductCardElementRef}>
            <FtaActivityHistoryListCardDetailComponent.FormBody
              product={product}
              {...props}
            />
          </div>
        ))}
      </div>

      {/* Infinite data loader */}
      <div style={{ display: loading ? 'inline' : 'none' }}>
        <Grid container justify='center'>
          <CngGridItem>
            <CircularProgress />
          </CngGridItem>
        </Grid>
      </div>
    </>
  )
}

const FtaActivityHistoryListCardComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default FtaActivityHistoryListCardComponent
