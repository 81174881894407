import { Chip, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'

const { CngMoreActionsMenu } = components

const CalcResultsListCardDetailTop = ({ productStatuses, ...props }) => {

  const { product, ftaName } = props

  let leftCountReducer = product.length > 2 ? 2 : product.length
  let productsLeftCount = product.length - leftCountReducer
  console.log(
    'total product length: \t',
    product.length,
    '\nremaining products to show:\t',
    productsLeftCount,
  )

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()


  const history = useHistory()

  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={4}>
          <div className={`cng-summary-card--header-box--left`}>
            <Chip
              label={
                <Typography className={`font-mediumBold font-16`}>
                  {ftaName?.toUpperCase()}
                </Typography>
              }
              style={{ backgroundColor: '#ffffff' }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1 text-right-align box-col`}>
              <div className={`field-vertical--label-value`}>
                {productsLeftCount > 0 && (
                  <Chip
                    label={
                      <Typography className={`font-mediumBold font-16`}>
                        {`${productsLeftCount} more`}
                      </Typography>
                    }
                    style={{ backgroundColor: '#ffffff' }}
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default CalcResultsListCardDetailTop
