import {
  Box,
  Button,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,

  makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useFormContext } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import { handleErrors } from 'src/store/intelligent-advisory'

import CngSelectField from 'src/components/select/CngSelectField'
import CngTreeview from 'src/components/treeview'
import { predictive, manual } from 'src/views/intelligent-advisory/tas/quick-search/helper'

import { TreeHeader } from 'src/views/intelligent-advisory/tas/quick-search/components'

import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import { components, constants, useServices } from 'cng-web-lib'
const { FormState } = constants

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField
    },
    CngForm
  },
  button: { CngPrimaryButton },
  CngDialog
} = components

const OPTIONS = [{
  text: 'Auto',
  value: 'auto'
}, {
  text: 'Description',
  value: 'description'
}, {
  text: 'HS Code',
  value: 'hscode'
}
//, {
//  text: 'HS/CA Desc',
//  value: 'custom_all'
//}
]

const INITIAL_VALUES = {
  search: 'auto',
  targetCountry: '',
  description: '',
  langInput: 'en',
  langOutput: 'en'
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(1.5, 3)
  }
}))

function SearchDialog(props) {
  const { createRecord, fetchRecords, fetchRecord, exportRecords, securedSendRequest } = useServices()
  const classes = useStyles()
  const parentForm = useFormContext()
  const { enqueueSnackbar } = useSnackbar()

  const countryOfImport = parentForm.watch('destination')

  const [results, setResults] = useState([])
  const [expanded, setExpanded] = useState([])
  const [activeItem, setActiveItem] = useState('')
  const [loading, setLoading] = useState(false)
  const HS_REGEX = new RegExp('(^[0-9]{6,13}$)+')

  const handleSubmit = (values) => {
    values.targetCountry = countryOfImport
    setLoading(true)
    setResults([])

    let err = -1
    if (!values.description)
      err = enqueueSnackbar('Please enter a value to search.', {variant: 'error'})
    if (!values.targetCountry)
      err = enqueueSnackbar('Please choose an import country.', {variant: 'error'})
    if (err === -1) {
        let stripped = values.description.trim().replaceAll(' ', '')
        values.searchType = values.search
        if (values.search !== 'hscode')
          values.searchType = (values.search === 'auto' && HS_REGEX.test(stripped))
            ? 'hscode' : 'gri'
        else
          values.description = stripped

      values.griLevel='predict'

      doSubmit(values)
    } else
      { setLoading(false) }
  }

  const doSubmit = values => {
    fetchRecords.execute(
      CIAApiUrls.HSCODE,
      { customData: values },
      data => {
        if (!data.results || !data.results.length)
          enqueueSnackbar('No results', { variant: 'error' })
        else {

          let [list, expanded, lastChild] = manual.search(data, {
            ...values,
            sessionToken: data.sessionToken
          })
          setResults(list)
          setExpanded(expanded)
          setActiveItem(lastChild || '')
        }
      },
      error => handleErrors(enqueueSnackbar, error),
      () => setLoading(false)
    )
  }

  const handleActiveItemChange = (node, isActive, isExpanded, openItems, setLoadingFn) => {
    if (node.isManual && !node.isLoading && !node.children) {
      setLoadingFn(true)
      let customData = {
        ...node.terms,
        griLevel: node.terms.griNextLevel,
        indent: node.indent+1, // required for manual search
        selectedHS: node.hs_code
      }
      fetchRecords.execute(
        CIAApiUrls.GRISEARCH,
        { customData },
        data => {
          let [children, expandList, lastChild] = manual.search(data, customData)
          node.children = children
        },
        error => handleErrors(enqueueSnackbar, error),
        done => setLoadingFn(false)
      )
    } else
      { setActiveItem(node.type === 'dutiable' && node) }
  }

  const handleClose = (preserveActive) => {
    setResults([])
    setExpanded([])
    setActiveItem('')
    props.onClose()
  }

  const handleApply = () => {
    props.onApply(activeItem)
    handleClose(true)
  }

  return (<CngDialog
    open={props.open}
    fullWidth
    maxWidth='md'
    dialogTitle={<Grid container wrap='nowrap' justify='space-between' alignItems='center'>
      <Typography variant='h5'><b>Import HS Code</b></Typography>
      <Tooltip title='Close'>
        <IconButton className='cng-cia' size='small'
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={['fal', 'times']} />
        </IconButton>
      </Tooltip>
    </Grid>}
    customDialogContent={<DialogContent className={classes.dialogContent}>
      <CngForm
        formikProps={{
          initialValues: INITIAL_VALUES,
          onSubmit: handleSubmit
        }}
        formState={FormState.COMPLETED}
        renderButtonSection={()=>false}
        //To prevent submitting of parent form. Will only apply to the nested child form
        innerForm={true}
        renderBodySection={() => <>
          <Grid container spacing={1} wrap='nowrap' alignItems='center'>
            <Grid item xs><Grid container spacing={1}>
              <Grid item><CngSelectField
                items={OPTIONS}
                name='search'
                size='small'
              /></Grid>
              <Grid item xs><CngTextField
                name='description'
                required
                size='small'
                label='HS code or description'
              /></Grid>
            </Grid></Grid>
            <Grid item>
              <CngPrimaryButton type='submit' size='large'
                disabled={loading}
                endIcon={loading && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />}
              >
                {loading ? 'Searching...' : 'Search'}
              </CngPrimaryButton>
            </Grid>
          </Grid>

          <CngTreeview
            data={results}
            renderHeader={node => <TreeHeader
              code={node.hs_code}
              loading={node.isLoading}
              description={node.hs_description}
            />}
            onToggle={handleActiveItemChange}
            expanded={expanded}
            active={activeItem.id}
            autoCascade
          />
        </>}
      />
    </DialogContent>}
    dialogAction={<>
      <Button onClick={handleClose} color='primary'>
        Cancel
      </Button>
      {activeItem && <CngPrimaryButton
        onClick={handleApply}
      >
        Apply
      </CngPrimaryButton>}
    </>}
  />)
}

export default SearchDialog
