import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import { Box, Grid, Card } from '@material-ui/core'
import CardMedia from './component/CardMedia'
import { useSnackbar } from 'notistack'
import SearchBar from './component/SearchBar'
import TradeMgmtApiUrls from '../../../apiUrls/TradeMgmtApiUrls'
import { createStore } from 'redux'
import { Provider, useDispatch, useSelector } from 'react-redux'
import SearchHistorysCard from './component/SearchHistorysCard'
import SearchRecordsCard from './component/SearchRecordsCard'
import NoRecordPage from './component/NoRecordPage'
import Namespace from 'src/constants/locale/Namespace'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'

const FORM_DEFAULT_VALUE = {
  partyType: '',
  searchName: ''
}

const INIT_STATE = {
  partyType: '',
  searchName: '',
  searchOn: '',
  searchRecord: [],
  searchHistory: []
}

const stateReducer = (state = INIT_STATE, { type, payload } = {}) => {
  switch (type) {
    case 'SET_PARTY_TYPE':
      return {
        ...state,
        partyType: payload
      }
    case 'SET_SEARCH_NAME':
      return {
        ...state,
        searchName: payload
      }
    case 'SET_SEARCH_ON':
      return {
        ...state,
        searchOn: payload
      }
    case 'SET_RECORD_SEARCH':
      return {
        ...state,
        searchRecord: payload
      }
    case 'SET_HISTORY_SEARCH':
      return {
        ...state,
        searchHistory: payload
      }
    case 'SET_RECORD_ID':
      return {
        ...state,
        searchRecordId: payload
      }
    case 'SET_DEFAULT':
      return state
    default:
      return state
  }
}

const store = createStore(stateReducer)

const PUBLIC_URL = process.env.PUBLIC_URL

function SearchPage() {
  const { translate } = useTranslation(Namespace.TMP_DPS)
  const translatedItem = makeTranslatedSourceItem()

  function makeTranslatedSourceItem() {
    let validateSearchName = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.VALIDATE_SEARCH_NAME
    )
    let validateSearchType = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.VALIDATE_SEARCH_TYPE
    )
    let searchFail = translate(
      Namespace.TMP_DPS,
      TmpDeniedPartySearchKeys.ERROR_SEARCH_FAIL
    )

    return {
      validateSearchName,
      validateSearchType,
      searchFail
    }
  }

  const dispatch = useDispatch()
  const { partyType, searchName, searchOn, searchRecord, searchHistory, searchRecordId } =
    useSelector(
      ({ partyType, searchName, searchOn, searchRecord, searchHistory, searchRecordId }) => ({
        partyType,
        searchName,
        searchOn,
        searchRecord,
        searchHistory,
        searchRecordId
      })
    )

  const [isLoading, setLoading] = useState(false)
  const [historyOn, setHistory] = useState(false)
  const [searchRecordEmpty, setSearchRecordEmpty] = useState(false)

  const { FormState } = constants

  const {
    form: { CngForm }
  } = components

  const { enqueueSnackbar } = useSnackbar()

  const { securedSendRequest } = useServices()

  useEffect(() => {
    getUserSearchHistories('createdDate,desc')
    dispatch({ type: 'SET_PARTY_TYPE', payload: "all" })

  }, [])

  const onSubmit = () => {
    //Validation
    if (searchName === '' || searchName === undefined) {
      enqueueSnackbar(translatedItem.validateSearchName, { variant: 'error' })
      setLoading(false)
      return
    } else if (partyType === '' || partyType === undefined) {
      enqueueSnackbar(translatedItem.validateSearchType, { variant: 'error' })
      setLoading(false)
      return
    }

    searchRisk(searchName, partyType)
  }

  const searchRisk = (searchName, partyType) => {
    setLoading(true)
    securedSendRequest.execute(
      'GET',
      `${TradeMgmtApiUrls.GET_DPS_SEARCH}?name=${searchName}&partyType=${partyType}`,
      {},
      (data) => {
        if (data.data.riskResults.length === 0) {
          dispatch({ type: 'SET_SEARCH_ON', payload: true })
          setSearchRecordEmpty(true)
          setLoading(false)
          return
        }

        dispatch({ type: 'SET_SEARCH_NAME', payload: searchName })
        dispatch({ type: 'SET_PARTY_TYPE', payload: partyType })
        dispatch({ type: 'SET_RECORD_SEARCH', payload: data.data.riskResults })
        dispatch({ type: 'SET_RECORD_ID', payload: data.data.uuid })
        dispatch({ type: 'SET_SEARCH_ON', payload: true })
        setSearchRecordEmpty(false)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        enqueueSnackbar(translatedItem.searchFail, { variant: 'error' })
        setLoading(false)
      }
    )
  }

  const getSearchHistory = (historyId) => {
    setLoading(true)
    securedSendRequest.execute(
      'GET',
      `${TradeMgmtApiUrls.GET_DPS_HISTORY}?id=${historyId}`,
      {},
      (data) => {
        if (data.data.riskResults.length === 0) {
          dispatch({ type: 'SET_SEARCH_ON', payload: true })
          setSearchRecordEmpty(true)
          setLoading(false)
          return
        }
        dispatch({ type: 'SET_RECORD_SEARCH', payload: data.data.riskResults })
        dispatch({ type: 'SET_RECORD_ID', payload: data.data.uuid })
        dispatch({ type: 'SET_SEARCH_ON', payload: true })
        setLoading(false)
      },
      (error) => {
        console.log(error)
        enqueueSnackbar(translatedItem.searchFail, { variant: 'error' })
        setLoading(false)
      }
    )
  }

  const getUserSearchHistories = (sorting) => {
    setLoading(true)
    securedSendRequest.execute(
      'GET',
      `${TradeMgmtApiUrls.GET_DPS_USER_SEARCH_HISTORY}?sort=${sorting}`,
      {},
      (data) => {
        if (data.data.dpsRequests.length === 0) {
          setLoading(false)
          return
        }
        setLoading(false)
        dispatch({ type: 'SET_HISTORY_SEARCH', payload: data.data.dpsRequests })
        setHistory(true)
      },
      (error) => {
        console.log(error)
        enqueueSnackbar(translatedItem.searchFail, { variant: 'error' })
        setLoading(false)
      }
    )
  }

  const downloadPDF = (id) => {
    setLoading(true)
    securedSendRequest.execute(
      'GET',
      `${TradeMgmtApiUrls.GET_DPS_PDF_DOWNLOAD}?id=${id}`,
      {},
      (data) => {
        let blob = new Blob(byteArraytoBlob(data.data.file), {
          type: 'application/pdf'
        })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${data.data.fileName}.pdf`
        link.click()
        setLoading(false)
      },
      (error) => {
        console.log(error)
        enqueueSnackbar(translatedItem.searchFail, { variant: 'error' })
        setLoading(false)
      }
    )
  }

  function byteArraytoBlob(base64Data) {
    let sliceSize = 1024
    let byteCharacters = atob(base64Data)
    let bytesLength = byteCharacters.length
    let slicesCount = Math.ceil(bytesLength / sliceSize)
    let byteArrays = new Array(slicesCount)
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize
      let end = Math.min(begin + sliceSize, bytesLength)
      let bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }

    return byteArrays
  }

  function handleType(value) {
    dispatch({ type: 'SET_PARTY_TYPE', payload: value })
  }

  function handleSearchName(value) {
    dispatch({ type: 'SET_SEARCH_NAME', payload: value })
  }

  function handleBackButton() {
    getUserSearchHistories('createdDate,desc')
    dispatch({ type: 'SET_SEARCH_ON', payload: false })
    dispatch({ type: 'SET_SEARCH_NAME', payload: '' })
    dispatch({ type: 'SET_PARTY_TYPE', payload: 'all' })
    setSearchRecordEmpty(false)
  }

  return (
    <Box my={2}>
      <CngForm
        formikProps={{
          FORM_DEFAULT_VALUE,
          onSubmit
        }}
        formState={FormState.COMPLETED}
        renderButtonSection={() => false}
        renderBodySection={() => (
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <SearchBar
                type={partyType}
                search={searchName}
                handleType={handleType}
                handleSearchName={handleSearchName}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item>
              <Card variant='outlined'>
                {searchOn && searchRecordEmpty && (
                  <NoRecordPage backButton={handleBackButton} />
                )}
                {searchOn && !searchRecordEmpty && (
                  <SearchRecordsCard
                    response={searchRecord}
                    backButton={handleBackButton}
                    downloadPDF={downloadPDF}
                    searchRecordId={searchRecordId}
                  />
                )}
                {!searchOn && historyOn && (
                  <SearchHistorysCard
                    sorting={getUserSearchHistories}
                    searchAgain={searchRisk}
                    response={searchHistory}
                    getHistory={getSearchHistory}
                    downloadPDF={downloadPDF}
                    isLoading={isLoading}
                  />
                )}
                {!searchOn && !historyOn && (
                  <CardMedia
                    image={PUBLIC_URL + '/static/images/deniedparty/search.svg'}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        )}
      />
    </Box>
  )
}

export default () => (
  <Provider store={store}>
    <SearchPage />
  </Provider>
)
