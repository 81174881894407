import { Box, Paper, Grid, Chip, makeStyles, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CngBackdrop from '../components/CngBackdrop'
import { useFormContext } from 'react-hook-form'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons'
import Utils from 'src/shared/Utils'
import GoBackButton from 'src/components/button/GoBackButton'
import pathMap from 'src/paths/PathMap_TradeManagement'
import { STATE_KEY } from '../myproducts/helpers'



const {
  CngGridItem,
  button: { CngIconButton }
} = components

const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    padding: '16px',
    borderRadius: '8px'
  }
}))

const FORM_INITIAL_VALUES = Object.freeze({})

const FormBody = ({ disabled, ...props }) => {
  const history = useHistory()

  const {
    bomName,
    profit,
    otherCost,
    directLaborCost,
    directOverheadCost,
    nonOriginatingValuePercentage,
    orignatingValuePercentage,
    productId
  } = props.displayedRelatedBoMSummary || {}

  const classes = useStyles()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  const editProductInfo = () => {
    console.log('Edit product information')
    localStorage.setItem(STATE_KEY, btoa("EDIT"))
    history.push(`${pathMap.MY_PRODUCT_FTA}/${productId || 'new'}`)
  }

  return (
    <>
      {/* Section */}
      <Box
        className={`cng-details-page__section mb-20 ${classes.sectionBlueBg}`}
      >
        {/* Row 1 - single column */}
        <div className='section-view-fta-details header one-col '>
          <Grid container>
            <CngGridItem xs={12} sm={11}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.bomName}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {bomName !== null ? bomName : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={1}>
              <span className='cta-btn-wrapper flex-end'>
                <Tooltip
                  title={FtaTranslatedTextsObject.ttipEditProductInformation}
                  placement='bottom'
                  className={`form-control__item`}
                >
                  <span>
                    <CngIconButton
                      icon={['fa-light', 'fa-pen-to-square']}
                      type='outlined'
                      size='medium'
                      onClick={() => editProductInfo()}
                    />
                  </span>
                </Tooltip>
              </span>
            </CngGridItem>
          </Grid>
        </div>

        {/* Row 2 -Two column - minmax 180px */}
        <div className='section-view-fta-details header one-col'>
          <Grid container>
            <CngGridItem xs={12} sm={4}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.profit}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {profit !== null ? profit : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.otherCost}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {otherCost !== null ? otherCost : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.directLabourCost}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {directLaborCost !== null ? directLaborCost : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.directOverheardCost}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {directOverheadCost !== null ? directOverheadCost : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.nonOriginatingValuePercentage}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {nonOriginatingValuePercentage !== null
                      ? nonOriginatingValuePercentage
                      : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <div className={`field-vertical--label-value`}>
                <span className={`field-vertical--item`}>
                  <label className={`field-label`}>
                    {FtaTranslatedTextsObject.orignatingValuePercentage}
                  </label>
                </span>

                <span className={`field-vertical--item`}>
                  <label className={`field-value`}>
                    {orignatingValuePercentage !== null
                      ? orignatingValuePercentage
                      : `-`}
                  </label>
                </span>
              </div>
            </CngGridItem>
          </Grid>
        </div>
      </Box>
    </>
  )
}

const RelatedBoMSummaryHeader = Object.freeze({
  FormBody: FormBody,
  initialValues: FORM_INITIAL_VALUES
})

export default RelatedBoMSummaryHeader
