import UserPreference from 'src/constants/locale/key/UserPreference'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const UserPreferenceTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.USER_PREFERENCE
  ])

  let userPreference = translate(
    Namespace.USER_PREFERENCE,
    UserPreference.USER_PREFERENCE
  )

  let timezoneTitle = translate(
    Namespace.USER_PREFERENCE,
    UserPreference.TIMEZONE_TITLE
  )

  let updateSubmitSuccessMsg = translate(
    Namespace.USER_PREFERENCE,
    UserPreference.UPDATE_SUBMIT_SUCCESS_MSG
  )

  let timezone = translate(
    Namespace.USER_PREFERENCE,
    UserPreference.TIMEZONE
  )

  let supportDST = translate(
    Namespace.USER_PREFERENCE,
    UserPreference.SUPPORT_DST
  )
  
  return {
    userPreference,
    timezoneTitle,
    updateSubmitSuccessMsg,
    timezone,
    supportDST
  }
}

export default UserPreferenceTranslationText
