import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from 'cng-web-lib'
import Namespace from "src/constants/locale/Namespace";
import TmpDeniedPartySearchKeys from "src/constants/locale/key/TmpDeniedPartySearch";


function PartyInfo(props) {

    const {translate} = useTranslation(Namespace.TMP_DPS)
    const translatedItem = makeTranslatedSourceItem()

    function makeTranslatedSourceItem() {
        let city = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.PARTY_CITY)
        let country = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.PARTY_COUNTRY)
        let nationality = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.PARTY_NATIONALITY)
        let birthDate = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.PARTY_BDATE)
        let supportingInfo = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.PARTY_SUPPINFO)
    
        return {
            city,
            country,
            nationality,
            birthDate,
            supportingInfo
      }
    }

    return (
        <Card variant="outlined" style={{borderRadius:'0px'}}>
            <CardHeader title={"Party Information"} titleTypographyProps={{variant:'h5', style:{fontWeight:"bold"}}} style={{backgroundColor: "#ececec", padding:'2vh'}}/>
            <CardContent style={{padding: "2vh"}}>
                <Grid container style={{marginBottom:"3vh"}}>
                    <Grid item xs={3}>
                        <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.city}</Typography>
                            <Typography variant="h5" color="textPrimary">{props.party.dpsRiskResultAdditionalInfo.city === null ? "- -" : props.party.dpsRiskResultAdditionalInfo.city}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                    <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.country}</Typography>
                            <Typography variant="h5" color="textPrimary" style={{display: 'flex', alignItems: 'center'}}>
                                {
                                    props.party.dpsRiskResultAdditionalInfo.country === null ? "- -" 
                                    :
                                    <>
                                        <img
                                        className={`field-country-flag`}
                                        alt='Flag'
                                        src={`${process.env.PUBLIC_URL}/static/images/countries/${props.party.dpsRiskResultAdditionalInfo.country}.svg`}
                                        />
                                        {props.party.dpsRiskResultAdditionalInfo.country}
                                    </>
                                }
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                    <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.nationality}</Typography>
                            <Typography variant="h5" color="textPrimary">{props.party.dpsRiskResultAdditionalInfo.nationality === null ? "- -" : props.party.dpsRiskResultAdditionalInfo.nationality}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                    <Stack>
                            <Typography variant="body2" color="textSecondary">{translatedItem.birthDate}</Typography>
                            <Typography variant="h5" color="textPrimary">{props.party.dpsRiskResultAdditionalInfo.restrictionDate === null ? "- -" : props.party.dpsRiskResultAdditionalInfo.restrictionDate}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack>
                    <Typography variant="body2" color="textSecondary">{translatedItem.supportingInfo}</Typography>
                    <Typography variant="h5" style={{wordBreak:"break-word"}}>{props.party.dpsRiskResultAdditionalInfo.info === null ? "- -" : props.party.dpsRiskResultAdditionalInfo.info}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default PartyInfo