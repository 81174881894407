import React from 'react'
import PartyInfo from './component/PartyInfo'
import SourceInfo from './component/SourceInfo'

function ProfilePage(props) {
  return (
    <>
      <div>
        <PartyInfo party={props.party} />
      </div>
      <div>
        <SourceInfo party={props.party} />
      </div>
    </>
  )
}

export default ProfilePage
