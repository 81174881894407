import React from 'react'
import {
  Box,
  Grid,
  Select,
  MenuItem,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import Pagination from '@material-ui/lab/Pagination'

import { PAGE_SIZE_OPTIONS } from './TableBody'

import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSelectField },
  },
} = components

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.textSecondary,
    fontSize: '.875rem'
  },
  select: {
    maxHeight: '2em',
    margin: '10px 0 12px',
    '& .MuiSelect-filled': {
      paddingTop: 10,
      maxHeight: '2em'
    }
  }
}))

function TableFooter(props) {
  const classes = useStyles()

  const perPageChange = e => {
    if (props.onChangeRowsPerPage)
      props.onChangeRowsPerPage(e)
    if (props.onPageInfoUpdate)
      props.onPageInfoUpdate('itemPerPage', e.target.value)
  }

  return <td style={{ display: "block" }}>
    <Box component={Grid} px={2} container spacing={2} alignItems="center">
      <Grid item xs><Grid container spacing={1} alignItems="center">
        <Grid item className={classes.text}>Items per page:</Grid>
        <Grid item>
          <Select variant="filled" size="small"
            value={props.rowsPerPage || PAGE_SIZE_OPTIONS[0]}
            onChange={perPageChange}
            className={clsx([
              classes.select, 'ng-form-control', 'ng-form-control-filled'
            ])}
          >
            {(props.rowsPerPageOptions || PAGE_SIZE_OPTIONS).map(i =>
              <MenuItem value={i} key={i}>{i}</MenuItem>
            )}
          </Select>
        </Grid>
      </Grid></Grid>
      <Grid item>
        <Pagination shape="rounded" color="primary"
          {...(props.pageInfo ? {
            count: props.pageInfo.totalPages,
            page: props.pageInfo.currentPage,
            onChange: (e, page) => props.onPageInfoUpdate('pageNo', page)
          } : {
            count: Math.ceil(props.count / props.rowsPerPage),
            page: props.page + 1,
            onChange: (e, page) => props.onChangePage(e, page - 1)
          })}
        />
      </Grid>
    </Box>
  </td>
}

export { TableFooter }
